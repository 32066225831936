@charset "UTF-8";
/*
Input group
----------------------------------------------------------------
*/



// Sizing options
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.input-group-hg > .form-control,
.input-group-hg > .input-group-addon,
.input-group-hg > .input-group-btn > .btn { @extend .input-hg; }
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn { @extend .input-lg; }
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn { @extend .input-sm; }

// Text input groups
// -------------------------
.input-group-addon {
  padding: 10px $m;
  font-size: floor($component-font-size-base*0.929); // 13px
  color: $gray-lighter;
  text-align: center;
  background-color: transparent;
  border: none;
  border-radius: $border-radius-base;
  transition: all .25s linear;

  .input-group-hg &,
  .input-group-lg &,
  .input-group-sm & {
    line-height: round($line-height-base * 0.5714);
  }
}

// Reset rounded corners
@include form-controls-corners-reset();

.input-group .form-control:first-child {
  border-top-right-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;
}
.input-group .form-control:last-child {
  border-top-left-radius: $border-radius-base;
  border-bottom-left-radius: $border-radius-base;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: $border-radius-base;
}

.input-group {
  // Remove padding for .form-control with button
  .input-group-btn + .form-control {
    padding-left: 0;
    border-left-color: transparent;
  }
  .form-control.has-btn-after {
    padding-right: 0;
    border-right-color: transparent;
  }
}

// Focus State
// -------------------------
.form-group.focus,
.input-group.focus {
  // .input-group-addon {
  //   background-color: $brand-secondary;
  //   border-color: $brand-secondary;
  // }
  .input-group-btn {
    > .btn-default + .btn-default {
      border-left-color: mix($brand-primary, black, 85%);
    }
    .btn {
      border-color: $brand-primary;
      background-color: $inverse;
      color: $brand-primary;
    }
    .btn-default {
      @include button-variant($btn-default-color, $brand-primary, $btn-primary-hover-bg, $btn-primary-active-bg);
    }
  }
}

// Button input groups
// -------------------------
.input-group-btn {
  .btn {
    color: $gray-subtle;
    line-height: round($btn-font-size-base * 1.2857);   // 18px
    background-color: $inverse;
    border: 1px solid $input-border-color;
    height: 44px;

    &[disabled],
    &[readonly],
    fieldset[disabled] & {
      background-color: $input-bg-disabled;
      border-color: $input-bg-disabled;
      color: $gray;
      cursor: default;
      opacity: .7;

      .input-icon {
        color: $gray-subtle;
      }
    }
  }
  .btn-default {
    @include button-variant($btn-default-color, $gray-light, $btn-hover-bg, $btn-active-bg);
  }
  .input-group-hg & .btn {
    line-height: round($btn-font-size-hg * 1.55);   // 31px
  }
  .input-group-lg & .btn {
    line-height: round($btn-font-size-lg * 1.3125);   // 21px
  }
  .input-group-sm & .btn {
    line-height: round($btn-font-size-sm * 1.357);   // 19px
  }

  &:first-child > .btn {
    border-right-width: 0;
    margin-right: -1px;
    z-index: 3;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
  }
  &:last-child > .btn {
    border-left-width: 0;
    margin-left: -1px;
    z-index: 3;
    border-top-right-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
  }
  & > .btn-default + .btn-default {
    border-left: 2px solid $gray-light;
  }
  & > .btn:first-child + .btn {
    .caret {
      margin-left: 0;
    }
  }
}
.input-group-sm > .input-group-btn > .btn {
  height: $input-height-small;
  padding: 8px 10px;
  font-size: $input-font-size-small;
}
.input-group-lg > .input-group-btn > .btn {
  height: $input-height-large;
  padding: 13px 14px;
  font-size: $input-font-size-large;
}
.input-group-hg > .input-group-btn > .btn {
  height: $input-height-huge;
  padding: 16px 20px;
  font-size: $input-font-size-huge;
}


// Rounded input groups
// -------------------------
.input-group-rounded {
  .input-group-btn + .form-control,
  .input-group-btn:last-child .btn {
    border-top-right-radius: ($input-height-base / 2);
    border-bottom-right-radius: ($input-height-base / 2);
  }
  .form-control:first-child,
  .input-group-btn:first-child .btn {
    border-top-left-radius: ($input-height-base / 2);
    border-bottom-left-radius: ($input-height-base / 2);
  }
}

.input-group-rounded.input-group-hg {
  .input-group-btn + .form-control,
  .input-group-btn:last-child .btn {
    border-top-right-radius: ($input-height-huge / 2);
    border-bottom-right-radius: ($input-height-huge / 2);
  }
  .form-control:first-child,
  .input-group-btn:first-child .btn {
    border-top-left-radius: ($input-height-huge / 2);
    border-bottom-left-radius: ($input-height-huge / 2);
  }
}

.input-group-rounded.input-group-lg {
  .input-group-btn + .form-control,
  .input-group-btn:last-child .btn {
    border-top-right-radius: ($input-height-large / 2);
    border-bottom-right-radius: ($input-height-large / 2);
  }
  .form-control:first-child,
  .input-group-btn:first-child .btn {
    border-top-left-radius: ($input-height-large / 2);
    border-bottom-left-radius: ($input-height-large / 2);
  }
}

.input-group-rounded.input-group-sm {
  .input-group-btn + .form-control,
  .input-group-btn:last-child .btn {
    border-top-right-radius: ($input-height-small / 2);
    border-bottom-right-radius: ($input-height-small / 2);
  }
  .form-control:first-child,
  .input-group-btn:first-child .btn {
    border-top-left-radius: ($input-height-small / 2);
    border-bottom-left-radius: ($input-height-small / 2);
  }
}

// IE10, 11対策 クリアボタン、パスワード表示/非表示切り替えボタンを非表示にする
input::-ms-clear {
    visibility: hidden;
}
input::-ms-reveal {
    visibility: hidden;
}

/* bootstrapのabui用にz-indexを調整 */
.input-group {
  & .form-control {
    z-index: 1;
  }

  //カレンダー：期間指定のパターン用
  // & .icon-abui-calendar-solid {
  //   right: 37px;
  //   z-index: 3;
  // }

  // 検索
  &.input-group-rounded {
    & > .input-group-btn {
      & + .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &:focus {
          z-index: 1;
        }
      }
    }
  }
}
