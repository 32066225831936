@charset "UTF-8";
/*
jQuery Ui reset
----------------------------------------------------------------
for jquery-ui-1.10.4.css
*/


.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: $border-radius-base;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: $border-radius-base;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: $border-radius-base;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: $border-radius-base;
}

.ui-widget-content {
  border: none;
  background: $dropdown-bg;
  color: $gray;
  @extend .dropshadow--lv2;
  transition: .25s;
}
.ui-widget {
  font-family: $font-family-base;
}
.ui-widget.ui-widget-content {
  border: 1px solid transparent;
}

.ui-widget-header {
  border: none;
  background: $brand-primary;
  color: $inverse;
  font-weight: bold;
  .ui-icon {
    background-image: none;
  }
}


.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: none;
  font-weight: normal;
  color: $gray-light;
  padding: $x ($x*2);
}
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: none;
  background: $inverse;
  color: $brand-primary;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  border: none;
  background: $brand-primary;
  font-weight: normal;
  color: $inverse;
}
