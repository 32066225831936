@charset "UTF-8";
/*
Table
----------------------------------------------------------------
*/



table {
  background-color: $inverse;
}
th {
  text-align: left;
}
caption {
  color: #777;
  text-align: left;
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
}


// Baseline styles

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  // Cells
  & td {
  	font-size: $component-font-size-base;
    line-height: $line-height-base * 0.8;
    vertical-align: middle;
    border-top: 1px solid $table-border-color;
    padding: $table-cell-padding;
  }
  & th {
    font-weight: $font-weight-base;
    font-size: ceil($component-font-size-base*0.857);
    line-height: $line-height-base * 0.8; // 1.4
    color: $gray;
    background-color: $table-bg-th;
    border-top: 1px solid $table-border-th-color;
    padding: $table-cell-padding;
    &.emphasis {
      background-color: $table-bg-th-emphasis;
    }
  }
  // Bottom align for column headings
  > thead th {
    vertical-align: middle;
    border-bottom: 1px solid $table-border-th-color;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  // Nesting
  .table {
    background-color: $body-bg;
  }
}


// Condensed table w/ half padding

.table-condensed {
  th,
  td {
    padding: $table-condensed-cell-padding;
  }
}


// Bordered version
//
.table-bordered {
  border: 0;
  & th {
  	border: 1px solid $table-border-th-color;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
  & td {
    border: 1px solid $table-border-color;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }

  & > thead {
    & > tr {
      & > th,
      & > td {
        border-bottom-width: 1px;
      }
    }
  }
}
.table {
  & > tbody {
    & > tr {
      &:first-child {
        & > td {
          border-top-width: 0;
        }
      }
    }
  }
}


// Zebra-striping
//
@include keyframes(bg-color-table-striped) {
  0%   { background-color: $table-bg-accent; }
  20%  { background-color: mix($brand-info, white, 20%); }
  100% { background-color: $table-bg-accent; }
}
.table-striped {
  > tbody > tr:nth-child(odd) {
  	> th {
      background-color: $table-bg-th;
      &.emphasis {
          background-color: $table-bg-th-emphasis;
        }
    }
    > td {
      background-color: $table-bg-accent;
    }
    &.feedback-changed {
      & > td {
        @include animation(bg-color-table-striped 1.2s ease 0s 1 normal both);
      }
    }
  }
}


// Hover effect
//
.table-hover,
.table-hover.table-striped {
  > thead,
  > tbody,
  > tfoot {
    > tr:hover, {
      > th {
        background-color: $table-bg-th;
      }
      > td {
        background-color: $table-bg-hover;
      }
      &.primary > td,
      > td.primary {
        background-color: $table-state-primary-bg-hover;
      }
      &.success > td,
      > td.success {
        background-color: $table-state-success-bg-hover;
      }
      &.info > td,
      > td.info {
        background-color: $table-state-info-bg-hover;
      }
      &.warning > td,
      > td.warning {
        background-color: $table-state-warning-bg-hover;
      }
      &.danger > td,
      > td.danger {
        background-color: $table-state-danger-bg-hover;
      }
      &.muted > td,
      > td.muted {
        background-color: $table-state-muted-bg-hover;
      }
    }
  }
}

.table-hover-cell,
.table-hover-cell.table-striped {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > td.hover {
        background-color: mix($table-bg-hover, white, 50%);
        &:hover {
        	background-color: $table-bg-hover;
        }
      }
      &.primary > td,
      & > td.primary {
        &.hover {
          background-color: mix($table-state-primary-bg-hover, white, 50%);
          &:hover {
            background-color: $table-state-primary-bg-hover;
          }
        }
      }
      &.success > td,
      & > td.success {
        &.hover {
          background-color: mix($table-state-success-bg-hover, white, 50%);
          &:hover {
            background-color: $table-state-success-bg-hover;
          }
        }
      }
      &.info > td,
      & > td.info {
        &.hover {
          background-color: mix($table-state-info-bg-hover, white, 50%);
          &:hover {
            background-color: $table-state-info-bg-hover;
          }
        }
      }
      &.warning > td,
      & > td.warning {
        &.hover {
          background-color: mix($table-state-warning-bg-hover, white, 50%);
          &:hover {
            background-color: $table-state-warning-bg-hover;
          }
        }
      }
      &.danger > td,
      & > td.danger {
        &.hover {
          background-color: mix($table-state-danger-bg-hover, white, 50%);
          &:hover {
            background-color: $table-state-danger-bg-hover;
          }
        }
      }
      &.muted > td,
      & > td.muted {
        &.hover {
          background-color: mix($table-state-muted-bg-hover, white, 50%);
          &:hover {
            background-color: $table-state-muted-bg-hover;
          }
        }
      }
    }
  }
}

// hover時にカーソル変更
.table {
  &.table-hover--pointer {
    @extend .table-hover;
    & td {
      cursor: pointer;
    }
  }
  &.table-hover-cell--pointer {
    @extend .table-hover-cell;
    & td {
      cursor: pointer;
    }
  }
}



// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9/10 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}
table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9/10 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}

// Table on active & success
.table {
  & th,
  & td {
    &.active {
      background-color: $table-bg-active!important;
      &:hover {
        background-color: $table-bg-active!important;
      }
    }
    // &.success {
    //   background-color: $table-state-success-bg;
    // }
  }
  & tr {
    &.active {
      & th,
      & td {
        background-color: $table-bg-active!important;
      }
    }
    &.active {
      &:hover {
        & th,
        & td {
          background-color: $table-bg-active!important;
        }
      }
    }
    // &.success {
    //   & th,
    //   & td {
    //     background-color: #f5f5f5;
    //   }
    // }
  }
}



// Table rows
//

.table,
.table-striped {
	> thead,
  > tbody,
  > tfoot {
  	& tr {
      &.primary > th,
      > th.primary {
        background-color: $table-state-primary-bg;
      }
      &.primary > td,
      > td.primary {
        background-color: $table-state-primary-bg;
        color: $brand-primary;
      }
      &.success > th,
      > th.success {
        background-color: $table-state-success-bg;
      }
  		&.success > td,
  		> td.success {
  			background-color: $table-state-success-bg;
  			color: $brand-success;
  		}
      &.info > th,
      > th.info {
        background-color: $table-state-info-bg;
      }
  		&.info > td,
  		> td.info {
  			background-color: $table-state-info-bg;
  			color: $brand-info;
  		}
      &.warning > th,
      > th.warning {
        background-color: $table-state-warning-bg;
      }
  		&.warning > td,
  		> td.warning {
  			background-color: $table-state-warning-bg;
  			color: $brand-warning;
  		}
      &.danger > th,
      > th.danger {
        background-color: $table-state-danger-bg;
      }
  		&.danger > td,
  		> td.danger {
  			background-color: $table-state-danger-bg;
  			color: $brand-danger;
  		}
      &.muted > td,
      > td.muted {
        background-color: $table-state-muted-bg;
        color: $text-muted;
      }

      &:nth-child(odd) {
        @extend tr;
      }
  	}
  }
}


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: $screen-xs-max) {
  .table-responsive {
    width: 100%;
    margin-bottom: ($l - 1);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: none;
    > {
      .table {
        margin-bottom: 0;
        & th,
        & td {
          white-space: nowrap;
        }
      }
      .table-bordered {
        border: 0;
        & tr {
          & > th,
          & > td {
            &:first-child {
              border-left: 0;
            }
          }
        }
        & tr {
          & > th,
          & > td {
            &:last-child {
              border-right: 0;
            }
          }
        }

        // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
        // chances are there will be only one `tr` in a `thead` and that would
        // remove the border altogether.
        tbody > tr:last-child > th, tfoot > tr:last-child > th, tbody > tr:last-child > td, tfoot > tr:last-child > td {
          border-bottom: 0;
        }
      }
    }
  }
}

// .table-responsive {
//   @media (max-width: $grid-float-breakpoint) {
//     width: 100%;
//     margin-bottom: ($line-height-computed * 0.75);
//     overflow-y: hidden;
//     overflow-x: auto;
//     -ms-overflow-style: -ms-autohiding-scrollbar;
//     // border: 1px solid $table-border-color;
//     border: none;
//     -webkit-overflow-scrolling: touch;

//     // Tighten up spacing
//     > .table {
//       margin-bottom: 0;

//       // Ensure the content doesn't wrap
//       > thead,
//       > tbody,
//       > tfoot {
//         > tr {
//           > th,
//           > td {
//             white-space: nowrap;
//           }
//         }
//       }
//     }

//     // Special overrides for the bordered tables
//     > .table-bordered {
//       border: 0;

//       // Nuke the appropriate borders so that the parent can handle them
//       > thead,
//       > tbody,
//       > tfoot {
//         > tr {
//           > th:first-child,
//           > td:first-child {
//             border-left: 0;
//           }
//           > th:last-child,
//           > td:last-child {
//             border-right: 0;
//           }
//         }
//       }

//       // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
//       // chances are there will be only one `tr` in a `thead` and that would
//       // remove the border altogether.
//       > tbody,
//       > tfoot {
//         > tr:last-child {
//           > th,
//           > td {
//             border-bottom: 0;
//           }
//         }
//       }

//     }
//   }
// }


// Controls

.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th {
      	.dropdown {
      		margin-left: -8px;
      		.btn-default {
					  @include button-variant($gray, transparent, transparent, transparent);
					}
      	}
      }
      > th,
      > td {
        .checkbox,
        .radio {
          margin: 6px;
          padding-left: 24px;
          line-height: 1.2;

          .icons {
            font-size: floor($component-font-size-base*1.143); //16px
          }
          &.no-label {
            width: floor($component-font-size-base*1.143);
            height: floor($component-font-size-base*1.143);
            padding-left: 0;
            .icons {
              width: floor($component-font-size-base*1.143);
            }
          }
        }
        .radio {
          &.no-label {
            min-height: floor($component-font-size-base*1.143);
          }
        }
        .has-switch {
          margin: -1px 2px;
          top: 2.5px;
        }
      }
    }
  }
}


// Sort
.table>thead>tr:hover>th.table-sort.table-sort-active,
.table>tbody>tr:hover>th.table-sort.table-sort-active,
.table>tfoot>tr:hover>th.table-sort.table-sort-active {
  background-color: $table-bg-th-emphasis;
}

.table>thead>tr>th.table-sort.table-sort-active,
.table>tbody>tr>th.table-sort.table-sort-active,
.table>tfoot>tr>th.table-sort.table-sort-active {
  background-color: $table-bg-th-emphasis;
}

.table>thead>tr>th.table-sort .dsc,
.table>thead>tr>th.table-sort .asc,
.table>tbody>tr>th.table-sort .dsc,
.table>tbody>tr>th.table-sort .asc,
.table>tfoot>tr>th.table-sort .dsc,
.table>tfoot>tr>th.table-sort .asc {
  padding-left: 4px;
  display: block;
  cursor: pointer
}

.table>thead>tr>th.table-sort .dsc:hover,
.table>thead>tr>th.table-sort .asc:hover,
.table>tbody>tr>th.table-sort .dsc:hover,
.table>tbody>tr>th.table-sort .asc:hover,
.table>tfoot>tr>th.table-sort .dsc:hover,
.table>tfoot>tr>th.table-sort .asc:hover {
  opacity: .7
}

.table>thead>tr>th.table-sort .dsc::after,
.table>thead>tr>th.table-sort .asc::after,
.table>tbody>tr>th.table-sort .dsc::after,
.table>tbody>tr>th.table-sort .asc::after,
.table>tfoot>tr>th.table-sort .dsc::after,
.table>tfoot>tr>th.table-sort .asc::after {
  font-family: 'ABUI-icons';
  color: #fff;
  width: 12px;
  display: inline-block;
  padding-left: 8px;
  content: "\e114"
}

.table>thead>tr>th.table-sort .asc::after,
.table>tbody>tr>th.table-sort .asc::after,
.table>tfoot>tr>th.table-sort .asc::after {
  content: "\e113"
}
// .table {
//   > thead,
//   > tbody,
//   > tfoot {
//     > tr {
//       &:hover {
//         > th {
//           &.table-sort {
//             &.table-sort-active {
//               background-color: $table-bg-th-emphasis;
//             }
//           }
//         }
//       }
//       > th {
//         &.table-sort {
//           &.table-sort-active {
//             background-color: $table-bg-th-emphasis;
//           }
//           .dsc {
//             padding-left: $x;
//             display: block;
//             cursor: pointer;
//             &:hover {
//               opacity: .7;
//             }
//             &::after {
//               font-family: 'ABUI-icons';
//               color: $inverse;
//               width: 12px;
//               display: inline-block;
//               padding-left:$s;
//               content: "\e114";
//             }
//           }
//           .asc {
//             @extend .dsc;
//             &::after {
//               content: "\e113";
//             }
//           }
//         }
//       }
//     }
//   }
// }

// Add Row
.table {
  tr {
    &.addrow {
      cursor: pointer;
      > td {
        padding: 0;
        height: 44px;
      }
      .btn {
        @extend .btn-flat-primary;
        @extend .btn-sm;
        text-align: left;
        width: 100%;
      }
    }
  }
}

.innerScrollFade .fadeout {
  font-size: 75%;
  text-align: center;
  color: $brand-primary;
  background: linear-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
  height: 60px;
  width: 100%;
  padding-top: $table-cell-padding * 5;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 1000;
}

// cell with controls
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  & .checkbox,
  & .radio {
    transition: none;
    & > .icons {
      transition: none;
    }
  }

  &.cell-control {
    padding: ($x / 2);
    .form-group {
      position: relative;
      margin-bottom: 0;
      .input-icon {
        top: 3px;
        right: 1px;
      }
      &.required {
        &:after {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 8px 8px 0;
          border-color: transparent $brand-danger transparent transparent;
        }
      }
    }

    & .btn,
    & .form-control {
      transition: none;
    }
  }
  // &.table-sort {
  //   padding: $x;
  // }
}


// noborder
.table-noborder {
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top: none;
      }
      > th {
        border-top: none;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    border-bottom: none;
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: none;
  }
}



// dropdown in th
.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th {
        .dropdown-toggle.btn-input {
          @include button-variant($gray, $table-bg-th, $table-bg-th, $table-bg-th);
          .caret {
            border-bottom-color: $gray-lighter;
            border-top-color: $gray-lighter;
          }
        }
      }
    }
  }
}


//
// table内 カラム近接
//
.table {
  [class^="col-"],
  [class*=" col-"] {
    // すべての解像度で適用
    &.col-all-closer {
      padding-left: 6px;
      padding-right: 6px;
    }
    // for tablet (幅768px以上) に適用
    &.col-sm-closer {
      @media (min-width: $screen-sm-min) {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
    // for desktop (幅992px以上) に適用
    &.col-md-closer {
      @media (min-width: $screen-md-min) {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
    // for wide desktop (幅1200px以上) に適用
    &.col-lg-closer {
      @media (min-width: $screen-lg-min) {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
    // 端に来る場合にリセット
    // すべての解像度
    &.col-all-closer--leftend {
      padding-left: $l;
    }
    &.col-all-closer--rightend {
      padding-right: $l;
    }
    // for wide desktop (幅1200px以上) に適用
    @media (min-width: $screen-lg-min) {
      &.col-lg-closer--leftend {
        padding-left: $l;
      }
      &.col-lg-closer--rightend {
        padding-right: $l;
      }
    }
    // for desktop (幅992px以上 - 幅1200px未満) に適用
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      &.col-md-closer--leftend {
        padding-left: $l;
      }
      &.col-md-closer--rightend {
        padding-right: $l;
      }
    }
    // for tablet (幅768px以上 - 幅992px未満) に適用
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      &.col-sm-closer--leftend {
        padding-left: $l;
      }
      &.col-sm-closer--rightend {
        padding-right: $l;
      }
    }
    // for phone (幅768px未満) に適用
    @media (max-width: $screen-xs-max) {
      &.col-xs-closer--leftend {
        padding-left: $l;
      }
      &.col-xs-closer--rightend {
        padding-right: $l;
      }
    }
  }
}

//入力行追加UI用
.table {
  tr.tgt--delrow {
    td {
      background-color: mix($table-state-danger-bg, white, 50%) !important;
    }
  }
  td.tgt--delrow {
    background-color: mix($table-state-danger-bg, white, 50%) !important;
  }
}

.table-checkablerow {
  tr {
    td {
      cursor: pointer;
      &.js-check-exclude {
        cursor: default;
      }
    }
  }
}



//行、列固定テーブル
/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#bs/dt-1.10.16
 *
 * Included libraries:
 *   DataTables 1.10.16
 */

table.dataTable {
  clear: both;
  max-width: none;
  // border-collapse: separate;
  // width: auto;
  & th,
  & td {
    // box-sizing: content-box;
    border-top: 0px;
    &.dataTables_empty {
      text-align: center;
    }
    &.cell-action {

    }
  }
  //reset
  & th {
    border: 1px solid $table-border-th-color;
    &:first-child {
      // border-left: 1px solid $table-border-th-color;
    }
    &:last-child {
      // border-right: 1px solid $table-border-th-color;
    }
  }
  & td {
    border: 1px solid $table-border-color;
    &:first-child {
      // border-left: 1px solid $table-border-color;
    }
    &:last-child {
      // border-right: 1px solid $table-border-color;
    }
  }

  &.nowrap {
    & th, & td {
      white-space: nowrap;
    }
  }
}

.dataTables_scrollHead,
.dataTables_scrollBody {
  thead,
  tbody {
    & th {
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
    & td {
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}

.dataTables_scrollBody {
  & > table {
    margin-top: 0;
    margin-bottom: 0;
  }
  & thead {
    & .sorting,
    & .sorting_asc,
    & .sorting_desc {
      &::after {
        display: none;
      }
    }
  }
}

.dataTables_scrollFootInner {
  // box-sizing: content-box;
  & > table {
    margin-top: 0;
  }
}

.table-responsive {
  clear: both;
  & > .dataTables_wrapper {
    & > .row {
      margin: 0;
      & [class^="col-"] {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.DTFC_LeftHeadWrapper,
.DTFC_LeftBodyWrapper {
  width: auto;
  thead,
  tbody {
    & th {
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 1px solid $table-border-th-color;
      }
    }
    & td {
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 1px solid $table-border-color;
      }
    }
    .colgroup {
      border-right: 0;
      td {
        border-right: 0;
      }
    }
  }
}

.DTFC_RightHeadWrapper,
.DTFC_RightBodyWrapper {
  // border-left: 1px solid $inverse;
  width: auto;
  thead,
  tbody {
    & th {
      &:first-child {
        border-left: 1px solid $table-border-th-color;
      }
      &:last-child {
        border-right: 0;
      }
    }
    & td {
      &:first-child {
        border-left: 1px solid $table-border-color;
      }
      &:last-child {
        border-right: 0;
      }
    }
    .colgroup {
      border-right: 0;
      td {
        border-right: 0;
      }
    }
  }
}

.DTFC_RightWrapper {
  position: absolute;
  right: 0px;
  top: 0;
  height: 1px;
  .DTFC_RightBodyLiner {
    max-height: auto;
    // width: 100% !important;
    // padding-right: 0 !important;
  }
}
.DTFC_LeftWrapper {
  position: absolute;
  left: 0px;
  top: 0;
  height: 1px;
  .DTFC_LeftBodyLiner {
    max-height: auto;
  }
}

.DTFC_ScrollWrapper {
  position: relative;
}

//customization
.dataTable {
  margin: 0 !important;
  width: 100%;
  thead {
    th {
      // border-left: 0px;
    }
  }
  tbody {
    td {
      // border-bottom: 1px solid $table-border-color;
    }
  }
  tr.hover {
    & > td {
      background-color: $table-bg-hover !important;
    }
  }
  &.datatable-checkablerow {
    tr {
      cursor: pointer;
    }
  }
}

// .table-fixed {
//   .cell-checkbox {
    // width: 1em !important;
//   }
// }

.dataTables_scrollHeadInner, .DTFC_LeftHeadWrapper, .DTFC_RightHeadWrapper {
  .table-fixed {
    //rowspan, colspanがあるヘッダー用
    thead {
      & > tr:not(:first-child) {
        th {
          border-top: 1px solid $table-border-th-color;
        }
      }
    }
  }
}

.table-row-collapse {
  // border-bottom: 1px solid $table-border-color;
  & td {
    // line-height: round($line-height-base * 1.1429); // 2
    .cell-control {
      padding: $table-condensed-cell-padding;
    }

  }

  tr:not(.childrows) {
    td {
      border-bottom: 0px;
    }
  }

  tr.childrows:last-child {
    .table-collapse-childrows {
      & > tbody > tr.childrow_cell:last-child {
        td {
          // border-bottom: 1px solid $table-border-color;
        }
      }
    }
  }

  & > tbody {
    & > tr.childrows {
      .colgroup {
        border-top: none;
        border-bottom: none;
      }
    }
  }

  & thead {
    & td {
      // border-bottom-width: 1px;
    }
  }
  & tbody {
    & td {
      // border-bottom: none;
    }
  }

  & > tbody {
    & > tr:not(:first-child) {
      & > td:not(.colgroup) {
        // border-top: 1px solid $table-border-color;
      }
    }
    tr.childrow_cell {
      td {
        border-top: 1px solid $table-border-color;
      }
    }
  }
}

.dataTables_scrollBody .table-row-collapse {
  // .table-row-collapse {
    border-bottom: 1px solid $table-border-color;
  // }
}

.DTFC_RightWrapper{
  .childrows {
    .colgroup {
      border-left: none;
    }
  }
}

table.autoWidth {
  width: 100%;
}
