@charset "UTF-8";
/*
Select
----------------------------------------------------------------
*/


select {
  &[multiple], &[size] {
    height: auto;
  }
}

.select {
  display: inline-block;
  margin-bottom: 10px;

  // Select grid
  &[class*="span"] {
    [class*="span"] > & {
      margin-left: 0; // No margin if select is a closest child of the grid
    }
    .btn {
      width: 100%; // Button should take all available space of its parent
    }
  }

  // Fluid width. Takes all available space and behaves like a block
  &.select-block {
    display: block;
    float: none;
    margin-left: 0;
    width: auto;
    margin-bottom: 0;
    @extend .clearfix;

    .btn {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
    }
  }


  // Button Sizes
  // --------------------------------------------------
  .btn {
    width: 220px; // Default select width until .span* is applied

    // Huge
    &.btn-hg {
      .filter-option {
        left: 20px;
        right: 40px;
        top: 13px;
      }
      .caret {
        right: 20px;
      }
    }

    // Large
    &.btn-lg {
      .filter-option {
        left: 18px;
        right: 38px;
      }
    }

    // Small
    &.btn-sm {
      .filter-option {
        left: 13px;
        right: 33px;
      }
      .caret {
        right: 13px;
      }
    }

    // Exstra small
    &.btn-xs {
      .filter-option {
        left: 13px;
        right: 33px;
        top: 5px;
      }
      .caret {
        right: 13px;
      }
    }

    .filter-option {
      height: 26px;
      left: 13px;
      overflow: hidden;
      position: absolute;
      right: 33px;
      text-align: left;
      top: 12px;
    }
    .caret {
      position: absolute;
      right: 16px;
      top: 50%;
      margin-top: -3px;
    }
    .dropdown-toggle {
      border-radius: $border-radius-base;
    }

    // Dropdown menu
    .dropdown-menu {
      min-width: 100%;

      dt {
        cursor: default;
        display: block;
        padding: 3px 20px;
      }
      li {
        &:not(.disabled) > a:hover small {
          color: fade($inverse, .4);
        }
        > a {
          min-height: 20px;

          &.opt {
            padding-left: 35px;
          }
        }
        small {
          padding-left: .5em;
        }
        > dt small {
          font-weight: normal;
        }
      }
    }

    // Disabled state
    > .disabled,
    .dropdown-menu li.disabled > a {
      cursor: default;
    }
  }

}


/*!
 * Bootstrap-select v1.8.0 (http://silviomoreto.github.io/bootstrap-select)
 *
 * Copyright 2013-2015 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */
// Mixins
@mixin cursor-disabled() {
  cursor: not-allowed;
}

@function fade($color, $amnt) {
  @if $amnt > 1 {
    $amnt: $amnt / 100; // convert to percentage if int
  }
  @return rgba($color, $amnt);
}

// Rules
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0; /*IE9 and below*/

  // The selectpicker button
  > .dropdown-toggle {
    width: 100%;
    padding-right: 25px;
    z-index: 1;
  }

  > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;

    &.mobile-device {
      top: 0;
      left: 0;
      display: block !important;
      width: 100% !important;
      z-index: 2;
    }
  }

  // Error display
  .has-error & .dropdown-toggle,
  .error & .dropdown-toggle {
    color: $brand-danger;
    border-color: $brand-danger;
    background-color: $input-bg-error;
  }

  &.fit-width {
    width: auto !important;
  }

  &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }

  &.btn-group {
    &.select-hg {
      .btn-input {
        padding: 18px 20px;
        font-size: $btn-font-size-hg;
        line-height: $btn-line-height-hg;
        padding-right: 30px;
        .caret {
          right: 19px;
        }
      }
    }
    &.select-lg {
      .btn-input {
        padding: 13px 14px;
        font-size: $btn-font-size-lg;
        line-height: $btn-line-height-lg;
        padding-right: 26px;
        .caret {
          right: 14px;
        }
      }
    }
    &.select-sm {
      .btn-input {
        padding: 8px 10px;
        font-size: $btn-font-size-sm;
        line-height: $btn-line-height-sm;
        padding-right: 23px;
        .caret {
          right: 10px;
        }
      }
    }
    &.select-xs {
      .btn-input {
        padding: 5px 5px;
        font-size: $btn-font-size-xs;
        line-height: $btn-line-height-xs;
        // margin-top: -8px;
        padding-right: 19px;
        .caret {
          right: 8px;
          border-width: 6px 4px;
          margin-top: -3px;
        }
      }
    }
  }

  .dropdown-toggle:focus {
    outline-offset: -2px;
  }
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;

  &:not([class*="col-"]) {
    width: 100%;
  }

  &.input-group-btn {
    z-index: auto;
  }
}

// The selectpicker components
.bootstrap-select.btn-group {
  &:not(.input-group-btn),
  &[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
  }

  // Forces the pull to the right, if necessary
  &,
  &[class*="col-"],
  .row &[class*="col-"] {
    &.dropdown-menu-right {
      float: right;
    }
  }

  .form-inline &,
  .form-horizontal &,
  .form-group & {
    margin-bottom: 0;
  }

  .form-group-lg &.form-control,
  .form-group-sm &.form-control {
    padding: 0;
  }

  // Set the width of the live search (and any other form control within an inline form)
  // see https://github.com/silviomoreto/bootstrap-select/issues/685
  .form-inline & .form-control {
    width: 100%;
  }

  &.disabled,
  > .disabled {
    @include cursor-disabled();

    &:focus {
      outline: none !important;
    }
  }

  &.bs-container {
    position: absolute;

    .dropdown-menu {
      z-index: 1060;
    }
  }

  // The selectpicker button
  .dropdown-toggle {
    .filter-option {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      text-align: left;
    }

    .caret {
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -2px;
      vertical-align: middle;
      z-index: 40000;
    }
    &.btn-xs {
      .caret {
        right: 8px;
      }
    }
    &.btn-sm {
      .caret {
        right: 10px;
      }
    }
    &.btn-lg {
      .caret {
        right: 14px;
      }
    }
    &.btn-hg {
      .caret {
        right: 19px;
      }
    }
  }

  &[class*="col-"] .dropdown-toggle {
    width: 100%;
  }

  // The selectpicker dropdown
  .dropdown-menu {
    min-width: 100%;
    box-sizing: border-box;

    &.inner {
      position: static;
      float: none;
      border: 0;
      padding: 0;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
    }

    li {
      position: relative;

      &.active small {
        color: $inverse;
      }

      &.disabled a {
        @include cursor-disabled();
      }

      a {
        cursor: pointer;
        user-select: none;

        &.opt {
          position: relative;
        }

        span.check-mark {
          display: none!important;
        }

        span.text {
          display: inline-block;
        }
      }

      small {
        padding-left: 0.5em;
      }
    }

    .notify {
      position: absolute;
      bottom: 5px;
      width: 96%;
      margin: 0 2%;
      min-height: 26px;
      padding: 3px 5px;
      background: rgb(245, 245, 245);
      border: 1px solid rgb(227, 227, 227);
      box-shadow: 0 1px 1px fade(rgb(0, 0, 0), 5) inset;
      pointer-events: none;
      opacity: .9;
      box-sizing: border-box;
    }
  }

  .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
    white-space: nowrap;
  }

  &.fit-width .dropdown-toggle {
    .filter-option {
      position: static;
    }

    .caret {
      position: static;
      top: auto;
      margin-top: -1px;
    }
  }

  &.show-tick .dropdown-menu li {
    &.selected a span.check-mark {
      position: absolute;
      display: inline-block;
      height: $l;
      right: $l - 1;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    a span.text {
      margin-right: 34px;
    }
  }
}

.bootstrap-select.show-menu-arrow {
  &.open > .dropdown-toggle {
    z-index: (1060 + 1);
  }

  .dropdown-toggle {
    &::before {
      content: '';
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgba(204, 204, 204, 0.2);
      position: absolute;
      bottom: -4px;
      left: 9px;
      display: none;
    }

    &::after {
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      position: absolute;
      bottom: -4px;
      left: 10px;
      display: none;
    }
  }

  &.dropup .dropdown-toggle {
    &::before {
      bottom: auto;
      top: -3px;
      border-top: 7px solid rgba(204, 204, 204, 0.2);
      border-bottom: 0;
    }

    &::after {
      bottom: auto;
      top: -3px;
      border-top: 6px solid white;
      border-bottom: 0;
    }
  }

  &.pull-right .dropdown-toggle {
    &::before {
      right: $m;
      left: auto;
    }

    &::after {
      right: $m + 1;
      left: auto;
    }
  }

  &.open > .dropdown-toggle {
    &::before,
    &::after {
      display: block;
    }
  }
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: $x $s;
}

.bs-actionsbox {
  width: 100%;
  box-sizing: border-box;

  & .btn-group button {
    width: 50%;
  }
}

.bs-donebutton {
  float: left;
  width: 100%;
  box-sizing: border-box;

  & .btn-group button {
    width: 100%;
  }
}

.bs-searchbox {
  & + .bs-actionsbox {
    padding: 0 $s $x;
  }

  & .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
  }
}
