@charset "UTF-8";
/*
Pagination
----------------------------------------------------------------
*/


.pagination {
  text-align: center;
  border-radius: $border-radius-base + $border-radius-base;
  // padding-left: 0;
  margin: $l 0;
  display: block;
  position: relative;

  & > .active {
    & > a {
      color: inherit;
      background-color: inherit;
      &:hover,
      &:focus {
        color: inherit;
        background-color: inherit;
      }
    }
    & > span {
      @extend a;
    }
  }
}

.pagination__list {
  background: $pagination-color;
  color: $gray-lighter;
  padding: 0;
  margin: 0;
  display: inline-block;
  border-radius: $border-radius-base;
}

.pagination__list-item {
  display: inline-block;
  margin-right: -3px;
  vertical-align: middle;

  &:not(:nth-child(-n+4)):not(:nth-last-child(-n+4)){
    @media ( max-width: $screen-xs-max ){
      display:none;
    }
  }
  &:first-child {
    &.previous + & {
      > a,
      > span {
        border-left-width: 5px;
      }
    }
  }
  &:last-child {
    border-radius: 0 $border-radius-base $border-radius-base 0;
    margin-right: 0;
  }
  &.previous,
  &.next {
    > a,
    > span {
      font-size: floor($component-font-size-base * 1.067); // ~16px
      color: $gray-light;
      background: transparent;
      border: none;
      //border-right: 2px solid mix($pagination-color, white, 66%);
      border-radius: $border-radius-base 0 0 $border-radius-base;
      padding: 14px $l;
      margin: 0 9px 0 0;

      &[class^="icon-abui-"],
      &[class*=" icon-abui-"] {
        font-weight: 700;
      }

      &:hover,
      &:focus {
        //border-color: mix($pagination-color, white, 66%) !important;
        background: $btn-flat-default-hover-bg;
        color: $gray-light;
      }

      &:active {
        background-color: $btn-flat-default-active-bg;
      }

      @media (max-width: 480px) {
        & {
          margin-right: 0;
        }
      }
    }
    &.disabled {
        > a,
        > span {
          color: $gray-subtle;
          cursor: default;

          &:hover,
          &:focus {
            background-color: transparent;
            border-color: none;
            color: $gray-subtle;
          }
        }
      }
  }
  &.next {
    margin-left: 9px;

    > a,
    > span {
      //border-left: 2px solid mix($pagination-color, white, 66%);
      border-right: none;
      margin: 0;
      border-radius: 0 $border-radius-base $border-radius-base 0;
    }
  }
  &.active {
    > a,
    > span {
      background-color: $gray-subtler;
      border-color: $gray-subtler;
      border-width: 4px !important;
      color: $gray-light;
      margin: 10px 5px 9px;

      &:hover,
      &:focus {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: $inverse;
      }
    }
    &.previous,
    &.next {
      // border-color: mix($pagination-color, white, 66%);

      > a, > span {
        margin: 0;

        &,
        &:hover,
        &:focus {
          background-color: $btn-primary-hover-bg;
          color: $inverse;
        }
      }

    }
    &.previous {
      margin-right: 6px;
    }
  }
  // &.previous,
  // &.next {
  // }

  // Link
  > a,
  > span {
    font-size: $component-font-size-base;
    line-height: round($line-height-computed * 0.5714);   // 16px
    color: $brand-primary;
    background: transparent;
    border: 4px solid $pagination-color;
    border-radius: 50px;
    min-width: 0;
    min-height: 16px;
    padding: 0 4px;
    margin: 7px 2px 6px;
    display: inline-block;
    transition: background .2s ease-out,
                border-color 0s ease-out,
                color .2s ease-out;

    &:hover,
    &:focus {
      background-color: $brand-primary;
      border-color: $brand-primary;
      color: $inverse;
      transition: background .2s ease-out,
                  border-color .2s ease-out,
                  color .2s ease-out;
    }
    &:active {
      background-color: mix($brand-secondary, black, 85%);
      border-color: mix($brand-secondary, black, 85%);
    }
  }
}


.page-count,
.page-unit {
  font-size: ceil($component-font-size-base * 0.928); // 13px
  color: $gray-light;
  top: $m;

  @media ( min-width: $screen-sm-min ) {
    position: absolute;
  }

  @media (max-width: $screen-sm-max) {
    margin-bottom: 0;
  }
}

.page-unit {
  font-size: 0;
  color: $gray-subtle;
  padding-left: 0;
  list-style: none;
  display: block;
  right: 0;
  @media ( min-width: $screen-md-min ) {
    right: $l;
  }
}

.page-unit__list {
  font-size: ceil($component-font-size-base * 0.928);
  display: inline-block;
  &.active {
    & a {
    color: $gray-light;
      &:hover,
      &:active,
      &:focus {
        cursor: default;
        color: $gray-light;
      }
    }
  }
  & a {
    padding: 0 $s;
  }
}


.pagination {

  // Navigation buttons
  > .btn {
    &.previous,
    &.next {
      font-size: ceil($component-font-size-base * 0.928); // 13px
      line-height: $btn-line-height-base; // ~20px
      padding-left: 23px;
      padding-right: 23px;
      margin-right: $s;

      [class*="icon-abui-"] {
        font-size: $icon-normal;
        margin-left: -2px;
        margin-top: -2px;
      }
    }

    &.next {
      margin-left: $s;
      margin-right: 0;

      [class*="icon-abui-"] {
        margin-right: -2px;
        margin-left: $x;
      }
    }
  }
}


//
.filter-result {
  & > .section:first-child {
    .pagination:first-child {
      margin-top:0;
    }
    .btn-row,
    .table {
      & + .pagination,
      & + .row > .pagination {
        margin-top: $l;
      }
    }
  }
}
