@charset "UTF-8";
/*
Dropshadow
----------------------------------------------------------------
*/

.dropshadow {
  &--lv1 {
    box-shadow: 0 1px 1px rgba($gray-darker, .25);
  }
  &--lv2 {
    box-shadow: 0 2px 4px rgba($gray-darker, .25);
  }
  &--lv3 {
    box-shadow: 0 3px 8px rgba($gray-darker, .25);
  }
  &--lv4 {
    box-shadow: 0 4px 12px rgba($gray-darker, .25);
  }
}
