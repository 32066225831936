@charset "UTF-8";
/*
Code
----------------------------------------------------------------
*/



code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}


code {
  padding: 2px 6px;
  font-size: 85%;
  color: $code-color;
  background-color: $code-bg;
  border-radius: $border-radius-base;
}


kbd {
  padding: 2px 6px;
  font-size: 85%;
  color: $kbd-color;
  background-color: $kbd-bg;
  border-radius: $border-radius-base;
  box-shadow: none;
  & kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold;
    box-shadow: none;
  }
}


// .prettyprint をつけることで、bootstrap/js/google-code-prettify/prettify.js による色分け機能を利用できます
pre {
  font-size: ($font-size-base - 5); // 18px to 13px
  line-height: $line-height-base;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  color: $pre-color;
  background-color: $pre-bg;
  border: 1px solid $pre-border-color;
  border-radius: $pre-border-radius;
  padding: (($line-height-computed - 6) / 3);
  margin: 0 0 $l;
  display: block;
  & code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

.pre-scrollable {
  max-height: $pre-scrollable-max-height;
  overflow-y: scroll;
}
