@charset "UTF-8";
/*
Form
----------------------------------------------------------------
*/



// Normalize non-controls
//
// Restyle and baseline non-control form elements.

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $line-height-computed / 2;
  font-size: ceil($component-font-size-base * 1.571); // ~22px
  line-height: inherit;
  color: $legend-color;
  border-bottom: none;
}

output {
  display: block;
  padding-top: 7px;
  font-size: $font-size-base - 2;
  line-height: $line-height-base * 0.81632653;
  color: #555;
}


// Normalize form controls
// Textarea
textarea {
  font-size: $component-font-size-base;
  line-height: $line-height-computed - $x;
  min-height: 44px;
  padding: $s $m;
  &.form-control {
    height: auto;
  }
}

// Search
input[type="search"] {
  -webkit-appearance: none !important; // removes rounded corners for searchfields on iOS
}

// Label
label {
  font-weight: $font-weight-base;
  font-size: floor($component-font-size-base*0.929); // 13px
  line-height: $line-height-base * 0.57142857;  // 1
  color: $gray-light;
  max-width: 100%;
  margin-bottom: $s;
  display: inline-block;
}

// Placeholder
//
// Placeholder text gets special styles because when browsers invalidate entire
// lines if it doesn't understand a selector/
.form-control {
  @include placeholder($input-placeholder-color);
}

// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-control {
  // font-family: $font-family-base;
  font-size: $input-font-size-base;
  line-height: $input-line-height-base;
  color: $input-text-color;
  background-color: $inverse;
  background-image: none;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  height: 44px;
  width: 100%;
  padding: $s $m;
  appearance: none;
  transition: .25s;
  display: block;

  // Customize the `:focus` state
  @include form-control-focus();

  &:focus {
    & + .input-icon {
      color: $brand-primary;
    }
    & + .form-count {
      color: $brand-primary;
    }
  }
  &.input-timepicker[aria-showingpicker="true"] {
    border-color: $brand-primary;
    outline: 0;
    box-shadow: none;
    & + .input-icon {
      color: $brand-primary;
    }
  }

  // Disabled inputs
  // Note: HTML5 says that controls under a fieldset > legend:first-child won't
  // be disabled if the fieldset is disabled. Due to implementation difficulty,
  // we don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-bg-disabled;
    border-color: $input-bg-disabled;
    color: $gray;
    cursor: default;
    opacity: .7;
  }

  // Flat (without border)
  &.flat {
    border-color: transparent;

    &:hover {
      border-color: $gray-subtle;
    }
    &:focus {
      border-color: $brand-primary;
    }
  }

  // Form control sizing
  &.input-xs {
    @include input-size($input-height-xs, 5px, 5px, $input-font-size-xs, $input-line-height-xs);
  }
  &.input-sm {
    @include input-size($input-height-small, 6px, 10px, $input-font-size-small, $input-line-height-small);
  }
  &.input-lg {
    @include input-size($input-height-large, 10px, 14px, $input-font-size-large, $input-line-height-large);
  }
  &.input-hg {
    @include input-size($input-height-huge, 12px, 20px, $input-font-size-huge, $input-line-height-huge);
  }
}




// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.

// Warning
.has-warning {
  @include form-control-validation($brand-warning, $brand-warning);
}
// Error
.has-error {
  @include form-control-validation($brand-danger, $brand-danger);
  [class^="icon-abui-"], [class*=" icon-abui-"] {
    color: $brand-danger;
  }

  .form-control {
    @include placeholder($input-placeholder-color);

    &:focus {
      & + .form-count {
        color: $brand-danger;
      }
    }
  }

  .form-count {
    color: $brand-danger;
  }

  &.textfield-digit {
    .form-control {
      &.digit-underline {
        color: $brand-danger!important;
      }
    }
  }
}
// Success
.has-success {
  @include form-control-validation($brand-success, $brand-success);
}

// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.

.help-block {
  font-size: $font-size-base*0.6785; // 11px
  line-height: $line-height-base*0.85714;
  color: $gray-lighter;
  display: block;
  min-height: 14px;
  margin-top: $x;
  margin-bottom: 0;
}
.form-error {
  @extend .help-block;
  color: $brand-danger;
}
.form-count {
  @extend .help-block;
  @extend .text-right;
}


// Form groups
//

.form-group {
  position: relative;
  margin-bottom: 13px;
  & > label:not(.checkbox-inline):not(.radio-inline) {
    display: block;
  }
  &.required > label:not(.checkbox-inline):not(.radio-inline) {
    position: relative;
    span.label-text {
      position: relative;
      &::after {
        content: "*";
        font-size: ceil($component-font-size-base * 2.125);
        line-height: $line-height-base * 0.57142857;
        color: $brand-danger;
        margin-left: 2px;
        display: block;
        position: absolute;
        top: -3px;
        right: -12px;
      }
    }
  }
  &.required > .label-additional {
    position: relative;
    span.label-text {
      position: relative;
      &::after {
        content: "*";
        font-size: ceil($component-font-size-base * 2.125);
        line-height: $line-height-base * 0.57142857;
        color: $brand-danger;
        margin-left: 2px;
        display: block;
        position: absolute;
        top: -3px;
        right: -12px;
      }
    }
  }
  &.required > label.radio-inline,
  &.required > label.checkbox-inline {
    &::after {
      content: "";
      margin-left: 0;
    }
  }
}

.form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  &.input-lg, &.input-sm {
    padding-right: 0;
    padding-left: 0;
  }
}



// Additional Label
.label-additional {
  font-weight: $font-weight-base;
  font-size: floor($component-font-size-base*0.929); // 13px
  line-height: $line-height-base * 0.57142857;  // 1
  color: $gray-light;
  margin-top: $s + 1;
  margin-bottom: $s;
  display: block;
  &.text-primary {
    @include text-emphasis-variant($brand-primary);
  }
  &.text-success {
    @include text-emphasis-variant($state-success-text);
  }
  &.text-info {
    @include text-emphasis-variant($state-info-text);
  }
  &.text-warning {
    @include text-emphasis-variant($state-warning-text);
  }
  &.text-danger {
    @include text-emphasis-variant($state-danger-text);
  }
}

// Form Inline
.form-inline {
  font-size: 0;
  margin-bottom: 13px;
  @include make-row();
  > label {
    display: block;
    margin-left: $m;
    &+ .form-group {
      @media (min-width: $grid-float-breakpoint) {
        margin-left: 0px;
      }
      &:not(:first-child) {
        @media (min-width: $grid-float-breakpoint) {
          margin-left: 0px;
        }
      }
    }
  }
  .form-group {
    font-size: $input-font-size-base;
    margin-top: 0;
    padding-left: $m;
    padding-right: $m;
    &:not(:last-child) {
      margin-bottom: 0;
    }
    &:not(:first-child) {
      margin-left: -$m;
      // @media (min-width: $grid-float-breakpoint) {
      //   margin-left: -$m;
      // }
    }
    // @media (min-width: $screen-sm-min) {
    //   vertical-align: middle;
    //   display: inline-block;
    //   margin-bottom: 0;
    // }
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
  }
  .label-additional {
    &+ .form-group {
      @media (min-width: $grid-float-breakpoint) {
        margin-left: 0px;
      }
    }
  }
  .form-control {
    width: 100%;
    @media (min-width: $screen-sm-min) {
      vertical-align: middle;
      width: auto;
      display: inline-block;
    }
  }
  .label-additional,
  .help-block {
    margin-left: $m;
  }
  &.required {
    // margin-top: -26px;
    > .label-additional {
      position: relative;
      top: 2px;
      span.label-text {
        position: relative;
        &::after {
          content: "*";
          color: $brand-danger;
          margin-left: 2px;
          font-size: ceil($component-font-size-base * 2.125);
          line-height: $line-height-base * 0.57142857;
          display: block;
          position: absolute;
          top: -3px;
          right: -12px;
        }
      }
    }
    > label:not(.checkbox-inline):not(.radio-inline) {
      position: relative;
      span.label-text {
        position: relative;
        &::after {
          content: "*";
          font-size: ceil($component-font-size-base * 2.125);
          line-height: $line-height-base * 0.57142857;
          color: $brand-danger;
          margin-left: 2px;
          display: block;
          position: absolute;
          top: -3px;
          right: -12px;
        }
      }
    }
    > label {
      &.radio-inline,
      &.checkbox-inline {
        &::after {
          content: "";
          margin-left: 0;
        }
      }
    }
  }
  .input-group {
    @media (max-width: $screen-xs-max) {
      display: inline-table;
      vertical-align: middle;
    }
    .input-group-addon {
      @media (max-width: $screen-xs-max) {
        width: auto;
      }
    }
  }
}
.form-inline-text {
  display: block;
  font-size: floor($component-font-size-base*0.929); // 13px
  line-height: 1;
  text-align: center;
  // padding: $s;
  margin-left: -4px;
  margin-right: -4px;
  color: $gray-lighter;
  @media (max-width: $grid-float-breakpoint) {
    text-align: left;
    padding: $s 0;
    margin-left: 0;
    margin-right: 0;
  }
}

// Submit
.section-submit {
  padding: 0 ($l*2);
  text-align: center;
  // margin-bottom: $l*2;
  margin-bottom: $l;
  .submit-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $s ($s - 2) 0;
    border-color: transparent;
    border-top-color: $gray-subtler;
    margin-top: 0;
    margin-bottom: $l;
  }
  > div {
    text-align: left;
  }
}

$btn-filter-again-height: 80px;
.btn-filter-again {
  background: mix($gray-subtler, white, 90%);
  width: 100%;
  height: $btn-filter-again-height;
  position: relative;
  display: none;
  margin-bottom: $l;
  @extend .clearfix;
  &:hover {
    @extend .btn-flat-primary;
    cursor: pointer;
  }
  &::after {
    content: '条件を再設定';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: $btn-filter-again-height;
    font-size: $btn-font-size-base;
    font-weight: $btn-font-weight;
    font-smoothing: subpixel-antialiased;
    transition: .25s;
    color: $brand-primary;
    z-index: 0;
  }
  &.with-condition {
    &::after {
      text-align: right;
      padding-right: $l*2;
    }
    .filter--condition {
      font-size: ceil($font-size-base * 0.875);
      color: $gray;
      padding: 0 $l*2;
      line-height: 80px;
      position: absolute;
      z-index: 1;
      span {
        display: inline-block;
        &::after {
          content: "/";
          color: $gray-lighter;
          margin-right: $s;
          padding-left: $s;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}


// 検索結果が無い
.search-result-msg {
  margin-bottom: 0;
  @extend .text-center;
  color: $gray-light;
}

// viewonly
.form-group {
  &.viewonly {
    .viewonly-text {
      margin: 0;
      // border: 1px dotted $input-border-color;
      // color: $gray-light;
      font-family: $font-family-base;
      font-size: $input-font-size-base;
      line-height: $input-line-height-base;
      padding: ($m - 1) $m $s;
      // height: 44px;
      appearance: none;
      // border-radius: $input-border-radius;
      background-color: transparent;
      border: none;
      border-bottom: 1px dotted $gray-lighter;
      color: $gray;
      height: auto;
      min-height: $input-height-base;
      box-shadow: none;
      transition: .25s;

      &.input-hg {
        @include input-size($input-height-huge, 14px, 20px, $input-font-size-huge, $input-line-height-huge);
        padding-top: 19px;
      }
      &.input-lg {
        @include input-size($input-height-large, 12px, 14px, $input-font-size-large, $input-line-height-large);
        padding-top: 19px;
      }
      &.input-sm {
        @include input-size($input-height-small, 8px, 10px, $input-font-size-small, $input-line-height-small);
        min-height: $input-height-small;
      }
    }
    &.label-inline {
      label {
        position: absolute;
        top: 15px;
      }
      input[type="text"] {
        background-color: transparent;
        color: $gray;
        opacity: 1;
        border: none;
      }
    }
  }
}

//input with icon
.form-group {
  & > .input-with-icon {
    position: relative;
  }
}

/* bootstrapのabui用にz-indexを調整 */
.input-group {
  & .form-control {
    z-index: 1;
  }
}


// 桁数明示テキストフィールド
.form-group {
  &.textfield-digit {
    .form-control {
      font-family: "Courier New", monospace;
      font-size: $input-font-size-base;
      letter-spacing: 0.5em;
      overflow: hidden;
      padding-right: 0;
      &.digit-underline {
        font-weight: 100;
        line-height: $input-line-height-base * 0.69979006;  // 1
        color: $gray-subtle;
        border: none;
        background-color: transparent;
        height: $s;
        padding: 0;
        margin: 0;
        margin-top: -12px;
        position: absolute;
        top: 13px + $s + 44px;
        left: 0;
      }
      &.input-sm {
        font-size: $input-font-size-small;
        &.digit-underline {
          top: 13px + $s + $input-height-small;
          margin-top: -10px;
        }
      }
      &.input-lg {
        font-size: $input-font-size-large;
        &.digit-underline {
          top: 13px + $s + $input-height-large;
        }
      }
      &.input-hg {
        font-size: $input-font-size-huge;
        &.digit-underline {
          top: 13px + $s + $input-height-huge;
          margin-top: -14px;
        }
      }
    }
  }
}

// サジェスト付きフィールド
// typeahead バージョンアップに伴う変更
.form-group-typeahead {
  & .form-control.input-typeahead {
    background-color: $inverse;
  }
}
.form-control.input-typeahead.tt-hint {
  color: $gray-lighter;
}
.section.section-filter{
  & .form-control[disabled] {
    background-color: #feffff !important;
  }
}

// aタグのdisabled
a.disabled,
a[disabled] {
  pointer-events: none;
  color: $gray-lighter;
}

// 変更のフィードバック
@include keyframes(bg-color) {
  0%   { background-color: $input-bg; }
  20%  { background-color: mix($brand-info, $input-bg, 20%); }
  100% { background-color: $input-bg; }
}
@include keyframes(bg-color--viewonly) {
  0%   { background-color: $input-viewonly-bg; }
  20%  { background-color: mix($brand-info, $input-viewonly-bg, 20%); }
  100% { background-color: $input-viewonly-bg; }
}
.feedback-changed {
  animation: bg-color 1.2s ease 0s 1 normal both;
  &.viewonly-text {
    animation: bg-color--viewonly 1.2s ease 0s 1 normal both;
  }
}