@charset "UTF-8";
/*
Typeahead
----------------------------------------------------------------
*/

%base-width {
  width: 100%;
}
%base-padding {
  padding: 6px 14px;
}

.twitter-typeahead {
  @extend %base-width;

  .tt-hint {
    @extend .form-control;
    background: transparent !important;
  }
  .tt-query {
    @extend .form-control;
    background: $inverse;
  }

  .tt-menu {
    @extend %base-width;
    background-color: $dropdown-bg;
    // border: 2px solid $brand-secondary;
    border-radius: $border-radius-base;
    box-shadow: $gray-lighter 0 1px $x;
    padding: 5px 0;
    margin-top: $x;
  }

  .tt-suggestion {
    @extend %base-padding;
    font-size: ceil($component-font-size-base * 0.933);
    line-height: $line-height-base * 0.81657; // ~20px
    color: $gray;
    margin: 0;
    cursor: pointer;

    &:first-child,
    &:last-child {
      @extend %base-padding;
    }
    &:hover, &.tt-cursor {
      color: $gray-dark;
      background-color: $gray-subtle;
    }
  }
}
.input-icon-typeahead {
  font-family: "ABUI-icons";
  font-size: $input-icon-font-size;
  line-height: round($line-height-computed * 1.4285);   // 40px
  vertical-align: middle;
  color: $gray-subtle;
  background-color: transparent;
  border-radius: $input-border-radius;
  padding: 0 11px 0 0;
  margin: auto;
  position: absolute;
  bottom: 2px;
  top: 2px;
  right: 1px;
  z-index: 1;
}
