@charset "UTF-8";
//
// Panel
// --------------------------------------------------


// Base class
.panel {
  margin-bottom: $m*2;
  background-color: $panel-bg;
  border: 1px solid $panel-default-border;
  border-radius: $border-radius-small;
  // box-shadow: 0 1px 1px rgba($gray-darker, .1);
  font-size: $component-font-size-base;
}

body {
  &.interruption,
  &.bg-gray {
    .panel {
      border-color: transparent;
    }
  }
}

// Panel contents
.panel-body {
  padding: $panel-body-padding*0.75 $panel-body-padding;
  @extend .clearfix;
}

// Optional heading
.panel-heading {
  padding: $panel-heading-padding*0.75 $panel-heading-padding;
  border-bottom: 1px solid $panel-inner-border;
  border-top-right-radius: $border-radius-small;
  border-top-left-radius: $border-radius-small;
  color: $gray-light;

  > .dropdown .dropdown-toggle {
    color: inherit;
  }

  &.panel-heading-noborder {
    padding-bottom: 0;
    border-bottom-color: transparent!important;
  }
}

// Within heading, strip any `h*` tag of its default margins for spacing.
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: ceil($component-font-size-base * 1.142); //16px
  color: inherit;
  font-weight: bold;

  & > a,
  & > small,
  & > .small,
  & > small > a,
  & > .small > a {
    color: inherit;
  }
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
  padding: $panel-footer-padding*0.75 $panel-footer-padding;
  background-color: $panel-footer-bg;
  border-top: 1px solid transparent;
  border-bottom-right-radius: $border-radius-small;
  border-bottom-left-radius: $border-radius-small;
  &.panel-footer-nobackground {
    background-color: transparent;
    padding-top: 0;
  }
  &.row {
    margin-left: 0;
    margin-right: 0;
  }
}


// Panles with Close
.panel {
  &.panel-withclose {
    .panel-heading {
      padding-right: $panel-heading-padding + $l;
    }
    .panel-body {
      padding-right: $panel-body-padding+ $l;
    }
  }
}


// List groups in panels
//
// By default, space out list group content from panel headings to account for
// any kind of custom content between the two.

.panel {
  > .list-group {
    margin-bottom: 0;

    .list-group-item {
      border-width: 1px 0;
      border-radius: 0;
      padding: $s $l;
    }

    // Add border top radius for first one
    &:first-child {
      .list-group-item:first-child {
        border-top: 0;
        border-top-right-radius: $border-radius-base;
        border-top-left-radius: $border-radius-base;
      }
    }
    // Add border bottom radius for last one
    &:last-child {
      .list-group-item:last-child {
        border-bottom: 0;
        border-bottom-right-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;
      }
    }
  }
}
// Collapse space between when there's no additional content.
.panel-heading + .list-group {
  .list-group-item:first-child {
    border-top-width: 0;
  }
}
.list-group + .panel-footer {
  border-top-width: 0;
}

// Tables in panels
//
// Place a non-bordered `.table` within a panel (not within a `.panel-body`) and
// watch it go full width.

.panel {
  > .table,
  > .table-responsive > .table,
  > .panel-collapse > .table {
    margin-bottom: 0;
  }
  // Add border top radius for first one
  > .table:first-child,
  > .table-responsive:first-child > .table:first-child {
    border-top-right-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;

    > thead:first-child,
    > tbody:first-child {
      > tr:first-child {
        td:first-child,
        th:first-child {
          border-top-left-radius: ($border-radius-base);
        }
        td:last-child,
        th:last-child {
          border-top-right-radius: ($border-radius-base);
        }
      }
    }
  }
  // Add border bottom radius for last one
  > .table:last-child,
  > .table-responsive:last-child > .table:last-child {
    border-bottom-right-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;

    > tbody:last-child,
    > tfoot:last-child {
      > tr:last-child {
        td:first-child,
        th:first-child {
          border-bottom-left-radius: $border-radius-base;
        }
        td:last-child,
        th:last-child {
          border-bottom-right-radius: $border-radius-base;
        }
      }
    }
  }
  > .panel-body + .table,
  > .panel-body + .table-responsive {
    border-top: 1px solid $table-border-color;
  }
  > .table > tbody:first-child > tr:first-child th,
  > .table > tbody:first-child > tr:first-child td {
    border-top: 0;
  }
  > .table-bordered,
  > .table-responsive > .table-bordered {
    border: 0;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th:first-child,
        > td:first-child {
          border-left: 0;
        }
        > th:last-child,
        > td:last-child {
          border-right: 0;
        }
      }
    }
    > thead,
    > tbody {
      > tr:first-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
    > tbody,
    > tfoot {
      > tr:last-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
  }
  > .table-responsive {
    border: 0;
    margin-bottom: 0;
  }
}


// Collapsable panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  margin-bottom: $line-height-computed;

  // Tighten up margin so it's only between panels
  .panel {
    margin-bottom: 0;
    border-radius: $border-radius-large;
    + .panel {
      margin-top: $x;
    }
  }

  .panel-heading {
    border-bottom: 0;
    // + .panel-collapse > .panel-body {
    //   border-top: 1px solid $panel-inner-border;
    // }
  }
  .panel-footer {
    border-top: 0;
    // + .panel-collapse .panel-body {
    //   border-bottom: 1px solid $panel-inner-border;
    // }
  }
}


// Contextual variations
.panel-default {
  @include panel-variant($gray-light);
  border-color: $panel-default-border;
  background-color: $panel-bg;
  > .panel-heading {
    background-color: $panel-bg;
    border-bottom-color: $panel-inner-border;
    &.panel-heading-noborder {
      border-bottom-color: transparent;
    }
  }
   > .panel-footer {
    background-color: $panel-footer-bg;
   }
}
.panel-primary {
  @include panel-variant($brand-primary);
}
.panel-success {
  @include panel-variant($brand-success);
}
.panel-info {
  @include panel-variant($brand-info);
}
.panel-warning {
  @include panel-variant($brand-warning);
}
.panel-danger {
  @include panel-variant($brand-danger);
}
