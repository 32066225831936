@charset "UTF-8";
/*
Switch
----------------------------------------------------------------
*/
/*! ===========================================================
 * bootstrapSwitch v1.3 by Larentis Mattia @spiritualGuru
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */

.has-switch {
  line-height: $line-height-base;
  text-align: left;
  border-radius: $switch-border-radius;
  width: $switch-width;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 6px;
  overflow: hidden;
  user-select: none;
  @include mask(url('../images/switch/mask.png') 0 0 no-repeat);

  &.deactivate {
    cursor: default !important;

    label,
    span {
      cursor: default !important;
    }

    > div {
      &.switch-on,
      &.switch-off {
        label {
          background-color: mix($gray-lighter, white, 38%);
        }
        span {
          color: mix($gray-lighter, white, 38%);
          background-color: rgba($gray-subtle, .5);
        }
      }
      &.switch-on {
        label {
          background-color: #c4ccd0;
          background-color: mix($gray-light, white, 38%);
        }
        span {
          color: #c4ccd0;
          color: mix($gray-light, white, 38%);
        }
      }
    }
  }

  > div {
    width: 130px;
    position: relative;
    top: 0;

    &.switch-animate {
      transition: left .25s ease-out;
    }

    &.switch-off {
      left: -50px;

      label {
        background-color: $btn-default-bg;
        border-color: $gray-subtle;
        box-shadow: -1px 0 0 fade($inverse, 0.5);
      }
    }

    &.switch-on {
      left: 0;

      label {
        background-color: $brand-primary;
        position: relative;
        right: -5px;
      }

    }
  }

  input[type=checkbox] {
    display: none;
  }

  span {
    font-size: $component-font-size-base; // 15px
    font-weight: 400;
    line-height: round($line-height-computed * 0.535714); // 15px
    text-align: center;
    width: 50%;
    height: 25px;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 5px;
    float: left;
    position: relative;
    z-index: 1;
    transition: .25s ease-out;
    cursor: pointer;

    [class^="icon-abui-"],
    [class*=" icon-abui-"] {
      font-weight: 700;
    }

    &.switch-left {
      border-radius: $switch-border-radius 0 0 $switch-border-radius;
      background-color: $gray-subtle;
      color: $brand-primary;
      border-left: 1px solid transparent;
    }

    &.switch-right {
      border-radius: 0 $switch-border-radius $switch-border-radius 0;
      background-color: $gray-subtle;
      color: $gray-light;
      text-indent: 7px;

      [class*="icon-abui-"] {
        text-indent: 0;
      }
    }
  }

  label {
    vertical-align: middle;
    border: 0px solid $gray-subtle;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 0 -15px;
    padding: 0;
    position: relative;
    float: left;
    z-index: 100;
    transition: .25s ease-out;
  }
}

// Square Switch
// -------------------------------
/*
.switch-square {
  border-radius: $border-radius-base;
  @include mask(url('../images/switch/mask.png') 0 0 no-repeat);

  > div {
    &.switch-off {
      label {
        border-color: $btn-default-bg;
        border-radius: $border-radius-base 0 0 $border-radius-base;
      }
    }
  }

  span {
    padding-top: 5px;
    &.switch-left {
      border-radius: $border-radius-base 0 0 $border-radius-base;

      [class*="icon-abui-"] {
        text-indent: -10px;
      }
    }

    &.switch-right {
      border-radius: 0 $border-radius-base $border-radius-base 0;

      [class*="icon-abui-"] {
        text-indent: 5px;
      }
    }
  }

  label {
    border-radius: 0 $border-radius-base $border-radius-base 0;
    border-color: $brand-primary;
  }
}
*/

// Label
label.switch {
  display: block;
}
