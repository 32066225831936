@charset "UTF-8";
/*
File Upload using Dropzone.js
----------------------------------------------------------------
*/

@include keyframes(passing-through){
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@include keyframes(slide-in){
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(pulse){
  0% {
    @include scale(1, 1);
  }
  10% {
    @include scale(1.1, 1.1);
  }
  20% {
    @include scale(1, 1);
  }
}

$easing-cubic-bezier: cubic-bezier(0.77, 0, 0.175, 1);

.dropzone,
.dropzone * {
  box-sizing: border-box;
}

$image-size: 120px;

.dropzone {
  min-height: $image-size + $l*2 + 2px;
  border: 1px dashed $gray-lighter;
  // text-align: center;
  color: $gray-lighter;
  background-color: mix($gray-subtler, white, 25%);
  margin-bottom: $l;
  padding-left: 6px;
  &.dz-clickable {
    cursor: pointer;
    * {
      cursor: default;
    }
    .dz-message,
    .dz-message * {
      cursor: pointer;
    }
  }
  &.dz-started {
    .dz-message {
      display: none;
    }
  }
  &.dz-drag-hover {
    border-style: solid;
    .dz-message {
      opacity: .5;
    }
  }
  span {
    display: block;
    &.icon {
      margin-bottom: $s;
      font-size: ceil($font-size-base * 2);
      color: $gray-lighter;
    }
  }
  small {
    display: block;
  }

  .dz-message {
    text-align: center;
    margin: 2em 0 0;
    margin-left: -3px;
  }
  .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: $l $m;
    min-height: 100px;
    &:hover {
      z-index: 1000;
      .dz-details {
        opacity: 1;
      }
      .dz-image {
        &:after {
          display: block;
          width: 100%;
          height: 152px;
          background-color: rgba($gray-dark, 0.8);
        }
        img {
          @include scale(1.05, 1.05);
          filter: blur(8px);
        }
      }
    }
    &.dz-file-preview {
      .dz-image {
        border-radius: $border-radius-small;
        background: #999;
        background: linear-gradient(to bottom, #eee, #ddd);
      }
      .dz-details {
        opacity: 1;
      }
    }
    &.dz-image-preview {
      .dz-details {
        transition: opacity 0.2s linear;
      }
    }
    &.dz-success {
      .dz-success-mark {
        @include animation(passing-through 9s $easing-cubic-bezier);
      }
    }
    &.dz-error {
      &:hover {
        .dz-error-message {
          opacity: 1;
          pointer-events: auto;
        }
      }
      .dz-error-mark {
        opacity: 1;
        @include animation(slide-in 3s $easing-cubic-bezier);
        display: none;
      }
      // .dz-error-message {
      //   display: block;
      // }
    }
    .dz-success-mark,
    .dz-error-mark {
      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -12px;
      margin-top: -12px;
      svg {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
    .dz-success-mark {
      opacity: 0;
      &::after {
        display: block;
        margin: 0 auto;
        content: "\e013";
        font-family: 'ABUI-icons';
        font-size: ceil($font-size-base * 1.5);
        color: white;
        line-height: 1;
        opacity: .9;
      }
      svg {
        display: none;
      }
    }
    .dz-error-mark {
      opacity: 0;
    }
    .dz-error-message {
      pointer-events: none;
      z-index: 1000;
      position: absolute;
      display: block;
      opacity: 1;
      transition: opacity 0.3s ease;
      border-radius: $border-radius-base;
      font-size: ceil($font-size-base * 0.8125);
      top: $image-size + $x;
      left: -8px;
      width: $image-size + $l;
      line-height: 1.5;
      background-color: rgba($brand-danger,0.95);
      padding: $s $m;
      color: white;
      &::after {
        content: '';
        position: absolute;
        top: -7px;
        left: 64px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 7px solid rgba($brand-danger,0.95);;
      }
    }
    &.dz-processing {
      .dz-progress {
        opacity: 1;
        transition: all 0.2s linear;
      }
    }
    &.dz-complete {
      .dz-progress {
        opacity: 0;
        transition: opacity 0.4s ease-in;
      }
    }
    &:not(.dz-processing) {
      .dz-progress {
        @include animation(pulse 3s ease infinite);
      }
    }
    .dz-remove {
      position: absolute;
      top: -$x;
      right: -$x;
      z-index: 30;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: white;
      color: white;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none;
      &:hover {
        text-decoration: none;
      }
      &::after {
        font-family: 'ABUI-icons';
        font-size: $btn-font-size-sm;
        content: "\e014";
        display: block;
        color: $brand-danger;
        // margin-top: 2px;
      }
    }
    .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: ceil($font-size-base * 0.75);
      min-width: 100%;
      max-width: 100%;
      height: $image-size;
      padding: 76px $s $s;
      text-align: center;
      background-color: rgba($gray, 0.3);
      line-height: 150%;
      .dz-size {
        font-size: ceil($font-size-base * 0.75);
        span {
          // background-color: rgba(255, 255, 255, 0.4);
          // padding: 0 0.4em;
          // @include border-radius(3px);
          color: white;
          strong {
            font-weight: normal;
          }
        }
      }
      .dz-filename {
        span {
          width: $image-size - $s*2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: white;
          line-height: 1;
        }

        // &:hover {
        //   span {
        //     border: 1px solid rgba(200, 200, 200, 0.8);
        //     background-color: rgba(255, 255, 255, 0.8);
        //   }
        // }
        // &:not(:hover) {
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   span {
        //     border: 1px solid transparent;
        //   }
        // }
        // span {
        //   background-color: rgba(255, 255, 255, 0.4);
        //   padding: 0 0.4em;
        //   @include border-radius(3px);
        // }
      }
    }
    .dz-image {
      border-radius: $border-radius-small;
      overflow: hidden;
      width: $image-size;
      height: $image-size;
      position: relative;
      display: block;
      z-index: 10;
      img {
        display: block;
      }
    }
    .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 4px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 96px;
      margin-left: -48px;
      background: rgba($gray-subtle, 0.9);
      @include scale(1, 1);
      border-radius: 8px;
      overflow: hidden;
      .dz-upload {
        background: $brand-primary;
        // background: linear-gradient(to bottom, #666, #444);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width 300ms ease-in-out;
      }
    }
  }
}

