@charset "UTF-8";
/*
Alerts
----------------------------------------------------------------
*/



// Base styles
// -------------------------

.alert {
  font-size: $component-font-size-base;
  border: 1px solid transparent;
  width: 100%;
  padding: $alert-padding;
  margin-bottom: 0;
  position: fixed;
  opacity: 1;
  z-index: 5000;
  transition: .3s;
  @extend .dropshadow--lv3;

  &.alert-bottom {
    bottom: -150px;
    left: 0px;
    transition: .3s;
    @media (min-width: $screen-md-min) {
      width: auto;
      min-width: 400px;
      bottom: -75px;
      left: 32px;
    }

    &.show-alert {
      transform: translate(0px, -150px);
      transition: .3s;
      @media (min-width: $screen-md-min) {
        transform: translate(0px, -111px);
      }
    }
    &.hiding {
      opacity: 0;
    }
  }
  &.alert-center {
    text-align: center;
    opacity: 0;
    z-index: -1;
    min-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;

    @media (max-width: $screen-xs-max) {
      background-color: transparent;
      // height: auto;
      min-width: 100%;
      left: auto;
      margin-left: auto!important;
      padding: $s;
      .alert-message {
        background-color: rgba($alert-default-bg,0.95);
        padding: $alert-padding;
        display: block;
        border-radius: $alert-border-radius;
      }
      &.alert-dismissible,
      &.alert-dismissable {
        .alert-message {
          padding-right: $alert-padding + $alert-padding + 20px;
        }
        .close {
          right: $alert-padding;
          top: $alert-padding;
        }
      }
      &.alert-primary {
        .alert-message {
          background-color: $alert-primary-bg;
        }
      }
      &.alert-success {
        .alert-message {
          background-color: $alert-success-bg;
        }
      }
      &.alert-info {
        .alert-message {
          background-color: $alert-info-bg;
        }
      }
      &.alert-warning {
        .alert-message {
          background-color: $alert-warning-bg;
        }
      }
      &.alert-danger {
        .alert-message {
          background-color: $alert-danger-bg;
        }
      }
    }

    @media (min-width: $screen-sm-min) {
      max-width: 72%;
    }
    @media (min-width: $screen-md-min) {
      width: auto;
      max-width: none;
    }


    &.show-alert {
      display: block;
      opacity: 1;
      z-index: 9999;
      & ~ .disable {
        transition: .3s;
        opacity: .7;
      }
    }
    &.hiding {
      opacity: 0;
    }
  }
  &.alert-top-fixed {
    padding: $l $alert-padding $l $l;
    width: 100%;
    border: none;
    top: 0;
    border-radius: 0;
    box-shadow: 0 0 0 transparent;
    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.show-alert {
      display: block;
      opacity: 1;
    }
    &.hiding {
      opacity: 0;
    }
  }

  &.cl_AlertView {
    bottom: -116px;
    margin-bottom: 32px;
    margin-left: 32px;
  }



  @media (min-width: $grid-float-breakpoint) {
    border-radius: 2px 2px;
    // @include box-shadow(rgba($gray, .4) 0 8px 16px);
  }

  /*
  @media (max-width: $grid-float-breakpoint) {
    border-radius: 0;
    box-shadow: 0 0 0 transparent;
    left: 0;
    width: 100%;
    text-align: center;
  }
  */

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing $headings-color
    color: inherit;
  }
  // Provide class for links that match alerts
  .alert-link {
    font-weight: $alert-link-font-weight;
    &:hover,
    &:focus {
      opacity: .7;
    }
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }
  > p + p {
    margin-top: 5px;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable, // The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert-dismissible {
  padding-right: ($alert-padding + 20);

  // Adjust close link position
  .close {
    color: inherit;
    margin-top: -4px;
    margin-bottom: -4px;
    position: relative;
    top: 0;
    right: -21px;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-default {
  @include alert-variant($alert-default-bg, $alert-default-border, $alert-default-text);
  &.alert-top-fixed {
    @include alert-variant(lighten($alert-default-bg, 20%), $alert-default-border, $alert-default-text);
  }
}
.alert-primary {
  @include alert-variant($alert-primary-bg, $alert-primary-border, $alert-primary-text);
}
.alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}
.alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}
.alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}
.alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

.showing-alert {
  opacity: 1;
  transition: .3s;
}

.disable-operation {
  overflow: hidden;
}

.alert-dismissible {
	&.no-close-button {
		padding-right: 24px;
	}
}

.br::before {
	content: "\A" ;
	white-space: pre;
}

.alert-top-fixed--bg {
  width: 100%;
  height: 56px;
  position: fixed;
  background-color: $body-bg;
  top: 0;
  left: 0;
  z-index: 4900;
}
