@charset "UTF-8";
/*
Pager
----------------------------------------------------------------
*/



.pager {
  font-size: $component-font-size-base;
  font-weight: 700;
  color: $gray-lighter;
  background-color: transparent;
  border-radius: $border-radius-base;
  margin: 20px 0;
  text-align: center;
  list-style: none;
  display: inline-block;
  &.pager-justified {
    width: 100%;
  }

  & li {
    display: inline;

    &:first-child {
      > a,
      > span {
        border-left: none;
        border-radius: $border-radius-base 0 0 $border-radius-base;
      }
    }
    > a,
    > span {
      text-decoration: none;
      white-space: nowrap;
      line-height: $line-height-base * 0.75028571;  // 1.313
      color: $gray-light;
      border: none;
      background: none;
      border-radius: 0 $border-radius-base $border-radius-base 0;
      //border-left: 2px solid mix($brand-primary, black, 85%);
      padding: $pager-padding;
      display: inline-block;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: $btn-flat-default-hover-bg;
      }
      &:active {
        background-color: $btn-flat-default-active-bg;
      }

      [class^="icon-abui-"],
      [class*=" icon-abui-"] {
        font-weight: 700;
      }

      // Add some spacing between the icon and text
      [class*="icon-abui-"] + span {
        margin-left: $s;
      }
      span + [class*="icon-abui-"] {
        margin-left: $s;
      }
    }
    &.disabled {
      > a,
      > a:hover,
      > a:focus,
      > span {
        color: $gray-subtle;
      }
    }
  }
  & .next > {
    a, span {
      float: right;
    }
  }
  & .previous > {
    a, span {
      float: left;
    }
  }
  & .disabled > {
    a {
      cursor: not-allowed;
      background-color: #fff;
      &:hover, &:focus {
        cursor: not-allowed;
        background-color: #fff;
      }
    }
    span {
      cursor: not-allowed;
      background-color: #fff;
    }
  }
}