@charset "UTF-8";
/*
Timepicker
----------------------------------------------------------------
*/



.wickedpicker {
  user-select: none;
  border: none;
  background: $dropdown-bg;
  color: $gray;
  @extend .dropshadow--lv2;
  transition: .25s;
  border-radius: $border-radius-base;
  margin: 0 auto;
  width: 192px;
  font-size: ceil($font-size-base * 1.125);
  display: none;

  .modal-open & {
    z-index: 3011;
  }
}
.wickedpicker__title {
  display: none;
}
.wickedpicker__controls {
  line-height: normal;
  padding: $m 0;
  margin: 0;
}
.wickedpicker__controls__control,
.wickedpicker__controls__control--separator {
  vertical-align: middle;
  display: inline-block;
  font-size: inherit;
  margin: 0 $x;
  width: 36px;
  letter-spacing: 1.3px;
}
.wickedpicker__controls {
  li:nth-child(4) {
    margin-left: $l;
  }
}
.wickedpicker__controls__control-up,
.wickedpicker__controls__control-down {
  color: $gray;
  position: relative;
  display: block;
  margin: 0 auto;
  font-size: ceil($font-size-base * 0.875);
  cursor: pointer;
  height: 36px;
  width: 36px;
  padding-left: 3px;
}

.wickedpicker__controls__control-up {
  padding-top: 9px;
  &::before {
    font-family: 'ABUI-icons';
    content: "\e113";
    display: inline-block;
  }
}
.wickedpicker__controls__control-down {
  padding-top: 11px;
  &::after {
    font-family: 'ABUI-icons';
    content: "\e114";
    display: inline-block;
  }
}
.wickedpicker__controls__control--separator {
  width: 4px;
  padding: 6px 0;
  position: relative;
  top: -1px;
}
.wickedpicker__controls__control--hours,
.wickedpicker__controls__control--minutes,
.wickedpicker__controls__control--meridiem {
  display: block;
  padding: 6px 0;
  color: $gray-light;
  &:focus {
    outline: none;
  }
}

.text-center, .wickedpicker__title,
.wickedpicker__controls,
.wickedpicker__controls__control,
.wickedpicker__controls__control--separator,
.wickedpicker__controls__control-up,
.wickedpicker__controls__control-down {
  text-align: center;
}

.hover-state {
  background-color: $btn-hover-bg;
  color: $inverse;
  border-radius: $border-radius-base;
}


.input-with-icon {
  .input-timepicker + .input-icon {
    z-index: 3;
  }
}




