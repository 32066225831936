@charset "UTF-8";
/*
Mark
----------------------------------------------------------------
*/


$label-height: 13px;
.label {
	font-size: ceil($font-size-base * 0.625);
	font-weight: bold;
	white-space: nowrap;
	line-height: $label-height;
	text-align: center;
	vertical-align: baseline;
	color: $inverse;
	border-radius: $label-height / 2;
	height: $label-height;
	min-height: $label-height;
	padding: 0 6px;
	display: inline-block;
	margin-left: $x;
	margin-right: $x;
  position: relative;
	top: -2px;
	&.label-default {
		background-color: $gray-light;
	}
	&.label-primary {
		background-color: $brand-primary;
	}
	&.label-success {
		background-color: $brand-success;
	}
	&.label-info {
		background-color: $brand-info;
	}
	&.label-warning {
		background-color: $brand-warning;
	}
	&.label-danger {
		background-color: $brand-danger;
	}
}

.label-before {
	margin-left: 0;
}
.label-middle {
	margin-right: $x;
}

a.label {
  &:hover, &:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
}
