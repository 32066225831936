@charset "UTF-8";
/*
Tooltip
----------------------------------------------------------------
*/



.tooltip {
  font-size: ceil($component-font-size-base * 0.9285); // ~13px
  line-height: $line-height-base * 0.8; // 1.4

  &.in {
    opacity: 1;
  }
  &.top {
    padding-bottom: 9px;
    .tooltip-arrow {
      border-top-color: $gray-light;
      border-width: 8px 6px 0;
      bottom: 1px;
      margin-left: -8px;
    }
  }
  &.right {
    .tooltip-arrow {
      border-right-color: $gray-light;
      border-width: 6px 8px 6px 0;
      margin-top: -6px;
      left: -3px;
    }
  }
  &.bottom {
    padding-top: $s;
    .tooltip-arrow {
      border-bottom-color: $gray-light;
      border-width: 0 6px 8px;
      margin-left: -8px;
      top: 0;
    }
  }
  &.left {
    .tooltip-arrow {
      border-left-color: $gray-light;
      border-width: 6px 0 6px 8px;
      margin-top: -6px;
      right: -3px;
    }
  }
}

.tooltip-inner {
  background-color: $gray-light;
  color: $inverse;
  padding: $m;
  text-align: center;
  // min-width: 183px;
  max-width: none;
  border-radius: $border-radius-base;
  small, .small {
    line-height: $line-height-base * 0.8; // 1.4
  }
}


// Color Valiation
.tooltip-primary {
  @include tooltip-variant($brand-primary);
}
.tooltip-success {
  @include tooltip-variant($brand-success);
}
.tooltip-info {
  @include tooltip-variant($brand-info);
}
.tooltip-warning {
  @include tooltip-variant($brand-warning);
}
.tooltip-danger {
  @include tooltip-variant($brand-danger);
}
.tooltip-inverse {
  @include tooltip-variant($gray-subtler);
  &+.tooltip {
   .tooltip-inner {
      color: $gray-light;
    }
  }
}

// Login Page
.login-tooltip--user-account {
  .tooltip{
    .tooltip-arrow{
      border-top-color: $brand-danger;
    }
    .tooltip-inner{
      background-color: $brand-danger;
      width: 100%;
      max-width: 100%;
    }
  }
}

.login-tooltip--password {
  .tooltip{
    .tooltip-arrow{
      border-top-color: $brand-danger;
    }
    .tooltip-inner{
      background-color: $brand-danger;
      width: 100%;
      max-width: 100%;
    }
  }
}


// inline guide
.inline-guide {
  position: relative;
  .label-icon-guide {
    &::before {
      font-family: 'ABUI-icons';
      font-size: ceil($font-size-base * 0.75);
      font-weight: normal;
      font-variant: normal;
      color: $gray-light;
      speak: none;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e085";
      padding-left: $x;
      position: absolute;
      display: inline-block;
      width: ceil($font-size-base * 0.75);
      height: ceil($font-size-base * 0.75);
    }
    &+.tooltip {
      margin-left: ceil($font-size-base * 0.75);
    }
  }
}
.form-group,
.form-inline {
  &.required {
    > label,
    > .label-additional {
      &.inline-guide {
        .label-icon-guide {
          padding-left: $x + $s;
          &+.tooltip {
            margin-left: ceil($font-size-base * 0.75) + 6px;
          }
        }
      }
    }
  }
}