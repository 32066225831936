@charset "UTF-8";
/*
Datepicker
----------------------------------------------------------------
for jquery-ui-1.10.4.css
*/


$ui-datepicker-width: 17em;

.ui-datepicker {
  width: auto;  // 'auto'が無いと表示位置がズレる
  padding: $m $m $x;
  display: none;
  font-size: ceil($component-font-size-base*1.1428);

  .ui-datepicker-header {
    font-size: $component-font-size-base;
    border-bottom-radius: 0;
    border: none;
    height: 36px;
    padding: 0;
    position: relative;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    position: absolute;
    top: 0;
    width: 36px;
    height: 36px;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      left: 50%;
      // margin-left: -8px;
      margin-left: -6px;
      top: 50%;
      // margin-top: -8px;
      margin-top: -6px;
      &:before {
        font-family: 'ABUI-icons';
        // font-size: 16px;
        font-size: ceil($component-font-size-base*0.833);
        line-height: 1;
        // color: $inverse;
        color: $gray;
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
      }
    }
    .ui-icon {
      text-indent: 0;
      color: transparent;
      width: 16px;
      height: 16px;
    }
  }
  .ui-datepicker-prev-hover,
  .ui-datepicker-next-hover {
    top: 0;
    border: none;
    // background: $btn-primary-hover-bg;
    background: $btn-hover-bg;
    span {
      &:before {
        color: $inverse;
      }
    }
  }
  .ui-datepicker-prev {
    left: 0;
    span {
      &:before {
        content: "\e079";
      }
    }
  }
  .ui-datepicker-next {
    right: 0;
    span {
      &:before {
        content: "\e080";
      }
    }
  }
  .ui-datepicker-prev-hover {
    left: 0;
    // @include border-right-radius(0);
    // @include border-bottom-left-radius(0);
  }
  .ui-datepicker-next-hover {
    right: 0;
    // @include border-left-radius(0);
    // @include border-bottom-right-radius(0);
  }

  .ui-datepicker-title {
    margin: 0;
    line-height: 36px;
    padding: 0 40px;
    text-align: center;
    height: 36px;
    select {
      font-size: 0.95em;
      margin: 0;
      border: none;
      color: $gray;
      font-weight: normal;
      height: 24px;
      margin-right: 4px;
      margin-left: 4px;
      border-radius: $border-radius-base;
      background: white;
    }
  }
  select.ui-datepicker-month,
  select.ui-datepicker-year {
    width: 40%;
  }

  table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em;
    background: none;
  }
  th {
    padding: .7em .3em;
    text-align: center;
    font-weight: bold;
    border: 0;
    &.ui-datepicker-week-col {
      text-align: center;
      color: $gray-lighter;
    }
  }
  td {
    border: 0;
    padding: 1px;
    span,
    a {
      display: block;
      padding: .2em;
      text-align: right;
      text-decoration: none;
      &.ui-state-default:not(.ui-state-active):not(.ui-state-hover) {
        background: transparent;
      }
    }
    &.ui-datepicker-week-col {
      padding: 4px 8px;
      text-align: center;
      color: $gray-lighter;
      font-size: 12px;
    }
  }
  .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    button {
      float: right;
      margin: .5em .2em .4em;
      cursor: pointer;
      padding: .2em .6em .3em .6em;
      width: auto;
      overflow: visible;
      font-size: floor($component-font-size-base*0.928);
      &.ui-datepicker-current {
        float: left;
      }
    }
  }
  &.ui-datepicker-multi {
    width: auto;
  }

  .ui-datepicker-week-end {
  	&.date-sun {
  		> .ui-state-default {
  			color: $warm-6;
        &.ui-state-active {
          color: $inverse;
        }
        &.ui-state-hover {
          color: $inverse !important;
        }
  		}
  	}
  	&.date-sat {
  		> .ui-state-default {
  			color: $cool-6;
        &.ui-state-active {
          color: $inverse;
        }
        &.ui-state-hover {
          color: $inverse !important;
        }
  		}
  	}
  }
}

.ui-datepicker-multi {
  .ui-datepicker-group {
    float: left;
    table {
      width: 98%;
      margin: 0 auto .4em;
    }
  }
  .ui-datepicker-group-first {
    table {
      margin-left:0;
    }
  }
  .ui-datepicker-group-middle {
    table {
      width: 96%;
    }
  }
  .ui-datepicker-group-last {
    table {
      margin-right:0;
    }
  }
  .ui-datepicker-group-first,
  .ui-datepicker-group-middle {
   border-right: 1px solid white;
  }
  .ui-datepicker-group-last .ui-datepicker-header,
  .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0;
  }
  .ui-datepicker-buttonpane {
    clear: left;
  }

}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

/*
#ui-datepicker-div {
  &.ui-datepicker-multi-2 {
    width: $ui-datepicker-width*2 !important;
  }
  &.ui-datepicker-multi-3 {
    width: $ui-datepicker-width*3 !important;
  }
  &.ui-datepicker-multi-4 {
    width: $ui-datepicker-width*4 !important;
  }
}
*/

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}


/* RTL support */
.ui-datepicker-rtl {
  direction: rtl;
  .ui-datepicker-prev {
    right: 2px;
    left: auto;
    &:hover {
      right: 1px;
      left: auto;
    }
  }
  .ui-datepicker-next {
    left: 2px;
    right: auto;
    &:hover {
      left: 1px;
      right: auto;
    }
  }
  .ui-datepicker-buttonpane {
    clear: right;
    button {
      float: left;
      &.ui-datepicker-current {
        float: right;
      }
    }
  }
  .ui-datepicker-group {
    float: right;
  }
  .ui-datepicker-group-last .ui-datepicker-header,
  .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
  }
}



// Hour and Minutes Add-on
.hour-minute {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  span {
    padding: 0 4px;
  }
  select {
    appearance: none;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #e1e6e9;
    color: #54666f;
    font-size: 88%;
    padding: 0 16px;
    border-radius: $border-radius-base;
    height: 32px;
  }
  button {
    float: right;
    font-size: 88%;
    height: 32px;
    transition: 0.25s;
    &.ui-state-default,
    &.ui-widget-content .ui-state-default,
    &.ui-widget-header .ui-state-default {
      &.btn-default {
        color: white;
        background-color: #92a4ad;
        padding: 4px 16px;
        &:hover {
          background: mix(#92a4ad, white, 80%);;
        }
      }

    }
  }
}


.ui-widget-header {
  background-color: transparent;
  color: $gray;
}
.ui-datepicker-calendar {
  th {
    color: $brand-primary;
    font-size: 83%;
  }
  .ui-state-default {
    text-align: center;
    border-radius: $border-radius-base;
    &.ui-state-hover {
      background-color: $btn-hover-bg;
      color: $inverse;
    }
  }
}

.ui-datepicker-today {
  background: white;
  a {
    color: $brand-primary;
  }
}

button.ui-datepicker-trigger {
  background: none;
  border: none;
  outline: none;
  appearance: none;
  color: $gray-subtle;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 7px;

  .form-control:focus + & {
    color: $brand-primary;
  }

  /* 期間指定のアイコン位置 */
  .input-group & {
    right: 42px;
    z-index: 3;
  }
}


//weekpickerカスタマイズ
.ui-weekpicker-calendar {
  transition: .1s;
  &.hideWeekpicker {
    display: none !important;
  }
  &.fadeInWeekpicker {
    opacity: 1;
  }
  &.withWeekNum {
    tr {
      &.ui-datepicker-current-week {
        td.ui-datepicker-current-day:nth-child(2) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        td.ui-datepicker-current-day:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      td.ui-state-hover {
        &:nth-child(2) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      &.ui-datepicker-today.ui-state-hover {
        background: $btn-hover-bg;
        a {
          background: transparent;
          color: white;
        }
      }
    }
  }
  tr {
    &.ui-datepicker-current-week {
      td.ui-datepicker-current-day {
        background: $astro-blue;
        &.ui-state-hover {
          background: $btn-hover-bg;
          a {
            background: $btn-hover-bg;
          }
        }
      }
      td.ui-datepicker-current-day:nth-child(1) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      td.ui-datepicker-current-day:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      // td.ui-state-hover {
      //   background: $brand-primary !important;
      // }
    }

    td {
      transition: .25s;
      // a {
      //   transition: none;
      // }
      &.ui-datepicker-current-day{
        a.ui-state-default {
          background: $brand-primary;
          color: white;
        }
      }

      &.ui-datepicker-today.ui-datepicker-current-day {
        &.reset-ui-active {
          background: white;
          a {
            background: white;
            color: $brand-primary;
          }

          &.ui-state-hover {
            border: none;
            background: $btn-hover-bg;
            a {
              background: $btn-hover-bg;
              color: white;
            }
          }
        }
        &.ui-state-hover {
          border: none;
          background: $btn-hover-bg;
          color: white;
        }
        a.ui-state-active {
          // color: $gray-light;
          &.ui-state-hover {
            color: white;
          }
        }
      }

      &.ui-state-hover {
        border: none;
        background: $btn-hover-bg;
        &:nth-child(1) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        a {
          color: white;
        }
        .ui-state-default {
          background: transparent;
        }
      }
      &.ui-datepicker-today.ui-state-hover {
        background: $btn-hover-bg;
        a {
          background: transparent;
          color: white;
        }
      }
    }
  }
}


// 六曜を表示させる場合
// datepickerカスタマイズ
.ui-datepicker {
  &.ui-datepicker-width-rokuyo {
    .sensho > a::after {
      content: "先勝";
      display: block;
      text-align: center;
      font-size: 10px;
      position: relative;
      top: -6px;
    }
    .tomobiki > a::after {
      content: "友引";
      display: block;
      text-align: center;
      font-size: 10px;
      position: relative;
      top: -6px;
    }
    .senpu > a::after {
      content: "先負";
      display: block;
      text-align: center;
      font-size: 10px;
      position: relative;
      top: -6px;
    }
    .butsumetsu > a::after{
      content: "仏滅";
      display: block;
      text-align: center;
      font-size: 10px;
      position: relative;
      top: -6px;
    }
    .taian > a::after {
      content: "大安";
      display: block;
      text-align: center;
      font-size: 10px;
      position: relative;
      top: -6px;
    }
    .shakku > a::after {
      content: "赤口";
      display: block;
      text-align: center;
      font-size: 10px;
      position: relative;
      top: -6px;
    }
  }
}
