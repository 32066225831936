@charset "UTF-8";
/*
Button
----------------------------------------------------------------
*/

// Base styles
// --------------------------------------------------

.btn {
  font-size: $btn-font-size-base;
  font-weight: $btn-font-weight;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: $btn-line-height-base;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  border: none;
  border-radius: $border-radius-base;
  background-image: none;
  padding: $m 13px;
  transition: .25s;
  display: inline-block;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:focus {
    outline: none;
    color: $btn-default-color;
  }

  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background-color: $btn-link-disabled-color;
    color: fade($btn-default-color, 75%);
    opacity: .7;
  }

  // Button icons
  // --------------------------------------------------
  > [class^="icon-abui-"],
  > [class*=" icon-abui-"] {
    margin: 0 1px;
    position: relative;
    line-height: 1;
    font-weight: 700;
    top: 1px;
  }
  // &.btn-hg {
  //   > [class^="icon-abui-"],
  //   > [class*=" icon-abui-"] {
      // line-height: $btn-line-height-hg;
  //   }
  // }
  // &.btn-lg {
  //   > [class^="icon-abui-"],
  //   > [class*=" icon-abui-"] {
      // line-height: $btn-line-height-lg;
  //   }
  // }
  // &.btn-sm {
  //   > [class^="icon-abui-"],
  //   > [class*=" icon-abui-"] {
      // line-height: $btn-line-height-sm;
  //   }
  // }
  // &.btn-xs {
  //   > [class^="icon-abui-"],
  //   > [class*=" icon-abui-"] {
      // line-height: $btn-line-height-xs;
  //   }
  // }
}

// Alternate buttons
// --------------------------------------------------
.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-hover-bg, $btn-active-bg);
}
.btn-primary {
  @include button-variant($btn-default-color, $brand-primary, $btn-primary-hover-bg, $btn-primary-active-bg);
}
.btn-info    {
  @include button-variant($btn-default-color, $brand-info, $btn-info-hover-bg, $btn-info-active-bg);
}
.btn-danger  {
  @include button-variant($btn-default-color, $brand-danger, $btn-danger-hover-bg, $btn-danger-active-bg);
}
.btn-success {
  @include button-variant($btn-default-color, $brand-success, $btn-success-hover-bg, $btn-success-active-bg);
}
.btn-warning {
  @include button-variant($btn-default-color, $brand-warning, $btn-warning-hover-bg, $btn-warning-active-bg);
}


// Inverse Button
[class^="btn-inverse"], [class*=" btn-inverse"] {
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $gray-subtle;
      color: $gray-lighter;
    }
  }
  border-width: 1px;
  border-style: solid;
  padding: ($m - 1) $m;
}
.btn-inverse {
  @include button-variant($gray-light, transparent, $btn-inverse-hover-bg, $btn-inverse-active-bg, white);
  border-color: $gray-light;
  &:hover,
  &:focus {
    border: 1px solid $btn-inverse-hover-bg;
  }
}
.btn-inverse-primary {
  @include button-variant($brand-primary, transparent, $btn-flat-primary-hover-bg, $btn-flat-primary-active-bg, transparent);
  border-color: $brand-primary;
  &:hover,
  &:focus {
    color: $btn-primary-hover-bg;
    border: 1px solid $btn-flat-primary-active-bg;
  }
}
.btn-inverse-info {
  @include button-variant($brand-info, transparent, $btn-flat-info-hover-bg, $btn-flat-info-active-bg, transparent);
  border-color: $brand-info;
  &:hover,
  &:focus {
    color: $btn-info-hover-bg;
    border: 1px solid $btn-flat-info-active-bg;
  }
}
.btn-inverse-danger {
  @include button-variant($brand-danger, transparent, $btn-flat-danger-hover-bg, $btn-flat-danger-active-bg, transparent);
  border-color: $brand-danger;
  &:hover,
  &:focus {
    color: $btn-danger-hover-bg;
    border: 1px solid $btn-flat-danger-active-bg;
  }
}
.btn-inverse-success {
  @include button-variant($brand-success, transparent, $btn-flat-success-hover-bg, $btn-flat-success-active-bg, transparent);
  border-color: $brand-success;
  &:hover,
  &:focus {
    color: $btn-success-hover-bg;
    border: 1px solid $btn-flat-success-active-bg;
  }
}
.btn-inverse-warning {
  @include button-variant($brand-warning, transparent, $btn-flat-warning-hover-bg, $btn-flat-warning-active-bg, transparent);
  border-color: $brand-warning;
  &:hover,
  &:focus {
    color: $btn-warning-hover-bg;
    border: 1px solid $btn-flat-warning-active-bg;
  }
}


// Flat Button
[class^="btn-flat-"], [class*=" btn-flat-"] {
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $gray-light;
    }
  }
}
.btn-flat-default {
  @include button-variant($btn-default-bg, transparent, $btn-flat-default-hover-bg, $btn-flat-default-active-bg, transparent);
}
.btn-flat-primary {
  @include button-variant($brand-primary, transparent, $btn-flat-primary-hover-bg, $btn-flat-primary-active-bg, transparent);
}
.btn-flat-info {
  @include button-variant($brand-info, transparent, $btn-flat-info-hover-bg, $btn-flat-info-active-bg, transparent);
}
.btn-flat-danger {
  @include button-variant($brand-danger, transparent, $btn-flat-danger-hover-bg, $btn-flat-danger-active-bg, transparent);
}
.btn-flat-success {
  @include button-variant($brand-success, transparent, $btn-flat-success-hover-bg, $btn-flat-success-active-bg, transparent);
}
.btn-flat-warning {
  @include button-variant($brand-warning, transparent, $btn-flat-warning-hover-bg, $btn-flat-warning-active-bg, transparent);
}





// Hover Button
.btn-hover-primary {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-primary-hover-bg, $btn-primary-active-bg);
}
.btn-hover-info    {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-info-hover-bg, $btn-info-active-bg);
}
.btn-hover-danger  {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-danger-hover-bg, $btn-danger-active-bg);
}
.btn-hover-success {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-success-hover-bg, $btn-success-active-bg);
}
.btn-hover-warning {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-warning-hover-bg, $btn-warning-active-bg);
}

// Inverse Hover Button
.btn-inverse-hover-primary {
  @include button-variant($gray-light, transparent, $btn-primary-hover-bg, $btn-primary-active-bg, white);
  border: 1px solid $gray-light;
  padding: ($m - 1) $m;
  &:hover,
  &:focus {
    color: $inverse;
    border: 1px solid $btn-primary-hover-bg;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $gray-lighter;
      color: $gray-lighter;
    }
  }
}
.btn-inverse-hover-info {
  @include button-variant($gray-light, transparent, $btn-info-hover-bg, $btn-info-active-bg, white);
  border: 1px solid $gray-light;
  padding: ($m - 1) $m;
  &:hover,
  &:focus {
    color: $inverse;
    border: 1px solid $btn-info-hover-bg;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $gray-lighter;
      color: $gray-lighter;
    }
  }
}
.btn-inverse-hover-danger {
  @include button-variant($gray-light, transparent, $btn-danger-hover-bg, $btn-danger-active-bg, white);
  border: 1px solid $gray-light;
  padding: ($m - 1) $m;
  &:hover,
  &:focus {
    color: $inverse;
    border: 1px solid $btn-danger-hover-bg;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $gray-lighter;
      color: $gray-lighter;
    }
  }
}
.btn-inverse-hover-success {
  @include button-variant($gray-light, transparent, $btn-success-hover-bg, $btn-success-active-bg, white);
  border: 1px solid $gray-light;
  padding: ($m - 1) $m;
  &:hover,
  &:focus {
    color: $inverse;
    border: 1px solid $btn-success-hover-bg;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $gray-lighter;
      color: $gray-lighter;
    }
  }
}
.btn-inverse-hover-warning {
  @include button-variant($gray-light, transparent, $btn-warning-hover-bg, $btn-warning-active-bg, white);
  border: 1px solid $gray-light;
  padding: ($m - 1) $m;
  &:hover,
  &:focus {
    color: $inverse;
    border: 1px solid $btn-warning-hover-bg;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $gray-lighter;
      color: $gray-lighter;
    }
  }
}


.btn-input {
  @include button-variant($btn-default-color, white, $btn-input-hover-bg, $btn-input-active-bg,$input-bg-disabled);
  border: 1px solid $input-border-color;
  color: $gray;
  padding: ($m - 1) $m;
  &:hover {
    border: 1px solid $input-border-color;
    color: $gray;
  }
  &:active,
  &:focus {
    border: 1px solid $brand-primary;
    color: $gray;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $gray;
    }
  }
}


// .btn-embossed {
//   @include box-shadow(inset 0 -2px 0 fade(black, 15%));

//   &.active,
//   &:active {
//     @include box-shadow(inset 0 2px 0 fade(black, 15%));
//   }
// }

.btn-wide {
  min-width: 240px;
  padding-left: $l * 2;
  padding-right: $l * 2;
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: $link-color;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Button sizes
// --------------------------------------------------

.btn-hg {
  @include button-size(19px, 21px, $btn-font-size-hg, $btn-line-height-hg, $border-radius-base);
  &[class^="btn-inverse"], &[class*=" btn-inverse"] {
    padding: 18px 20px;
  }
}
.btn-lg {
  @include button-size(14px, 15px, $btn-font-size-lg, $btn-line-height-lg, $border-radius-base);
  &[class^="btn-inverse"], &[class*=" btn-inverse"] {
    padding: 13px 14px;
  }
}
.btn-sm {
  @include button-size(9px, 11px, $btn-font-size-sm, $btn-line-height-sm, $border-radius-base);
  &[class^="btn-inverse"], &[class*=" btn-inverse"] {
    padding: 8px 10px;
  }
}
.btn-xs {
  @include button-size(6px, 6px, $btn-font-size-xs, $btn-line-height-xs, $border-radius-base);
  &[class^="btn-inverse"], &[class*=" btn-inverse"] {
    padding: 5px 5px;
  }
}


// Button tip
// --------------------------------------------------
.btn-tip {
  font-size: 92%;
  font-weight: 300;
  padding-left: 10px;
}


// Block button
// --------------------------------------------------

.btn-block {
  white-space: normal;
}


// Dropdown button
// --------------------------------------------------

.open {
  > .dropdown-toggle {
    &.btn-default {
      color: $inverse;
      background-color: $btn-hover-bg;
    }
    &.btn-primary {
      background-color: $btn-primary-hover-bg;
    }
    &.btn-success {
      background-color: $btn-success-hover-bg;
    }
    &.btn-info {
      background-color: $btn-info-hover-bg;
    }
    &.btn-warning {
      background-color: $btn-warning-hover-bg;
    }
    &.btn-danger {
      background-color: $btn-danger-hover-bg;
    }
    .caret {
      border-top-color: $brand-primary;
      border-bottom-color: $brand-primary;
    }
  }
}


// Close button
// --------------------------------------------------

button {
  &.close {
    opacity: 1;
    color: $gray-lighter;
    width: 32px;
    height: 32px;
    font-size: ceil($component-font-size-base * 1);
    &:hover {
      color: mix($gray-lighter, white, 70%);
    }
    &:after {
      display: block;
      font-family: 'ABUI-icons';
      content: "\e014";
      font-weight: normal;
      font-style: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 2px;
    }
    span[aria-hidden="true"] {
      display: none;
    }
  }
}



// Buttons Row
// --------------------------------------------------

.btn-row {
margin-top: 20px;
}

// Button Helper Class
// --------------------------------------------------
.btn--ls {
  margin-top: 21px;
}
