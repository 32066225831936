@charset "UTF-8";
/*
Breadcrumb
----------------------------------------------------------------
*/



.breadcrumb {
    font-size: 0;
    padding: 11px 0;
    margin-bottom: 20px;
    list-style: none;
    background-color: transparent;
    // @include border-top-radius($border-radius-base);
    > {
        li {
            font-size: ceil($component-font-size-base*0.928);
            display: inline-block;
            + li::before {
                padding: 0 5px;
                color: #ccc;
                content: "/\00a0";
            }
        }
        .active {
          color: #777;
        }
    }
}