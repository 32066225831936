@charset "UTF-8";
/*
Mixins
----------------------------------------------------------------
*/


/*
Clearfix
Source: http://nicolasgallagher.com/micro-clearfix-hack/

For modern browsers
1. The space content is one way to avoid an Opera bug when the
   contenteditable attribute is included anywhere else in the document.
   Otherwise it causes space to appear at the top and bottom of elements
   that are clearfixed.
2. The use of `table` rather than `block` is only necessary if using
   `:before` to contain the top-margins of child elements.
*/

@mixin micro-clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Webkit-style focus
@mixin tab-focus() {
  // Default
  outline: thin dotted;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
  width: $width;
  height: $height;
}
@mixin square($size) {
  @include size($size, $size);
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  &:-moz-placeholder            { color: $color; } // Firefox 4-18
  &::-moz-placeholder           { color: $color;   // Firefox 19+
                                  opacity: 1; } // See https://github.com/twbs/bootstrap/pull/11526
  &:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
  &.placeholder                 { color: $color; } // Fallback
}

@mixin placeholder-height($height) {
  &:-moz-placeholder {
    line-height: $height;
  }
  &::-webkit-input-placeholder {
    line-height: $height;
  }
  &.placeholder {
    line-height: $height;
  }
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CSS image replacement
@mixin text-hide() {
  font: 0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// Caret
@mixin caret($color: $gray-subtle) {
  border-bottom-color: $color;
  border-top-color: $color;
}


// CSS3 PROPERTIES
// --------------------------------------------------

@mixin mask($arguments) {
  -webkit-mask: $arguments;
          mask: $arguments;
}

// // Single side border-radius
// @mixin border-top-radius($radius) {
//   border-top-right-radius: $radius;
//    border-top-left-radius: $radius;
// }
// @mixin border-right-radius($radius) {
//   border-bottom-right-radius: $radius;
//      border-top-right-radius: $radius;
// }
// @mixin border-bottom-radius($radius) {
//   border-bottom-right-radius: $radius;
//    border-bottom-left-radius: $radius;
// }
// @mixin border-left-radius($radius) {
//   border-bottom-left-radius: $radius;
//      border-top-left-radius: $radius;
// }

// // Drop shadows
// @mixin box-shadow($shadow) {
//   -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
//           box-shadow: $shadow;
// }

// Transitions
// @mixin transition($transition) {
//   -webkit-transition: $transition;
//           transition: $transition;
// }
// @mixin transition-property($transition-property) {
//   -webkit-transition-property: $transition-property;
//           transition-property: $transition-property;
// }
// @mixin transition-delay($transition-delay) {
//   -webkit-transition-delay: $transition-delay;
//           transition-delay: $transition-delay;
// }
// @mixin transition-duration($transition-duration) {
//   -webkit-transition-duration: $transition-duration;
//           transition-duration: $transition-duration;
// }
// @mixin transition-transform($transition) {
//   -webkit-transition: -webkit-transform $transition;
//      -moz-transition: -moz-transform $transition;
//        -o-transition: -o-transform $transition;
//           transition: transform $transition;
// }

// Transformations
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9+
          transform: rotate($degrees);
}
@mixin scale($ratio, $ratio-y) {
  -webkit-transform: scale($ratio, $ratio-y);
      -ms-transform: scale($ratio, $ratio-y); // IE9 only
          transform: scale($ratio, $ratio-y);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9+
          transform: translate($x, $y);
}
@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
      -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
          transform: skew($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}

@mixin rotateX($degrees) {
  -webkit-transform: rotateX($degrees);
      -ms-transform: rotateX($degrees); // IE9+
          transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
      -ms-transform: rotateY($degrees); // IE9+
          transform: rotateY($degrees);
}
@mixin perspective($perspective) {
  -webkit-perspective: $perspective;
     -moz-perspective: $perspective;
          perspective: $perspective;
}
@mixin perspective-origin($perspective) {
  -webkit-perspective-origin: $perspective;
     -moz-perspective-origin: $perspective;
          perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
     -moz-transform-origin: $origin;
      -ms-transform-origin: $origin; // IE9 only
          transform-origin: $origin;
}


/*
  Animations
*/
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

$prefixes: ("-webkit-", "-moz-", null);
@mixin animation($name, $duration, $timing, $delay, $count, $direction: null) {
  @each $prefix in $prefixes {
      #{$prefix}#{animation}: $name $duration $timing $delay $count $direction;
  }
}

@mixin animation($animation) {
  -webkit-animation: $animation;
          animation: $animation;
}

@mixin animation-name($name) {
  -webkit-animation-name: $name;
          animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
          animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
          animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
          animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
          animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
          animation-direction: $direction;
}



// // Backface visibility
// // Prevent browsers from flickering when using CSS 3D transforms.
// // Default value is `visible`, but can be changed to `hidden`
// // See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
// @mixin backface-visibility($visibility){
//   -webkit-backface-visibility: $visibility;
//      -moz-backface-visibility: $visibility;
//           backface-visibility: $visibility;
// }

// // Background clip
// @mixin background-clip($clip: border-box) {
//   -webkit-background-clip: $clip;
//      -moz-background-clip: $clip;
//           background-clip: $clip;
// }

// // Box sizing
// @mixin box-sizing($boxmodel) {
//   -webkit-box-sizing: $boxmodel;
//      -moz-box-sizing: $boxmodel;
//           box-sizing: $boxmodel;
// }

// User select
// For selecting text on the page
@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select; // IE10+
       -o-user-select: $select;
          user-select: $select;
}

// Resize anything
@mixin resizable($direction) {
  resize: $direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
     -moz-column-count: $column-count;
          column-count: $column-count;
  -webkit-column-gap: $column-gap;
     -moz-column-gap: $column-gap;
          column-gap: $column-gap;
}

// // Optional hyphenation
// @mixin hyphens($mode: auto) {
//   word-wrap: break-word;
//   -webkit-hyphens: $mode;
//      -moz-hyphens: $mode;
//       -ms-hyphens: $mode; // IE10+
//        -o-hyphens: $mode;
//           hyphens: $mode;
// }

// // Opacity
// @mixin opacity($opacity) {
//   opacity: $opacity;
//   // IE8 filter
//   $opacity-ie: ($opacity * 100);
//   filter: alpha(opacity=$opacity-ie);
// }



// GRADIENTS
// --------------------------------------------------

#gradient {

  // Horizontal gradient, from left to right
  //
  // Creates two color stops, start and end, by specifying a color and position for each color stop.
  // Color stops are not available in IE9 and below.
  @mixin horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background-image: -webkit-linear-gradient(left, color-stop($start-color $start-percent), color-stop($end-color $end-percent)); // Safari 5.1-6, Chrome 10+
    background-image:  linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
    // filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb($start-color),argb($end-color))); // IE9 and down
  }

  // Vertical gradient, from top to bottom
  //
  // Creates two color stops, start and end, by specifying a color and position for each color stop.
  // Color stops are not available in IE9 and below.
  @mixin vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
    // filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb($start-color),argb($end-color))); // IE9 and down
  }

  @mixin directional($start-color: #555, $end-color: #333, $deg: 45deg) {
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
    background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  }
  @mixin horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
    background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
    background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
    background-repeat: no-repeat;
    // filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb($start-color),argb($end-color))); // IE9 and down, gets no color-stop at all for proper fallback
  }
  @mixin vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
    background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
    background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
    background-repeat: no-repeat;
    // filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb($start-color),argb($end-color))); // IE9 and down, gets no color-stop at all for proper fallback
  }
  @mixin radial($inner-color: #555, $outer-color: #333) {
    background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
    background-image: radial-gradient(circle, $inner-color, $outer-color);
    background-repeat: no-repeat;
  }
  @mixin striped($color: rgba(255,255,255,.15), $angle: 45deg) {
    background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  }
}

// Reset filters for IE
//
// When you need to remove a gradient background, do not forget to use this to reset
// the IE filter for IE9 and below.
// @mixin reset-filter() {
//   filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
// }



// Retina images
//
// Short retina mixin for setting background-image and -size

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url($file-1x);

  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    background-image: url($file-2x);
    background-size: $width-1x $height-1x;
  }
}


// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
@mixin responsive-visibility() {
  display: block !important;
  tr & { display: table-row !important; }
  th &,
  td & { display: table-cell !important; }
}

@mixin responsive-invisibility() {
    &,
  tr &,
  th &,
  td & { display: none !important; }
}

// Grid System
// -----------

// Centered container element
@mixin container-fixed() {
  margin-right: auto;
  margin-left: auto;
  padding-left:  ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  @include micro-clearfix();
  // @include clearfix();
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
  @include micro-clearfix();
  // @include clearfix();
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
  @media (min-width: $screen-xs-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xs-column-push($columns) {
  @media (min-width: $screen-xs-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xs-column-pull($columns) {
  @media (min-width: $screen-xs-min) {
    right: percentage(($columns / $grid-columns));
  }
}


// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}


// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-md-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}


// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-lg-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}


// COMPONENT MIXINS
// --------------------------------------------------

// Color swatches grid
@mixin calc-color($first-color, $second-color) {
  .palette-#{$first-color} {
    background-color: $first-color;
  }
  .palette-#{$second-color}  {
    background-color: $second-color;
  }
}

// Dropdown arrow
// -------------------------
@mixin dropdown-arrow($color: $brand-primary, $top: 5px, $left: 15px, $size: 9px) {
  &:before {
    content: "";
    border-style: solid;
    border-width: 0 $size $size $size;
    border-color: transparent transparent $color transparent;
    height: 0;
    position: absolute;
    left: $left;
    top: $top;
    width: 0;
    // Make corners smooth
    -webkit-transform: rotate(360deg);
  }
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $hover-background, $active-background, $disabled-background: $gray-subtle) {
  color: $color;
  background-color: $background;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open .dropdown-toggle {
    color: $color;
    background-color: $hover-background;
    border-color: $hover-background;
  }
  &:active,
  &.active,
  .open .dropdown-toggle,
  &:active:hover, &.active:hover,
  &:active:focus, &.active:focus,
  &:active.focus, &.active.focus {
    background: $active-background;
    border-color: $active-background;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $disabled-background;
      border-color: $disabled-background;
      color: $gray-light;
    }
  }
}

// @mixin button-variant($color, $background, $hover-background, $active-background, $border: $background, $disabled-background: $gray-light) {
//   color: $color;
//   background-color: $background;
//   border: 1px solid $border;

//   &:hover,
//   &:focus,
//   &:active,
//   &.active,
//   .open .dropdown-toggle {
//     color: $color;
//     background-color: $hover-background;
//     border-color: $hover-background;
//   }
//   &:active,
//   &.active,
//   .open .dropdown-toggle {
//     background: $active-background;
//     border-color: $active-background;
//   }
//   &.disabled,
//   &[disabled],
//   fieldset[disabled] & {
//     &,
//     &:hover,
//     &:focus,
//     &:active,
//     &.active {
//       background-color: $background;
//       border-color: $background;
//     }
//   }
// }


// Button sizes
// -------------------------
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

// Social button variants
// -------------------------
@mixin social-button-variant($color, $background) {
  color: $color;
  background-color: $background;

  &:hover,
  &:focus {
    background-color: mix($background, white, 80%);
  }
  &:active,
  &.active {
    background-color: mix($background, black, 85%);
  }
}

@mixin swap-dialog-color($color) {
  background-color: mix($color, white, 9%);
  border-top-color: mix($color, white, 20%);
}

@mixin dropdown-arrow-inverse {
  border-bottom-color: $brand-primary !important;
  border-top-color: $brand-primary !important;
}

@mixin swap-iconbar-color($color) {
  background-color: $color;
  > ul {
    > li {
      &.active {
        > a {
          color: mix($color, black, 66%);
        }
      }
      > a {
        &:hover, &:focus {
          color: mix($color, black, 66%);
        }
      }
    }
  }
  .iconbar-unread { background-color: mix($color, black, 66%); }
}

@mixin swap-pagination-color($color, $hover, $active) {
  ul {
    background-color: $color;

    li {
      &.previous {
        > a {
          border-right-color: mix($color, white, 66%);
        }
      }
      > a, > span {
        border-left-color: mix($color, white, 66%);

        &:hover, &:focus {
          background-color: $hover;
        }
        &:active {
          background-color: $active;
        }
      }
      &.active {
        > a, > span {
          background-color: $active;
        }
      }

      &.pagination-dropdown.dropup {
        .dropdown-arrow {
          border-top-color: $color;
        }
      }
    }
  }
}

// Labels
// -------------------------
@mixin label-variant($color, $hover-color, $text-color: $inverse) {
  background-color: $color;
  color: $text-color;

  &[href] {
    color: $text-color;

    &:hover,
    &:focus {
      color: $text-color;
      background-color: $hover-color;
    }
  }
}

// Contextual backgrounds
// -------------------------
@mixin bg-variant($color) {
  background-color: $color;
  a:hover {
    background-color: darken($color, 10%);
  }
}

// Typography
// -------------------------
@mixin text-emphasis-variant($color) {
  color: $color;
  a:hover {
    color: mix($color, white, 80%);
  }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.
@mixin navbar-vertical-align($element-height, $navbar-height: $navbar-height-base) {
  padding-top: (($navbar-height - $element-height) / 2);
  padding-bottom: (($navbar-height - $element-height) / 2);
}

// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation($text-color: $brand-primary, $border-color: $gray-light, $background-color: $input-bg-error) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
    box-shadow: none;
    @include placeholder($text-color);

    &:focus {
      box-shadow: none;
      border-color: $border-color;
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

@mixin form-control-focus($color: $brand-primary) {
  .form-group.focus &,
  &:focus {
    border-color: $color;
    outline: 0;
    // @include box-shadow(none);
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

@mixin input-size($input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius: $border-radius-base) {
  height: $input-height;
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;

  select {
    height: $input-height;
    line-height: $input-height;
  }

  textarea,
  select[multiple] {
    height: auto;
  }
}

// Reset rounded corners for form controls
//
@mixin form-controls-corners-reset() {
  // .input-group .form-control:first-child,
  // .input-group-addon:first-child,
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .dropdown-toggle,
  .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  // .input-group .form-control:last-child,
  // .input-group-addon:last-child,
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .dropdown-toggle,
  .input-group-btn:first-child > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Spinner variants
// -------------------------
@mixin spinner-variant($up-btn-height, $down-btn-height, $line-height) {
  & + .ui-spinner-button {
    height: $up-btn-height;
    line-height: $line-height;
  }
  & + .ui-spinner-button + .ui-spinner-button {
    height: $down-btn-height;
    line-height: $line-height;
  }
}


// Panels

@mixin panel-variant($color) {

  $bg:     mix($color, $panel-bg, 20%);
  $border: mix($color, $panel-bg, 50%);

  background-color: $bg;
  border-color: $border;

  & > .panel-heading {
    color: $color;
    background-color: $bg;
    border-color: mix($color, $panel-bg, 40%);

    + .panel-collapse > .panel-body {
      border-top-color: $border;
    }
    .badge {
      color: $bg;
      background-color: $color;
    }
  }
  & > .panel-footer {
      background-color: mix(mix($color, $panel-bg, 50%), $panel-footer-bg, 50%);
    + .panel-collapse > .panel-body {
      border-bottom-color: transparent;
    }
    &.panel-footer-nobackground {
      background-color: transparent;
    }
  }
  .close {
    color: $color;
  }
}

// Alerts

@mixin alert-variant($background, $border, $text-color) {
  background-color: rgba($background, .95);
  border-color: rgba($border, 0);
  color: $text-color;

  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: mix($text-color, $background, 60%);
    text-decoration: underline;
  }
}

// Tooltip

@mixin tooltip-variant($background-color) {
  &+.tooltip {
    .tooltip-inner {
      background-color: $background-color;
    }
    &.top {
      .tooltip-arrow {
        border-top-color: $background-color;
      }
    }
    &.right {
      .tooltip-arrow {
        border-right-color: $background-color;
      }
    }
    &.bottom {
      .tooltip-arrow {
        border-bottom-color: $background-color;
      }
    }
    &.left {
      .tooltip-arrow {
        border-left-color: $background-color;
      }
    }
  }
}
