@charset "UTF-8";
/*
Layout
----------------------------------------------------------------
*/

html, body {
  height: 100%;
}

.wrapper-page {
	// overflow: scroll;
	min-height: 100%;
}

.row {
	margin-left:  ($grid-gutter-width / -2);
  margin-right: ($grid-gutter-width / -2);
}

.displaynone {
	display: none;
}

// Grid System カラム
%col-base {
    position: relative;
    min-height: 1px;
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    // [class^="col-"],
    // [class*=" col-"] {
    //     padding-right: 0;
    //     padding-left: 0;
    // }
    [class^="col-"].radio,
    [class*=" col-"].radio,
    [class^="col-"].checkbox,
    [class*=" col-"].checkbox {
        padding-right: ($grid-gutter-width / 2);
        padding-left: ($grid-gutter-width / 2) * 2;
    }
}
$screens: xs, sm, md, lg;
@each $screen in $screens {
    @for $i from 1 through 12 {
        .col-#{$screen}-#{$i} {
            @extend %col-base;
        }
    }
}

// カラムの横幅
$col-width: (
    '12' : 100%,
    '11' : 91.66666667%,
    '10' : 83.33333333%,
    '9'  : 75%,
    '8'  : 66.66666667%,
    '7'  : 58.33333333%,
    '6'  : 50%,
    '5'  : 41.66666667%,
    '4'  : 33.33333333%,
    '3'  : 25%,
    '2'  : 16.66666667%,
    '1'  : 8.33333333%
);

@each $key, $value in $col-width {
    .col-xs-#{$key} {
        width: $value;
        float: left;
    }
    .col-xs-pull-#{$key} {
        right: $value;
    }
    .col-xs-push-#{$key} {
        left: $value;
    }
    .col-xs-offset-#{$key} {
        margin-left: $value;
    }
}
.col-xs-pull-0 {
    right: auto;
}
.col-xs-push-0 {
    left: auto;
}
.col-xs-offset-0 {
    margin-left: 0;
}

// Small screen
@media (min-width: $screen-sm-min) {
    @each $key, $value in $col-width {
        .col-sm-#{$key} {
            width: $value;
            float: left;
        }
        .col-sm-pull-#{$key} {
            right: $value;
        }
        .col-sm-push-#{$key} {
            left: $value;
        }
        .col-sm-offset-#{$key} {
            margin-left: $value;
        }
    }

    .col-sm-pull-0 {
        right: auto;
    }
    .col-sm-push-0 {
        left: auto;
    }
    .col-sm-offset-0 {
        margin-left: 0;
    }
}
// Medium screen
@media (min-width: $screen-md-min) {
    @each $key, $value in $col-width {
        .col-md-#{$key} {
            width: $value;
            float: left;
        }
        .col-md-pull-#{$key} {
            right: $value;
        }
        .col-md-push-#{$key} {
            left: $value;
        }
        .col-md-offset-#{$key} {
            margin-left: $value;
        }
    }

    .col-md-pull-0 {
        right: auto;
    }
    .col-md-push-0 {
        left: auto;
    }
    .col-md-offset-0 {
        margin-left: 0;
    }
}
// Large screen
@media (min-width: $screen-lg-min) {
    @each $key, $value in $col-width {
        .col-lg-#{$key} {
            width: $value;
            float: left;
        }
        .col-lg-pull-#{$key} {
            right: $value;
        }
        .col-lg-push-#{$key} {
            left: $value;
        }
        .col-lg-offset-#{$key} {
            margin-left: $value;
        }
    }

    .col-lg-pull-0 {
        right: auto;
    }
    .col-lg-push-0 {
        left: auto;
    }
    .col-lg-offset-0 {
        margin-left: 0;
    }
}


//
$breakpoint-wide : 1366px; // wide PC monitor
$screen-adjustment : $screen-lg-min + $sidebar-width - $breakpoint-wide;


// Navbar
@media (min-width: $screen-lg-min + $sidebar-width - $screen-adjustment ) {
	.with-sidebar {
		&#header-site {
			.sidebar-toggle {
				display: none;
			}
			h1 {
				margin-left:0;
			}
		}
		&#header-site,
		&#header-content,
		&.container {
		  margin-left: $sidebar-width;
		}
		&#header-content{
			.sidebar-toggle {
				display: none;
			}
			.navbar-header{
				h2{
					margin-left: 0px;
				}
			}
		}
	}
	.with-sidebar-below {
		&#header-site,
		&#header-content, {
			margin-left: 0;
		}
	}
}
#header-content {
	&.with-navbar-fixed-top {
		margin-top: $navbar-height-base;
	}
	&.with-navbar-fixed-top-large {
		margin-top: $navbar-height-large;
	}
}
.wrapper {
	&.with-navbar-fixed-top {
		padding-top: $navbar-height-base;
	}
	&.with-navbar-fixed-top-lg {
		padding-top: $navbar-height-large;
	}
	&.with-navbar-fixed-top-bslg {
		padding-top: $navbar-height-base + $navbar-height-large;
	}
}
@media (max-width: $screen-lg-min + $sidebar-width - $screen-adjustment - 1px) {
	#header-site {
		&.with-sidebar {
			.navbar-title {
				// margin-left: ($m*2) + $s;
        padding-left: ($m*2) + $s + $l;
			}
		}
	}
}



// Sidebar
.sidebar {
	// display: none;
	left: 0 - $sidebar-width-xs;
}
@media (min-width: $screen-sm-min) and (max-width: $screen-lg-min + $sidebar-width - $screen-adjustment - 1px) {
	.sidebar {
		// display: none;
		left: 0 - $sidebar-width;
	}
}
@media (min-width: $screen-lg-min + $sidebar-width - $screen-adjustment) {
	.sidebar {
		left: 0;
		margin-top: 0;
	}
}
.sidebar {
&.sidebar-fixed-top {
		top: 0;
	}
	&.sidebar-fixed-top-bs {
		top: $navbar-height-base;
		padding-bottom: $navbar-height-base + $l;
	}
	&.sidebar-fixed-top-lg {
		top: $navbar-height-large;
		padding-bottom: $navbar-height-large + $l;
	}
	&.sidebar-fixed-top-bslg {
		top: $navbar-height-base + $navbar-height-large;
		padding-bottom: $navbar-height-base + $navbar-height-large + $l;
	}
}



// Container
.container {
    padding: 0;
    margin: 0;
    @media (min-width: $screen-sm-min) {
        width: 750px;
    }
    @media (min-width: $screen-md-min) {
        width: 970px;
    }
    @media (min-width: $screen-lg-min) {
        // width: 1159px;
        width: 1408px;
    }
}

.container-fluid {
  position: relative;
  padding: 0;
  margin: 0;
}

@media (min-width: $screen-lg-min + $sidebar-width - $screen-adjustment) {
    .with-sidebar.container-fluid {
       margin-left: $sidebar-width;
    }
}



// Footblock
@media (min-width: $screen-lg-min + $sidebar-width - $screen-adjustment) {
	.with-sidebar {
		&.footbar {
		  margin-left: $sidebar-width;
		  padding-right: $sidebar-width + $l;
		}
	}
}
@media (min-width: $grid-float-breakpoint) {
	.wrapper {
		&.with-navbar-fixed-bottom {
      & > .container,
      & > .container-fluid {
        padding-bottom: $m * 4;
      }
		}
	}
}



// Section
.section {
  border-top: 1px solid $gray-subtle;
  padding: $m * 2;
  // padding-top: $m * 3;
  padding-bottom: $m * 2;
  @media (max-width: $grid-float-breakpoint) {
    padding: $m*2 $l;
  }
  // &:first-child {
  //   border-top: none;
  // }
  &.noborder {
    border-top: none!important;
  }
  &.section-filter {
    background: mix($gray-subtler, white, 90%);
    border-color: white;
    .form-control,
    .btn-input,
    .input-group-btn .btn {
      &[disabled],
      &.disabled,
      &[readonly],
      &.readonly,
      fieldset[disabled] & {
        background-color: mix($input-bg-disabled, white, 2%);
        border-color: mix($input-bg-disabled, white, 2%);
      }
    }
    .combobox {
    	.form-control {
            &[disabled],
            &[readonly],
            fieldset[disabled] & {
                background: transparent;
            }
        }
    }
    .twitter-typeahead {
    	.tt-query {
		    background-color: mix($input-bg-disabled, white, 2%) !important;
		  }
    }
    hr {
    	border-top-color: white;
    }
    a.acordion-btn {
    	color: $gray-light;
    	cursor: pointer;
    	&:hover {
    		color: mix($gray-light, white, 80%);;
    	}
    	.icon {
    		top: 1px;
		    position: relative;
    	}
    }
    .js-accordion {
    	margin-top: $l;
    }
  }

  .section-title {
    @extend h4;
    // color: $gray;
    // font-size: floor($component-font-size-base*1.286); //18px
    // // margin-bottom: 18px;
    // // margin-top: $m;
    // line-height: $headings-line-height;
    // margin-top: ($line-height-computed / 1.125);
    // margin-bottom: ($line-height-computed / 2);
  }
  .section-text {
    color: $gray;
    font-size: $component-font-size-base;
    margin-bottom: ($m*2)+$x;
  }
}
.wrapper {
  > .container,
  > .container-fluid {
    > .section {
      margin-top: -1px;
    }
  }
}

// hr
hr {
  border-top-color: $gray-subtle;
  border-style: dotted;
  margin: $m 0 $m*2;
}



// Modal
.modalIsOpen {
	#wrapper-page {
		-webkit-filter: blur(5px);
	}
	// .modal {
	// 	/* Regular size and visible */
	// 	@include scale(1); /* prefix me */
	// 	@include opacity(1);
	// 	/* Clickable */
	// 	pointer-events: auto;
	// }
}

// #wrapper-page,
// .modal {
// 	transition: all 0.2s ease; /* prefix me */
// }

.modal {
	display: none;
	position: fixed;
	z-index: 3010;
	width: 100%;
	height: 100%;
	&.row {
		margin-left: 0;
		margin-right: 0;
	}
}
.modal-background {
	position: fixed;
	z-index:3999;
	height:100%;
	width:100%;
	background: $gray;
  opacity: .3;
}
@media (min-width: $screen-lg-min + $sidebar-width - $screen-adjustment ) {
	.with-sidebar {
		&.modal {
		  padding-left: $sidebar-width;
		}
	}
}


// Sheet
.bg-gray {
	background: $gray-subtler;
}
.sheet {
	background: white;
	margin: $l;
	padding: $l;
  border: 1px solid $panel-default-border;
  border-radius: $border-radius-small;
  @extend .dropshadow--lv1;
	&.panel {
		padding: 0;
		border: none;
	}
}
body {
  &.interruption,
  &.bg-gray {
    .sheet {
      border-color: transparent;
    }
  }
}


// カラム近接　旧
.col-inline--pr {
	@media (min-width: $grid-float-breakpoint) {
		// for pc
		padding-right: 6px !important;
	}
}
.col-inline--pl {
	@media (min-width: $grid-float-breakpoint) {
		// for pc
		padding-left: 6px !important;
	}
}

// カラム近接
[class^="col-"],
[class*=" col-"] {
	// すべての解像度で適用
	&.col-all-closer {
		padding-left: 6px;
		padding-right: 6px;
	}
	// for tablet (幅768px以上) に適用
	&.col-sm-closer {
		@media (min-width: $screen-sm-min) {
			padding-left: 6px;
			padding-right: 6px;
		}
	}
	// for desktop (幅992px以上) に適用
	&.col-md-closer {
		@media (min-width: $screen-md-min) {
			padding-left: 6px;
			padding-right: 6px;
		}
	}
	// for wide desktop (幅1200px以上) に適用
	&.col-lg-closer {
		@media (min-width: $screen-lg-min) {
			padding-left: 6px;
			padding-right: 6px;
		}
	}
	// 端に来る場合にリセット
	// すべての解像度
	&.col-all-closer--leftend {
		padding-left: $m;
	}
	&.col-all-closer--rightend {
		padding-right: $m;
	}
	// for wide desktop (幅1200px以上) に適用
	@media (min-width: $screen-lg-min) {
		&.col-lg-closer--leftend {
			padding-left: $m;
		}
		&.col-lg-closer--rightend {
			padding-right: $m;
		}
	}
	// for desktop (幅992px以上 - 幅1200px未満) に適用
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		&.col-md-closer--leftend {
			padding-left: $m;
		}
		&.col-md-closer--rightend {
			padding-right: $m;
		}
	}
	// for tablet (幅768px以上 - 幅992px未満) に適用
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		&.col-sm-closer--leftend {
			padding-left: $m;
		}
		&.col-sm-closer--rightend {
			padding-right: $m;
		}
	}
	// for phone (幅768px未満) に適用
	@media (max-width: $screen-xs-max) {
		&.col-xs-closer--leftend {
			padding-left: $m;
		}
		&.col-xs-closer--rightend {
			padding-right: $m;
		}
	}
}

/*
    Helper Class
 */
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
  &.btn {
    @extend .center-block;
  }
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.clearfix  {
    @include micro-clearfix();
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable {
  &:active, &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.hide {
  display: none !important;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}




@media (min-width: 1600px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg, td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1600px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1600px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1600px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}