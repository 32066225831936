@charset "UTF-8";
/*
Tenkey
----------------------------------------------------------------
*/



.control-tenkey {
  border-radius: $border-radius-base;

  & .btn {
    @include button-variant($btn-default-color, $gray-lighter, $btn-hover-bg, $btn-active-bg);
    border-radius: 0;
    font-size: floor($font-size-base * 1);
    padding: 11px 16px;
    &.brtl {
      border-top-left-radius: $border-radius-base;
    }
    &.brtr {
      border-top-right-radius: $border-radius-base;
    }
    &.brbl {
      border-bottom-left-radius: $border-radius-base;
    }
    &.brbr {
      border-bottom-right-radius: $border-radius-base;
    }
  }
  > [class^="col"] {
    padding-right:1px;
    padding-bottom:1px;
    padding-left: 0;
    &.tenky--right {
      padding-right: 0;
    }
    &.tenky--bottom {
      padding-bottom: 0;
    }
  }
}

.has-error {
  .control-tenkey {
    [class^="icon-abui-"],
    [class*=" icon-abui-"]  {
      color: white;
    }
  }
}
// overlay-tenkey
.overlay-tenkey {
  position: absolute;
  z-index: 1010;
  width: 160px;
  background-color: white;
  opacity: 0;
  transition: opacity 300ms;
  @extend .dropshadow--lv2;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    left: 80px;
    margin-left: -7px;
  }
  &.tenkey--top {
    bottom: 78px;
    &::before {
      border-bottom-color: $gray-lighter;
      border-width: 8px 6px 0;
      bottom: -8px;
    }
  }
  &.tenkey--bottom {
    top: 78px;
    left: 50%;
    margin-left: -80px;
    &::before {
      border-bottom-color: $brand-primary;
      border-width: 0 6px 8px;
      top: -8px;
    }
  }
  .btn-stepper {
    @include button-variant($btn-default-color, $brand-primary, $btn-primary-hover-bg, $btn-primary-active-bg);
  }
  &.in {
    opacity: 1;
  }
}

.input-group {
  .overlay-tenkey {
    &.tenkey--top {
      bottom: 48px;
    }
    &.tenkey--bottom {
      top: 48px;
    }
  }
}
.cell-control {
  .overlay-tenkey {
    &.tenkey--top {
      bottom: 44px;
    }
    &.tenkey--bottom {
      top: 44px;
    }
  }
}
