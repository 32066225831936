@charset "UTF-8";
/*
Checkbox & Radio
----------------------------------------------------------------
*/


input {
  &[type="radio"],
  &[type="checkbox"] {
    margin: $x 0 0;
    // margin-top: 1px \9;
    line-height: normal;
    &:focus {
      // outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }
  &[type="radio"][disabled],
  &[type="radio"].disabled,
  &[type="checkbox"][disabled],
  &[type="checkbox"].disabled {
    cursor: not-allowed;
  }
}

.checkbox,
.radio {
  font-size: ceil($component-font-size-base * 0.933); // ~14px
  line-height: $input-line-height-base;
  color: $gray;
  // min-height: 22px;
  padding-left: $l * 2;
  margin-top: $m;
  margin-bottom: $m;
  position: relative;
  transition: color .25s linear;

  input {
    outline: none;
    display: none;
  }

  // Replace icons
  // --------------------------------------------------
  .icons {
    font-size: round($icon-normal * 1.25);  // 20px
    text-align: center;
    line-height: $component-font-size-base * 1.5; // 21px
    color: $gray-lighter;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    transition: color .25s linear;

    .first-icon,
    .second-icon {
      background-color: transparent;
      margin: 0;
      display: inline-table;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
    }
    .second-icon {
      opacity: 0;
    }
  }

  // Alternate States
  // --------------------------------------------------

  // Hover State
  &:hover {
    transition: color .25s linear;
    @media( min-width: $screen-md-min) {
      .first-icon {
        opacity: 0;
      }
      .second-icon {
        opacity: 1;
      }
    }
  }

  // Checked State
  &.checked {
    color: $brand-primary;

    .first-icon {
      opacity: 0;
    }
    .second-icon {
      color: $brand-primary;
      opacity: 1;
      transition: color .25s linear;
    }
  }

  // Disabled state
  &.disabled {
    cursor: default;
    color: mix($gray-lighter, white, 38%);

    .icons {
      cursor: default;
      color: mix($gray-lighter, white, 38%);
    }
    .first-icon {
      opacity: 1;
    }
    .second-icon {
      opacity: 0;
    }
    &.checked {
      .icons {
        color: mix($gray-light, white, 38%);
      }
      .first-icon {
        opacity: 0;
      }
      .second-icon {
        opacity: 1;
        color: mix($gray-light, white, 38%);
      }
    }
  }

  // Alternate Color
  // --------------------------------------------------

  // Primary
  &.primary {
    .icons {
      color: $brand-primary;
    }
    // Checked State
    &.checked {
      color: $brand-primary;

      .icons {
        color: $brand-primary;
      }
    }
    // Disabled state
    &.disabled {
      cursor: default;
      color: $gray-light;

      .icons {
        color: $gray-light;
      }
      &.checked {
        .icons {
          color: $gray-light;
        }
      }
    }
  }
}

// Inline Checkbox and Radio
.checkbox-inline,
.radio-inline {
  font-weight: $font-weight-base;
  vertical-align: middle;
  // margin-right: $m;
  margin-right: $m + 10px;
  display: inline-block;
  cursor: pointer;
  // & + & {
    // margin-left: 10px;
  // }
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
}


.radio + .radio,
.checkbox + .checkbox {
  margin-top: 10px;
}


// No Label
.checkbox,
.radio {
  &.no-label {
    padding-left: 0;
    width: 20px;
    margin: 0;
  }
}


// Small size
.checkbox-sm,
.radio-sm {
  // .checkbox-inline,
  // .radio-inline {
    margin-top: $s;
    margin-bottom: $s - 2px;
  // }
}
