@charset "UTF-8";
/*
ContextMenu
----------------------------------------------------------------
*/



.iw-contextMenu {
  border-radius: $border-radius-base;
  box-shadow: 0 1px 4px #abb7bb;
  border: none;
  display: none;
  z-index: 1030;
  & .AddMenu {
    vertical-align: middle;
    background: url(../images/icn_addshortcut.png) left top no-repeat;
    background-size: 16px 16px;
    border: none;
    height: 36px;
    width: 36px;
    position: absolute;
    right: -4px;
    display: inline-block;
    opacity: .3;
    &:hover {
      opacity: 1;
    }
  }
}

.iw-cm-menu {
  font-size: ceil($font-size-base * 0.86);
  color: #4e5f68;
  background-color: #f4f6f7;
  margin: 0px;
  padding: 0px;
  & li {
    line-height: $line-height-base * 0.74286;
    min-width: 256px;
    list-style: none;
    padding: 7px $m+1px;
    position: relative;
    cursor: pointer;
    &:first-child > a {
      border-radius: $border-radius-base $border-radius-base 0 0;
      padding-top: $s;
    }
    &:last-child > a {
      border-radius: 0 0 $border-radius-base $border-radius-base;
      padding-bottom: $s + 2;
    }
  }
}


.iw-mOverlay {
  background: $inverse;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: .5;
}

.iw-contextMenu li.iw-mDisable {
  opacity: .3;
}

.iw-mSelected {
  background: #d5dadd;
}

.iw-cm-arrow-right {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 8px solid #9fa9ae;
  width: 0;
  height: 0;
  margin-top: -6px;
  position: absolute;
  right: 8px;
  top: 50%;
}
