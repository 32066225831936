@charset "UTF-8";
/*
Progress bar
----------------------------------------------------------------
*/



// Outer container
.progress {
  background: $gray-subtle;
  border-radius: 4px 4px;
  box-shadow: none;
  height: $progress-height;
  margin-bottom: 20px;
  overflow: hidden;
}

// Bar of progress
.progress-bar {
  font-size: $progress-font-size;
  color: $inverse;
  line-height: $progress-height;
  text-align: center;
  background: $brand-primary;
  box-shadow: none;
  height: 100%;
  float: left;
  transition: width .6s ease;
}

// Variations
// -------------------------
.progress-bar {
  &.progress-bar-success {
    background-color: $brand-success;
  }
  &.progress-bar-warning {
    background-color: $brand-warning;
  }
  &.progress-bar-danger {
    background-color: $brand-danger;
  }
  &.progress-bar-info {
    background-color: $brand-info;
  }
}
