@charset "UTF-8";
/*
Tab
----------------------------------------------------------------
*/



.nav-tabs {
  border-bottom: 0px;
  margin-bottom: $l;
  > li {
    margin-bottom: 0;
    > a {
      font-size: ceil($component-font-size-base*0.928);
      color: $gray-light;
      background: $nab-tabs-bg;
      border-top-right-radius: $border-radius-base;
      border-top-left-radius: $border-radius-base;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border:0;
      border-bottom: 2px solid $nab-tabs-bg;
      padding: 13px $m*2 11px;
      margin-right: 4px;
      &:hover,
      &:focus {
        color: $brand-primary;
        border: 0;
        background: mix($nab-tabs-bg, white, 70%);
        border-bottom: 2px solid mix($nab-tabs-bg, white, 70%);
      }
    }
    &.active {
      > a {
        border: 0;
        cursor: default;
        background-color: $nab-tabs-active-bg;
        color: $brand-primary;
        border-bottom: 2px solid $nab-tabs-active-bg;
        &:hover,
        &:focus {
          border: 0;
          color: $brand-primary;
          background: $nab-tabs-active-bg;
          border-bottom: 2px solid $nab-tabs-active-bg;
        }
      }
      &::after {
        content: "";
        display: block;
        border-style: solid;
        border-width: 0 6px 8px 6px;
        border-color: transparent transparent white transparent;
        height: 0;
        margin-top: -8px;
        margin-left: -6px;
        opacity: 1;
        width: 0;
        z-index: 10;
        position: absolute;
        left: 50%;
      }
    }
  }
  &.nav-justified {
    > li {
      > a {
        margin-right: 4px;
        border: 0;
        border-bottom: 2px solid $nab-tabs-bg;
        &:hover,
        &:focus {
          border: 0;
          border-bottom: 2px solid mix($nab-tabs-bg, white, 70%);
        }
      }
      &.active {
        a {
          border: 0;
          border-bottom: 2px solid $nab-tabs-active-bg;
          &:hover,
          &:focus {
            background: $nab-tabs-active-bg;
            color: $brand-primary;
            border: 0;
            border-bottom: 2px solid $nab-tabs-active-bg;
          }
        }
        @media (max-width: $grid-float-breakpoint) {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
