@charset "UTF-8";
// Combobox
// ----------------------------------------------------------------


.combobox {
	input {
	  font-size: $input-font-size-small;	// 14px
	  border: none;
	  height: $input-height-base - 2px;
	  width: 83%;
	  padding: $s $m;
	  margin: 1px 0 0 1px;
	  position: absolute;
	  z-index: 500;
	  &[disabled] {
	  	opacity: 0;
	  	background: transparent;
	  	color: rgba($gray-dark, .7);
	  }
	  &.input-xs {
	    height: $input-height-xs - 2;
	  }
	  &.input-sm {
	    height: $input-height-small - 2;
	    padding-top: 5px;
	    padding-bottom: 5px;
	  }
	  &.input-lg {
	    height: $input-height-large - 2;
	  }
	  &.input-hg {
	    height: $input-height-huge - 2;
	  }
	}
	// .btn-input {
	// 	color: transparent;
	// }
}
