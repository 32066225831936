@charset "UTF-8";
/*
Sortable
----------------------------------------------------------------
*/

.ui-sortable {
  > tr > td:last-child,
  > li {
    position: relative;
    &::after {
      content: "\e900";
      font-family: 'ABUI-icons';
      speak: none;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      right: 6px;
      padding-top: 1px;
      font-size: ceil($font-size-base * 0.875);
      text-align: center;
      color: $gray-lighter;
      width: 14px;
      overflow: hidden;
    }
  }
}

.ui-sortable-handle {
  cursor: grab;
}
$sortable-placeholder-bg: mix($state-info-bg, white, 50%);
.table--sortable {
  &.table-bordered {
    tr.ui-sortable-placeholder {
      td {
        border-right-color: transparent;
        border-left-color: transparent;
      }
    }
  }
  &.table-striped {
    tr.ui-sortable-placeholder {
      td {
        background-color: $sortable-placeholder-bg;
      }
    }
  }
  tr.ui-sortable-placeholder {
    td {
      border-right-color: transparent;
      border-left-color: transparent;
      &:last-child::after {
        opacity: 0;
      }
    }
  }
}
.list--sortable {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    padding: $s;
    background-color: white;
    font-size: ceil($font-size-base * 0.785);
    border-top: 1px solid $gray-subtle;
    border-bottom: 1px solid $gray-subtle;
    margin-top: -1px;

    &.ui-sortable-placeholder {
      background-color: $sortable-placeholder-bg;
      height: 40px;
      &::after {
        opacity: 0;
      }
    }
  }
}
.ui-sortable-placeholder {
  background-color: $sortable-placeholder-bg;
}
.ui-sortable-helper {
  opacity: .9;
  cursor: grabbing;
}