@charset "UTF-8";
/*! nouislider - 9.2.0 - 2017-01-11 10:35:35 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-state-tap {
  .noUi-connect, .noUi-origin {
    -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
    transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  }
}

.noUi-base, .noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.noUi-horizontal {
  height: 18px;
}

.noUi-target {
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical {
  .noUi-draggable {
    cursor: ns-resize;
  }
  .noUi-handle {
    &:before {
      width: 14px;
      height: 1px;
      left: 6px;
      top: 14px;
    }
    &:after {
      width: 14px;
      height: 1px;
      left: 6px;
      top: 14px;
      top: 17px;
    }
  }
}

[disabled] {
  .noUi-connect {
    background: #B8B8B8;
  }
  &.noUi-target, &.noUi-handle, .noUi-handle {
    cursor: not-allowed;
  }
}

.noUi-pips {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  position: absolute;
  color: #999;
}

.noUi-value {
  position: absolute;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub, .noUi-marker-large {
  background: #AAA;
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0);
}

.noUi-marker-horizontal {
  &.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
  }
  &.noUi-marker-sub {
    height: 10px;
  }
  &.noUi-marker-large {
    height: 15px;
  }
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  padding-left: 25px;
}

.noUi-marker-vertical {
  &.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
  }
  &.noUi-marker-sub {
    width: 10px;
  }
  &.noUi-marker-large {
    width: 15px;
  }
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}