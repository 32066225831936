@charset "UTF-8";
/*
Caret
----------------------------------------------------------------
*/



.caret {
  border-width: 7px 5px;
  border-bottom-color: $gray-subtle;
  border-top-color: $gray-subtle;
  border-style: solid;
  border-bottom-style: none;
  transition: .25s;
  @include scale(1.001,1);
}

.dropup .caret,
.dropup .btn-lg .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-bottom-width: $s;
  border-bottom-color: $gray-subtle;
}

.btn-lg .caret {
  border-width: 7px 5px;
  // border-top-width: $s;
  // border-right-width: $s - 2;
  // border-left-width: $s - 2;
}

// Expander
.expander {
  @include rotate(-90deg);
  &.open {
    @include rotate(0deg);
  }
}


// Error display
.has-error .caret,
.error .caret {
  border-top-color: $brand-danger;
  border-bottom-color: $brand-danger;
}

