@charset "UTF-8";
/*
Images
----------------------------------------------------------------
*/


%base-img {
  max-width: 100%;
  height: auto;
}

.img-responsive {
  @extend %base-img;
  display: block;
}

.img-thumbnail {
  @extend %base-img;
  display: inline-block;
  padding: $x;
  line-height: $line-height-base * 0.81632653;  // 1.42857143
  background-color: $inverse;
  border: 1px solid #ddd;
  border-radius: $x;
  transition: all .2s ease-in-out;
}

.img-circle {
  border-radius: 50%;
}

// Rounded corners
.img-rounded {
  border-radius: $border-radius-base;
}

// Image captions
.img-caption {
  font-size: ceil($font-size-base * 0.8333);
  color: $thumbnail-caption-color;
  line-height: $line-height-base * 0.68571429;  // 1.2
  padding-top: $thumbnail-caption-padding;
  margin-bottom: $l;
}

.thumbnail-wrap {
  font-size: 0;
}

.thumbnail {
  font-size: $font-size-base * 0.875;   // 14px
  line-height: $line-height-base;
  background-color: $thumbnail-bg;
  // border: 2px solid $thumbnail-border;
  border: none;
  border-radius: $thumbnail-border-radius;
  padding: $thumbnail-padding;
  margin-bottom: 0;
  margin-right: $s;
  transition: all .25s ease-in-out;
  display: inline-block;
  position: relative;

  > img,
  a > img {
    margin-left: auto;
    margin-right: auto;
    border-radius: $thumbnail-border-radius;
    @include img-responsive(inline-block);
  }
  a {
    display: block;
    position: relative;
  }

  a:hover,
  a:focus,
  a.active {
    &::after {
      display: block;
      position: absolute;
      content: '';
      background-color: $link-color;
      height: 100%;
      width: 100%;
      top: 0;
      left:0;
      opacity: .7;
    }
  }

  & .caption {
    padding: 9px;
    color: #333;
  }
}

a.thumbnail {
  &:hover, &:focus, &.active {
    border-color: #337ab7;
  }
}