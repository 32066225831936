@charset "UTF-8";
/*
Tags Input
----------------------------------------------------------------
*/



.tagsinput {
  background: transparent;
  border-radius: $border-radius-base;
  height: 100px;
  margin-bottom: 18px;
  overflow-y: auto;
  text-align: left;

  & .tag {
    font-size: ceil($component-font-size-base * 0.933); // ~14px
    vertical-align: middle;
    line-height: round($line-height-computed * 0.5357); // 15px
    color: $inverse;
    border-radius: $border-radius-base;
    background-color: $brand-primary;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 7px 11px 6px 17px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .25s linear;

    &:hover {
      background-color: $btn-primary-hover-bg;
      color: $inverse;
      padding-left: $m;
      padding-right: $m + $s;

      .tagsinput-remove-link {
        color: $inverse;
        opacity: 1;
      }
    }
  }

  input {
    font-family: $font-family-base;
    font-size: ceil($component-font-size-base * 0.933); // ~14px
    vertical-align: top;
    color: $brand-primary;
    outline: none !important;
    background: transparent;
    border: none;
    width: 3em;
    padding: 0 0 0 5px;
    margin: 2px 5px 0 0;
  }
}

.tagsinput-remove-link {
  font-size: round($component-font-size-base * 0.857);  // 12px
  text-align: right;
  text-decoration: none;
  color: $inverse;
  width: 100%;
  padding: 7px 7px 5px 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;

  &::before {
    content: "\e014";
    font-family: "ABUI-Icons";
    font-weight: 700;
    color: $inverse;
  }
}

.tagsinput-add-container {
  vertical-align: middle;
  display: inline-block;
}

.tagsinput-add {
  font-size: ceil($component-font-size-base * 0.933); // ~14px
  line-height: round($line-height-base * 0.5714);   // 1
  vertical-align: top;
  color: $gray-light;
  background-color: transparent;
  border-radius: $border-radius-small;
  padding: 7px 9px 6px;
  margin-bottom: 5px;
  display: inline-block;
  cursor: pointer;
  transition: .25s linear;

  &:hover {
    background-color: $btn-flat-default-hover-bg;
  }
  &:active {
    background-color: $btn-flat-default-active-bg;
  }
  &::before {
    content: "\2b";
    font-family: "ABUI-icons";
    font-weight: 700;
  }
}

.tags_clear {
  clear: both;
  width: 100%;
  height: 0px;
}
.not_valid {
  background: #fbd8db !important;
  color: #90111a !important;
  margin-left: 5px !important;
}
