@charset "UTF-8";
/*
Interruption
----------------------------------------------------------------
*/



body.interruption {
  background-color: $gray-subtler;
  width: 100%;
  min-width: 0;
  padding-top: 32px;
}