@charset "UTF-8";
/*
Spaces
----------------------------------------------------------------
*/



.last-col {
  overflow: hidden;
}

// Padding

.ptn, .pvn, .pan {
  padding-top: 0 !important;
}

.ptx, .pvx, .pax {
  padding-top: $x !important;
}

.pts, .pvs, .pas {
  padding-top: $s !important;
}

.ptm, .pvm, .pam {
  padding-top: $m !important;
}

.ptl, .pvl, .pal {
  padding-top: $l !important;
}

.prn, .phn, .pan {
  padding-right: 0 !important;
}

.prx, .phx, .pax {
  padding-right: $x !important;
}

.prs, .phs, .pas {
  padding-right: $s !important;
}

.prm, .phm, .pam {
  padding-right: $m !important;
}

.prl, .phl, .pal {
  padding-right: $l !important;
}

.pbn, .pvn, .pan {
  padding-bottom: 0 !important;
}

.pbx, .pvx, .pax {
  padding-bottom: $x !important;
}

.pbs, .pvs, .pas {
  padding-bottom: $s !important;
}

.pbm, .pvm, .pam {
  padding-bottom: $m !important;
}

.pbl, .pvl, .pal {
  padding-bottom: $l !important;
}

.pln, .phn, .pan {
  padding-left: 0 !important;
}

.plx, .phx, .pax {
  padding-left: $x !important;
}

.pls, .phs, .pas {
  padding-left: $s !important;
}

.plm, .phm, .pam {
  padding-left: $m !important;
}

.pll, .phl, .pal {
  padding-left: $l !important;
}


// Margin

.mtn, .mvn, .man {
  margin-top: 0px !important;
}

.mtx, .mvx, .max {
  margin-top: $x !important;
}

.mts, .mvs, .mas {
  margin-top: $s !important;
}

.mtm, .mvm, .mam {
  margin-top: $m !important;
}

.mtl, .mvl, .mal {
  margin-top: $l !important;
}

.mrn, .mhn, .man {
  margin-right: 0px !important;
}

.mrx, .mhx, .max {
  margin-right: $x !important;
}

.mrs, .mhs, .mas {
  margin-right: $s !important;
}

.mrm, .mhm, .mam {
  margin-right: $m !important;
}

.mrl, .mhl, .mal {
  margin-right: $l !important;
}

.mbn, .mvn, .man {
  margin-bottom: 0px !important;
}

.mbx, .mvx, .max {
  margin-bottom: $x !important;
}

.mbs, .mvs, .mas {
  margin-bottom: $s !important;
}

.mbm, .mvm, .mam {
  margin-bottom: $m !important;
}

.mbl, .mvl, .mal {
  margin-bottom: $l !important;
}

.mln, .mhn, .man {
  margin-left: 0px !important;
}

.mlx, .mhx, .max {
  margin-left: $x !important;
}

.mls, .mhs, .mas {
  margin-left: $s !important;
}

.mlm, .mhm, .mam {
  margin-left: $m !important;
}

.mll, .mhl, .mal {
  margin-left: $l !important;
}


//
.gfb-mtx {
  @media (max-width: $grid-float-breakpoint) {
    margin-top: $x !important;
  }
}
.gfb-mts {
  @media (max-width: $grid-float-breakpoint) {
    margin-top: $s !important;
  }
}
.gfb-mtm {
  @media (max-width: $grid-float-breakpoint) {
    margin-top: $m !important;
  }
}
.gfb-mtl {
  @media (max-width: $grid-float-breakpoint) {
    margin-top: $l !important;
  }
}
.gfb-mrx {
  @media (max-width: $grid-float-breakpoint) {
    margin-right: $x !important;
  }
}
.gfb-mrs {
  @media (max-width: $grid-float-breakpoint) {
    margin-right: $s !important;
  }
}
.gfb-mrm {
  @media (max-width: $grid-float-breakpoint) {
    margin-right: $m !important;
  }
}
.gfb-mrl {
  @media (max-width: $grid-float-breakpoint) {
    margin-right: $l !important;
  }
}
.gfb-mbx {
  @media (max-width: $grid-float-breakpoint) {
    margin-bottom: $x !important;
  }
}
.gfb-mbs {
  @media (max-width: $grid-float-breakpoint) {
    margin-bottom: $s !important;
  }
}
.gfb-mbm {
  @media (max-width: $grid-float-breakpoint) {
    margin-bottom: $m !important;
  }
}
.gfb-mbl {
  @media (max-width: $grid-float-breakpoint) {
    margin-bottom: $l !important;
  }
}
.gfb-mlx {
  @media (max-width: $grid-float-breakpoint) {
    margin-left: $x !important;
  }
}
.gfb-mls {
  @media (max-width: $grid-float-breakpoint) {
    margin-left: $s !important;
  }
}
.gfb-mlm
 {
  @media (max-width: $grid-float-breakpoint) {
    margin-left: $m !important;
  }
}
.gfb-mll {
  @media (max-width: $grid-float-breakpoint) {
    margin-left: $l !important;
  }
}
