@charset "UTF-8";
/*
Input Icon
----------------------------------------------------------------
*/



.form-control {
  & + .input-icon {
    font-size: $input-icon-font-size;
    line-height: round($input-icon-font-size * 2.2222);
    vertical-align: middle;
    color: $gray-subtle;
    background-color: $inverse;
    border-radius: $input-border-radius;
    padding: 0 11px 0 0;
    position: absolute;
    bottom: 2px;
    // right: 2px;
    // top: 32px;
    right: 1px;
    &.input-icon-nolabel {
      top: 2px;
    }
  }
}

// Icons sizing
// ---------------------------

// Huge
.input-hg + .input-icon {
  line-height: round($input-font-size-huge * 2.45);   // 49px
  padding: 0 $l 0 0;
}

//Large
.input-lg + .input-icon {
  line-height: round($input-font-size-large * 2.56);   // 41px
  padding: 0 ($l - 1) 0 0;
}

// Small
.input-sm + .input-icon,
.input-sm + .input-icon-typeahead {
  font-size: $font-size-base;
  line-height: round($input-font-size-small * 2.2857);   // 32px
  padding: 0 ($s + 1) 0 0;
}


// Icons states
// ---------------------------

.has-success {
  .input-icon {
    color: $brand-success;
  }
}
.has-warning {
  .input-icon {
    color: $brand-warning;
  }
}
.has-error {
  .input-icon {
    color: $brand-danger;
    background-color: $input-bg-error;
  }
}
.form-control[disabled] + .input-icon,
.form-control[readonly] + .input-icon,
fieldset[disabled] .form-control + .input-icon,
.form-control.disabled + .input-icon,
.form-control[disabled] + .input-group-btn .btn .input-icon {
  // color: mix($gray, white, 40%);
  background-color: transparent;
  opacity: .7;
}
