@charset "UTF-8";
/*
Sidebar
----------------------------------------------------------------
*/



.sidebar {
  background-color: $sidebar-bg;
  width: $sidebar-width;
  height: 100%;
  padding-bottom: $sidebar-padding-l;
  position: fixed;
  z-index: 1040;
  overflow: auto;

  @media (max-width: $screen-xs-max) {
    width: $sidebar-width-xs;
  }

  .category {
    font-size: floor($component-font-size-base*0.929); //13px
    color: $gray-lighter;
    padding-left: $sidebar-padding;
    padding-top: $sidebar-padding;
    margin-bottom: $s;
  }
  .divider {
    border-top: 1px solid mix($gray-dark, $sidebar-bg, 35%);
  }
  ul {
    font-size: $component-font-size-base;
    padding: 0;
    list-style: none;
    li {
      color: $gray-subtler;
      a,
      span {
        line-height: $line-height-base * 0.74286; // 1.3
        padding: ($sidebar-padding - 3) $sidebar-padding;
        display: block;
      }
      a {
        transition: .25s;
        cursor: pointer;

        &:link,
        &:visited {
          color: $gray-subtler;
        }
        &:hover,
        &:active,
        &:focus {
          color: $inverse;
          background-color: rgba($gray-subtler,0.2);
        }
      }
      span {
        &.expander {
          width: 44px;
          height: 44px;
          padding: ($sidebar-padding + 2) $sidebar-padding;
          position: absolute;
          right: 0;
          cursor: pointer;
          z-index: 1;
          &:hover {
            opacity: .7;
          }
          &+ span {
            padding-right: round($sidebar-padding * 2.75); // 44px
            a {
              padding: 0;
            }
          }
        }
        &.caret {
          @include caret(mix($gray-subtle, $sidebar-bg, 60%));
          display: block;
          padding: 0;
          margin-left:0;
        }
      }
      ul {
        position: relative;
        &::before {
          display: block;
          position: absolute;
          content: "";
          top: -7px;
          right: 18px;
          width: 0;
          height: 0;
          border-bottom: 7px solid rgba(white, 0.1);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
        & li {
          background: rgba(255, 255, 255, 0.1);
          & li {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
      &.bg-dark {
        background-color: mix($gray-dark, $sidebar-bg, 50%);
      }
      &.bg-darker {
        background-color: mix($gray-dark, $sidebar-bg, 75%);
      }
      &.active {
        background-color: $brand-primary;
        color: $inverse;
        ul {
          background-color: $sidebar-bg;
          &::before {
            border-bottom: 7px solid mix($sidebar-bg, white, 90%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
        }
        a {
          &:link,
          &:visited {
            color: $inverse;
          }
        }
        & > span > span {
          &.caret {
            @include caret(mix($gray-subtler, $brand-primary, 60%));
          }
        }
      }
    }
  }
}
// IE11, Edgeのみハック
_:-ms-lang(x), .sidebar:not(#sidebar-all) {
  height: calc(100% - 48px);  // 全体の高さからポジションtop分を引く
}


.sidebar-toggle {
  line-height: $navbar-height-base;
  color: $brand-primary;
  background-color: transparent;
  border: none;
  height: $navbar-height-base;
  padding: 0 $sidebar-padding;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  &::before {
    color: $gray-light;
    content: "\e056";
    font-family: "ABUI-icons";
    font-size: floor($component-font-size-base * 1.467); // ~22px
    font-style: normal;
    font-weight: $font-weight-base;
    font-smoothing: antialiased;
    transition: color .25s linear;
  }
  &:hover,
  &:focus {
    outline: none;
    &::before {
      color: $link-hover-color;
    }
  }
}

// Inverse sidebar
.sidebar-inverse {
  background-color: $sidebar-inverse-bg;
  border-right: 1px solid $gray-subtle;
  .divider {
    border-top-color: mix($gray-dark, $sidebar-inverse-bg, 10%);
  }
  ul {
    li {
      color: $gray;
      a {
        &:link,
        &:visited {
          color: $gray;
        }
        &:hover,
        &:active,
        &:focus {
          background-color: rgba($gray-light,0.2);
        }
      }
      span {
        &.caret {
          @include caret(mix($gray-lighter, $sidebar-inverse-bg, 60%));
        }
      }
      ul {
        &:before {
          border-bottom-color: rgba($gray, 0.1);
        }
        li {
          background: rgba($gray, 0.1);
        }
      }
      &.bg-dark {
        background-color: mix($gray, $sidebar-inverse-bg, 12%);
      }
      &.bg-darker {
        background-color: mix(white, $sidebar-inverse-bg, 75%);
      }
      &.active {
        background-color: rgba($gray-subtle, 0.5);
        color: $gray-darker;
        ul {
          background-color: $sidebar-inverse-bg;
          &::before {
            border-bottom-color: mix($sidebar-inverse-bg, $gray, 90%);
          }
        }
        a {
          &:link,
          &:visited {
            color: $brand-primary;
          }
        }
      }
    }
  }
}


// システムヘッダーなし システム情報をsidebarに記載
.sidebar {
  h1.navbar-title {
    font-size: floor($font-size-base * 1.125);
    font-weight: bold;
    line-height: $navbar-input-line-height;
    height: auto;
    margin-top: $s;
    margin-bottom: $s;
    float: none;
    a {
      color: $gray-subtler;
      &:hover {
        color: white;
      }
    }
  }
  .logininfo {
    padding-left: $l;
    padding-right: $l;
    margin-bottom: $l+$s;
    margin-top: -$s;
    font-size: ceil($font-size-base * 0.75);

    .datetime, .username, .logout {
      display: block;
      line-height: $navbar-input-line-height;
      color: $gray-lighter;
      a {
        color: $gray-lighter;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
      }
    }
    .logout {
      padding-top: $x;
      a {
        color: $gray-lighter;
        &:hover {
          opacity: .8;
        }
      }
    }
  }
}