@charset "UTF-8";
/*
Login
----------------------------------------------------------------
*/



.login {
  background-color: $login-bg;
  width: 100%;
  min-width: 0;
  height: 100vh; //ブラウザーの高さ100%
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-screen {
  text-align: center;
  width: calc(100% - 32px);
  color: $inverse;
  @media (min-width: $screen-sm-min) {
    width: calc(100% - 96px + 32px);
  }
  @media (min-width: $screen-md-min) {
    width: calc(100% - 160px + 32px);
    max-width: 960px;
  }
  @media (min-width: $screen-lg-min) {
    width: 960px;
    max-width: none;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.login-icon {
  margin-bottom: 16px;
  text-align: right;
  @media (max-width: $grid-float-breakpoint) {
    text-align: center;
  }

  > img {
    display: inline-block;
    width: 120px;
    height: 120px;
  }
  > h4 {
    font-size: ceil($component-font-size-base*1.714);
    font-weight: 300;
    color: white;
    margin-top: 0;
    small {
      color: inherit;
      display: block;
      font-size: inherit;
      font-weight: 700;
      line-height: 1.3;
    }
  }
}


.login-form {
  padding-top: 16px;
  border-radius: $border-radius-base;
  @media (max-width: $grid-float-breakpoint) {
    padding: 8px 0 0;
  }
  .form-group {
    margin-bottom: 12px;
  }

  .form-control {
    border-color: transparent;
    &:focus {
      border-color: mix($login-bg, white, 40%);
    }
    & + .input-icon {
      top: 2px;
      right: 1px;
    }
  }
  .has-error {
    .form-control {
      border-color: $brand-danger;
    }
    .help-block {
      color: white;
    }
  }

  .help-block {
    text-align: left;
  }

  .btn-primary {
    @include button-variant($btn-default-color, darken($login-bg, 8%), mix($login-bg, black, 90%), mix(saturate(mix($login-bg, black, 90%), 20%), black, 90%));
  }
}

.login-link {
  color: mix($login-bg, white, 50%);
  display: inline-block;
  font-size: ceil($component-font-size-base*0.928);
  margin-top: 16px;
  text-align: center;
}

// credit

.credit {
  display: table;
  font-size: ceil($component-font-size-base*0.928);
  color: rgba($inverse, 0.8);
  position: absolute;
  bottom: 16px;
  right: 16px;
  @media (max-width: $screen-xs-max) {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 36px;
    margin-left: 32px;
  }
  span {
    display: table-cell;
    vertical-align: middle;
    @media (max-width: $screen-xs-max) {
      display: block;
      margin: 0 auto;
      bottom: 0;
    }
  }
  .icon-abui-astrolablogo-mark {
    font-size: 40px;
    color: rgba($inverse, 1);
    padding-left: $m;
    @media (max-width: $screen-xs-max) {
      padding-left: 0;
      padding-top: 6px;
    }
  }
}
