@charset "UTF-8";
/*
Modal
----------------------------------------------------------------
*/



.modal {
	.row {
		margin-left: -$m;
		margin-right: -$m;
	}
	[class^="col-"],
	[class*=" col-"] {
		padding-right: $m;
		padding-left: $m;
	}
	// カラム近接
	[class^="col-"],
	[class*=" col-"] {
		// すべての解像度で適用
		&.col-all-closer {
			padding-left: 6px;
			padding-right: 6px;
		}
		// for tablet (幅768px以上) に適用
		&.col-sm-closer {
			@media (min-width: $screen-sm-min) {
				padding-left: 6px;
				padding-right: 6px;
			}
		}
		// for desktop (幅992px以上) に適用
		&.col-md-closer {
			@media (min-width: $screen-md-min) {
				padding-left: 6px;
				padding-right: 6px;
			}
		}
		// for wide desktop (幅1200px以上) に適用
		&.col-lg-closer {
			@media (min-width: $screen-lg-min) {
				padding-left: 6px;
				padding-right: 6px;
			}
		}
		// 端に来る場合にリセット
		// すべての解像度
		&.col-all-closer--leftend {
			padding-left: $m;
		}
		&.col-all-closer--rightend {
			padding-right: $m;
		}
		// for wide desktop (幅1200px以上) に適用
		@media (min-width: $screen-lg-min) {
			&.col-lg-closer--leftend {
				padding-left: $m;
			}
			&.col-lg-closer--rightend {
				padding-right: $m;
			}
		}
		// for desktop (幅992px以上 - 幅1200px未満) に適用
		@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
			&.col-md-closer--leftend {
				padding-left: $m;
			}
			&.col-md-closer--rightend {
				padding-right: $m;
			}
		}
		// for tablet (幅768px以上 - 幅992px未満) に適用
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
			&.col-sm-closer--leftend {
				padding-left: $m;
			}
			&.col-sm-closer--rightend {
				padding-right: $m;
			}
		}
		// for phone (幅768px未満) に適用
		@media (max-width: $screen-xs-max) {
			&.col-xs-closer--leftend {
				padding-left: $m;
			}
			&.col-xs-closer--rightend {
				padding-right: $m;
			}
		}
	}
}

.modal-box {
	background: $body-bg;
	border-radius: $border-radius-base;
	@extend .dropshadow--lv4;
	padding: 0;
	margin-top: $l * 2;
	margin-bottom: $l * 2;
	z-index: 4000;
}

.modal-open {
	.wrapper-page {
		filter: blur(8px);
	}
}
.modal-backdrop {
	background-color: $gray;
	&.in {
		opacity: .8;
	}
}

.modal-content {
	border-radius: $border-radius-base;
	@extend .dropshadow--lv4;
	border: none;
}


$modal-padding-width: $l+$l;
$modal-padding-width-xs: $l+$x;
.modal-header {
	padding: $modal-padding-width $modal-padding-width $l;
	border: none;
	& .close {
		margin-top: -2px;
	}
	@media (max-width: $screen-xs-max) {
		padding: $modal-padding-width-xs $modal-padding-width-xs $l*0.875;
	}
}
.modal-body {
	padding: 0 $modal-padding-width;
	@media (max-width: $screen-xs-max) {
		padding: 0 $modal-padding-width-xs;
	}
}
.modal-footer {
	// text-align: right;
	padding:  $l $modal-padding-width $modal-padding-width;
	border: none;
	@media (max-width: $screen-xs-max) {
		padding:  $l*0.875 $modal-padding-width-xs $modal-padding-width-xs;
	}
}

.modal-header--title {
	font-size: ceil($font-size-base * 1.5);
	line-height: 1.3;
}
.op8 {
	opacity: .8;
}
.op8fade {
	opacity: .8;
	transition: opacity .5s;
}

@media (min-width: $screen-sm-min) {
	.modal-dialog {
		width: 640px;
		margin: 32px auto;
	}
	.modal-sm {
		width: 300px;
	}
}
@media (min-width: $screen-md-min) {
	.modal-lg {
		width: 960px;
	}
}

.modal-body {
	.section {
		padding: $modal-padding-width 0 $l ;
		&:first-child {
			padding-top: 0;
			border-top: none;
			&.section-filter {
				padding-top: $modal-padding-width;
			}
		}
		@media (max-width: $screen-xs-max) {
			padding: $modal-padding-width-xs 0 $m;
			&:first-child {
				&.section-filter {
					padding-top: $modal-padding-width-xs;
				}
			}
		}
	}
	.filter-result {
		.section {
			&:first-child {
				padding-top: $modal-padding-width;
				@media (max-width: $screen-xs-max) {
					padding-top: $modal-padding-width-xs;
				}
			}
		}
	}
}

// filter
.modal-content {
	.section-filter,
	.btn-filter-again {
		margin-left: -$modal-padding-width;
		margin-right: -$modal-padding-width;
		@media (max-width: $screen-xs-max) {
			margin-left: -$modal-padding-width-xs;
			margin-right: -$modal-padding-width-xs;
		}
	}
	.section-filter {
		padding: $modal-padding-width;
		@media (max-width: $screen-xs-max) {
			padding: $modal-padding-width-xs;
		}
	}
	.btn-filter-again {
		width: auto;
	}
}
