@charset "UTF-8";
/*!
 * Bootstrap v5.3.0 (http://getbootstrap.com)
 * Copyright 2011-2023 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
// abui用にカスタマイズ 2018.02

@media print {
  * {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    &::before, &::after {
      color: #000 !important;
      text-shadow: none !important;
      background: transparent !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
  a {
    text-decoration: underline;
    &:visited {
      text-decoration: underline;
    }
    &[href]::after {
      content: " (" attr(href) ")";
    }
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  a {
    &[href^="#"]::after, &[href^="javascript:"]::after {
      content: "";
    }
  }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr {
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    max-width: 100% !important;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
    td, th {
      background-color: #fff !important;
    }
  }
  .table-bordered {
    th, td {
      border: 1px solid #ddd !important;
    }
  }
}


/*
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format("embedded-opentype"), url('../fonts/glyphicons-halflings-regular.woff2') format("woff2"), url('../fonts/glyphicons-halflings-regular.woff') format("woff"), url('../fonts/glyphicons-halflings-regular.ttf') format("truetype"), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format("svg");
}


.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk::before {
  content: "\002a";
}

.glyphicon-plus::before {
  content: "\002b";
}

.glyphicon-euro::before, .glyphicon-eur::before {
  content: "\20ac";
}

// .glyphicon-minus::before {
//   content: "\2212";
// }

.glyphicon-cloud::before {
  content: "\2601";
}

// .glyphicon-envelope::before {
//   content: "\2709";
// }

// .glyphicon-pencil::before {
//   content: "\270f";
// }

.glyphicon-glass::before {
  content: "\e001";
}

.glyphicon-music::before {
  content: "\e002";
}

// .glyphicon-search::before {
//   content: "\e003";
// }

.glyphicon-heart::before {
  content: "\e005";
}

.glyphicon-star::before {
  content: "\e006";
}

.glyphicon-star-empty::before {
  content: "\e007";
}

.glyphicon-user::before {
  content: "\e008";
}

.glyphicon-film::before {
  content: "\e009";
}

.glyphicon-th-large::before {
  content: "\e010";
}

.glyphicon-th::before {
  content: "\e011";
}

.glyphicon-th-list::before {
  content: "\e012";
}

// .glyphicon-ok::before {
//   content: "\e013";
// }

// .glyphicon-remove::before {
//   content: "\e014";
// }

// .glyphicon-zoom-in::before {
//   content: "\e015";
// }

.glyphicon-zoom-out::before {
  content: "\e016";
}

.glyphicon-off::before {
  content: "\e017";
}

.glyphicon-signal::before {
  content: "\e018";
}

.glyphicon-cog::before {
  content: "\e019";
}

.glyphicon-trash::before {
  content: "\e020";
}

.glyphicon-home::before {
  content: "\e021";
}

.glyphicon-file::before {
  content: "\e022";
}

.glyphicon-time::before {
  content: "\e023";
}

.glyphicon-road::before {
  content: "\e024";
}

.glyphicon-download-alt::before {
  content: "\e025";
}

.glyphicon-download::before {
  content: "\e026";
}

.glyphicon-upload::before {
  content: "\e027";
}

.glyphicon-inbox::before {
  content: "\e028";
}

.glyphicon-play-circle::before {
  content: "\e029";
}

.glyphicon-repeat::before {
  content: "\e030";
}

.glyphicon-refresh::before {
  content: "\e031";
}

.glyphicon-list-alt::before {
  content: "\e032";
}

.glyphicon-lock::before {
  content: "\e033";
}

.glyphicon-flag::before {
  content: "\e034";
}

.glyphicon-headphones::before {
  content: "\e035";
}

.glyphicon-volume-off::before {
  content: "\e036";
}

.glyphicon-volume-down::before {
  content: "\e037";
}

.glyphicon-volume-up::before {
  content: "\e038";
}

.glyphicon-qrcode::before {
  content: "\e039";
}

.glyphicon-barcode::before {
  content: "\e040";
}

.glyphicon-tag::before {
  content: "\e041";
}

.glyphicon-tags::before {
  content: "\e042";
}

.glyphicon-book::before {
  content: "\e043";
}

.glyphicon-bookmark::before {
  content: "\e044";
}

.glyphicon-print::before {
  content: "\e045";
}

.glyphicon-camera::before {
  content: "\e046";
}

.glyphicon-font::before {
  content: "\e047";
}

.glyphicon-bold::before {
  content: "\e048";
}

.glyphicon-italic::before {
  content: "\e049";
}

.glyphicon-text-height::before {
  content: "\e050";
}

.glyphicon-text-width::before {
  content: "\e051";
}

.glyphicon-align-left::before {
  content: "\e052";
}

.glyphicon-align-center::before {
  content: "\e053";
}

.glyphicon-align-right::before {
  content: "\e054";
}

.glyphicon-align-justify::before {
  content: "\e055";
}

.glyphicon-list::before {
  content: "\e056";
}

.glyphicon-indent-left::before {
  content: "\e057";
}

.glyphicon-indent-right::before {
  content: "\e058";
}

.glyphicon-facetime-video::before {
  content: "\e059";
}

.glyphicon-picture::before {
  content: "\e060";
}

.glyphicon-map-marker::before {
  content: "\e062";
}

.glyphicon-adjust::before {
  content: "\e063";
}

.glyphicon-tint::before {
  content: "\e064";
}

// .glyphicon-edit::before {
//   content: "\e065";
// }

.glyphicon-share::before {
  content: "\e066";
}

.glyphicon-check::before {
  content: "\e067";
}

.glyphicon-move::before {
  content: "\e068";
}

.glyphicon-step-backward::before {
  content: "\e069";
}

.glyphicon-fast-backward::before {
  content: "\e070";
}

.glyphicon-backward::before {
  content: "\e071";
}

.glyphicon-play::before {
  content: "\e072";
}

.glyphicon-pause::before {
  content: "\e073";
}

.glyphicon-stop::before {
  content: "\e074";
}

.glyphicon-forward::before {
  content: "\e075";
}

.glyphicon-fast-forward::before {
  content: "\e076";
}

.glyphicon-step-forward::before {
  content: "\e077";
}

.glyphicon-eject::before {
  content: "\e078";
}

.glyphicon-chevron-left::before {
  content: "\e079";
}

.glyphicon-chevron-right::before {
  content: "\e080";
}

.glyphicon-plus-sign::before {
  content: "\e081";
}

.glyphicon-minus-sign::before {
  content: "\e082";
}

.glyphicon-remove-sign::before {
  content: "\e083";
}

.glyphicon-ok-sign::before {
  content: "\e084";
}

.glyphicon-question-sign::before {
  content: "\e085";
}

.glyphicon-info-sign::before {
  content: "\e086";
}

.glyphicon-screenshot::before {
  content: "\e087";
}

.glyphicon-remove-circle::before {
  content: "\e088";
}

.glyphicon-ok-circle::before {
  content: "\e089";
}

.glyphicon-ban-circle::before {
  content: "\e090";
}

.glyphicon-arrow-left::before {
  content: "\e091";
}

.glyphicon-arrow-right::before {
  content: "\e092";
}

.glyphicon-arrow-up::before {
  content: "\e093";
}

.glyphicon-arrow-down::before {
  content: "\e094";
}

.glyphicon-share-alt::before {
  content: "\e095";
}

.glyphicon-resize-full::before {
  content: "\e096";
}

.glyphicon-resize-small::before {
  content: "\e097";
}

.glyphicon-exclamation-sign::before {
  content: "\e101";
}

.glyphicon-gift::before {
  content: "\e102";
}

.glyphicon-leaf::before {
  content: "\e103";
}

.glyphicon-fire::before {
  content: "\e104";
}

.glyphicon-eye-open::before {
  content: "\e105";
}

.glyphicon-eye-close::before {
  content: "\e106";
}

.glyphicon-warning-sign::before {
  content: "\e107";
}

.glyphicon-plane::before {
  content: "\e108";
}

.glyphicon-calendar::before {
  content: "\e109";
}

.glyphicon-random::before {
  content: "\e110";
}

.glyphicon-comment::before {
  content: "\e111";
}

.glyphicon-magnet::before {
  content: "\e112";
}

.glyphicon-chevron-up::before {
  content: "\e113";
}

.glyphicon-chevron-down::before {
  content: "\e114";
}

.glyphicon-retweet::before {
  content: "\e115";
}

.glyphicon-shopping-cart::before {
  content: "\e116";
}

.glyphicon-folder-close::before {
  content: "\e117";
}

.glyphicon-folder-open::before {
  content: "\e118";
}

.glyphicon-resize-vertical::before {
  content: "\e119";
}

.glyphicon-resize-horizontal::before {
  content: "\e120";
}

.glyphicon-hdd::before {
  content: "\e121";
}

.glyphicon-bullhorn::before {
  content: "\e122";
}

.glyphicon-bell::before {
  content: "\e123";
}

.glyphicon-certificate::before {
  content: "\e124";
}

.glyphicon-thumbs-up::before {
  content: "\e125";
}

.glyphicon-thumbs-down::before {
  content: "\e126";
}

.glyphicon-hand-right::before {
  content: "\e127";
}

.glyphicon-hand-left::before {
  content: "\e128";
}

.glyphicon-hand-up::before {
  content: "\e129";
}

.glyphicon-hand-down::before {
  content: "\e130";
}

.glyphicon-circle-arrow-right::before {
  content: "\e131";
}

.glyphicon-circle-arrow-left::before {
  content: "\e132";
}

.glyphicon-circle-arrow-up::before {
  content: "\e133";
}

.glyphicon-circle-arrow-down::before {
  content: "\e134";
}

.glyphicon-globe::before {
  content: "\e135";
}

.glyphicon-wrench::before {
  content: "\e136";
}

.glyphicon-tasks::before {
  content: "\e137";
}

.glyphicon-filter::before {
  content: "\e138";
}

.glyphicon-briefcase::before {
  content: "\e139";
}

.glyphicon-fullscreen::before {
  content: "\e140";
}

.glyphicon-dashboard::before {
  content: "\e141";
}

.glyphicon-paperclip::before {
  content: "\e142";
}

.glyphicon-heart-empty::before {
  content: "\e143";
}

.glyphicon-link::before {
  content: "\e144";
}

.glyphicon-phone::before {
  content: "\e145";
}

.glyphicon-pushpin::before {
  content: "\e146";
}

.glyphicon-usd::before {
  content: "\e148";
}

.glyphicon-gbp::before {
  content: "\e149";
}

.glyphicon-sort::before {
  content: "\e150";
}

.glyphicon-sort-by-alphabet::before {
  content: "\e151";
}

.glyphicon-sort-by-alphabet-alt::before {
  content: "\e152";
}

.glyphicon-sort-by-order::before {
  content: "\e153";
}

.glyphicon-sort-by-order-alt::before {
  content: "\e154";
}

.glyphicon-sort-by-attributes::before {
  content: "\e155";
}

.glyphicon-sort-by-attributes-alt::before {
  content: "\e156";
}

.glyphicon-unchecked::before {
  content: "\e157";
}

.glyphicon-expand::before {
  content: "\e158";
}

.glyphicon-collapse-down::before {
  content: "\e159";
}

.glyphicon-collapse-up::before {
  content: "\e160";
}

.glyphicon-log-in::before {
  content: "\e161";
}

.glyphicon-flash::before {
  content: "\e162";
}

.glyphicon-log-out::before {
  content: "\e163";
}

.glyphicon-new-window::before {
  content: "\e164";
}

.glyphicon-record::before {
  content: "\e165";
}

.glyphicon-save::before {
  content: "\e166";
}

.glyphicon-open::before {
  content: "\e167";
}

.glyphicon-saved::before {
  content: "\e168";
}

.glyphicon-import::before {
  content: "\e169";
}

.glyphicon-export::before {
  content: "\e170";
}

.glyphicon-send::before {
  content: "\e171";
}

.glyphicon-floppy-disk::before {
  content: "\e172";
}

.glyphicon-floppy-saved::before {
  content: "\e173";
}

.glyphicon-floppy-remove::before {
  content: "\e174";
}

.glyphicon-floppy-save::before {
  content: "\e175";
}

.glyphicon-floppy-open::before {
  content: "\e176";
}

.glyphicon-credit-card::before {
  content: "\e177";
}

.glyphicon-transfer::before {
  content: "\e178";
}

.glyphicon-cutlery::before {
  content: "\e179";
}

.glyphicon-header::before {
  content: "\e180";
}

.glyphicon-compressed::before {
  content: "\e181";
}

.glyphicon-earphone::before {
  content: "\e182";
}

.glyphicon-phone-alt::before {
  content: "\e183";
}

.glyphicon-tower::before {
  content: "\e184";
}

.glyphicon-stats::before {
  content: "\e185";
}

.glyphicon-sd-video::before {
  content: "\e186";
}

.glyphicon-hd-video::before {
  content: "\e187";
}

.glyphicon-subtitles::before {
  content: "\e188";
}

.glyphicon-sound-stereo::before {
  content: "\e189";
}

.glyphicon-sound-dolby::before {
  content: "\e190";
}

.glyphicon-sound-5-1::before {
  content: "\e191";
}

.glyphicon-sound-6-1::before {
  content: "\e192";
}

.glyphicon-sound-7-1::before {
  content: "\e193";
}

.glyphicon-copyright-mark::before {
  content: "\e194";
}

.glyphicon-registration-mark::before {
  content: "\e195";
}

.glyphicon-cloud-download::before {
  content: "\e197";
}

.glyphicon-cloud-upload::before {
  content: "\e198";
}

.glyphicon-tree-conifer::before {
  content: "\e199";
}

.glyphicon-tree-deciduous::before {
  content: "\e200";
}

.glyphicon-cd::before {
  content: "\e201";
}

.glyphicon-save-file::before {
  content: "\e202";
}

.glyphicon-open-file::before {
  content: "\e203";
}

.glyphicon-level-up::before {
  content: "\e204";
}

.glyphicon-copy::before {
  content: "\e205";
}

.glyphicon-paste::before {
  content: "\e206";
}

.glyphicon-alert::before {
  content: "\e209";
}

.glyphicon-equalizer::before {
  content: "\e210";
}

.glyphicon-king::before {
  content: "\e211";
}

.glyphicon-queen::before {
  content: "\e212";
}

.glyphicon-pawn::before {
  content: "\e213";
}

.glyphicon-bishop::before {
  content: "\e214";
}

.glyphicon-knight::before {
  content: "\e215";
}

.glyphicon-baby-formula::before {
  content: "\e216";
}

.glyphicon-tent::before {
  content: "\26fa";
}

.glyphicon-blackboard::before {
  content: "\e218";
}

.glyphicon-bed::before {
  content: "\e219";
}

.glyphicon-apple::before {
  content: "\f8ff";
}

.glyphicon-erase::before {
  content: "\e221";
}

.glyphicon-hourglass::before {
  content: "\231b";
}

.glyphicon-lamp::before {
  content: "\e223";
}

.glyphicon-duplicate::before {
  content: "\e224";
}

.glyphicon-piggy-bank::before {
  content: "\e225";
}

.glyphicon-scissors::before {
  content: "\e226";
}

.glyphicon-bitcoin::before, .glyphicon-btc::before, .glyphicon-xbt::before {
  content: "\e227";
}

.glyphicon-yen::before, .glyphicon-jpy::before {
  content: "\00a5";
}

.glyphicon-ruble::before, .glyphicon-rub::before {
  content: "\20bd";
}

.glyphicon-scale::before {
  content: "\e230";
}

.glyphicon-ice-lolly::before {
  content: "\e231";
}

.glyphicon-ice-lolly-tasted::before {
  content: "\e232";
}

.glyphicon-education::before {
  content: "\e233";
}

.glyphicon-option-horizontal::before {
  content: "\e234";
}

.glyphicon-option-vertical::before {
  content: "\e235";
}

.glyphicon-menu-hamburger::before {
  content: "\e236";
}

.glyphicon-modal-window::before {
  content: "\e237";
}

.glyphicon-oil::before {
  content: "\e238";
}

.glyphicon-grain::before {
  content: "\e239";
}

.glyphicon-sunglasses::before {
  content: "\e240";
}

.glyphicon-text-size::before {
  content: "\e241";
}

.glyphicon-text-color::before {
  content: "\e242";
}

.glyphicon-text-background::before {
  content: "\e243";
}

.glyphicon-object-align-top::before {
  content: "\e244";
}

.glyphicon-object-align-bottom::before {
  content: "\e245";
}

.glyphicon-object-align-horizontal::before {
  content: "\e246";
}

.glyphicon-object-align-left::before {
  content: "\e247";
}

.glyphicon-object-align-vertical::before {
  content: "\e248";
}

.glyphicon-object-align-right::before {
  content: "\e249";
}

.glyphicon-triangle-right::before {
  content: "\e250";
}

.glyphicon-triangle-left::before {
  content: "\e251";
}

.glyphicon-triangle-bottom::before {
  content: "\e252";
}

.glyphicon-triangle-top::before {
  content: "\e253";
}

.glyphicon-console::before {
  content: "\e254";
}

.glyphicon-superscript::before {
  content: "\e255";
}

.glyphicon-subscript::before {
  content: "\e256";
}

.glyphicon-menu-left::before {
  content: "\e257";
}

.glyphicon-menu-right::before {
  content: "\e258";
}

.glyphicon-menu-down::before {
  content: "\e259";
}

.glyphicon-menu-up::before {
  content: "\e260";
}
*/


// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. IE11とEdgeでスクロールバーがコンテンツと重なる挙動を回避
*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%; // 4
  -ms-text-size-adjust: 100%;     // 4
  -ms-overflow-style: scrollbar;  // 5
}

// IE10以降で起こる<meta name="viewport">が無視される挙動を回避
@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

// body {
//   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//   font-size: 14px;
//   line-height: 1.42857143;
//   color: #333;
//   background-color: #fff;
// }

// タッチデバイスでのリンクやボタンの反応を向上
a,
area,
button,
[role="button"],
input:not([type="range"]),label,select,summary,textarea {
  touch-action: manipulation;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// a {
//   color: #337ab7;
//   text-decoration: none;
//   &:hover {
//     color: #23527c;
//     text-decoration: underline;
//   }
//   &:focus {
//     color: #23527c;
//     text-decoration: underline;
//     outline: 5px auto -webkit-focus-ring-color;
//     outline-offset: -2px;
//   }
// }

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

ul {
  list-style-type: none;
}
  // IE10以降ハック
  _:-ms-lang(x), ul:not(.dropdown-menu):not(.navbar-nav) {
    list-style-image: url(data:0);
  }


// .carousel-inner > .item > {
//   img, a > img {
//     display: block;
//     max-width: 100%;
//     height: auto;
//   }
// }


hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
  outline: 0 !important;
}

[role="button"] {
  cursor: pointer;
}

// h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
//   font-family: inherit;
//   font-weight: 500;
//   line-height: 1.1;
//   color: inherit;
// }

// h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
//   font-weight: normal;
//   line-height: 1;
//   color: #777;
// }

// h1, .h1, h2, .h2, h3, .h3 {
//   margin-top: 20px;
//   margin-bottom: 10px;
// }

// h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
//   font-size: 65%;
// }

// h4, .h4, h5, .h5, h6, .h6 {
//   margin-top: 10px;
//   margin-bottom: 10px;
// }

// h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
//   font-size: 75%;
// }

// h1, .h1 {
//   font-size: 36px;
// }

// h2, .h2 {
//   font-size: 30px;
// }

// h3, .h3 {
//   font-size: 24px;
// }

// h4, .h4 {
//   font-size: 18px;
// }

// h5, .h5 {
//   font-size: 14px;
// }

// h6, .h6 {
//   font-size: 12px;
// }

// p {
//   margin: 0 0 10px;
// }

// .lead {
//   margin-bottom: 20px;
//   font-size: 16px;
//   font-weight: 300;
//   line-height: 1.4;
// }

// @media (min-width: 768px) {
//   .lead {
//     font-size: 21px;
//   }
// }

// small, .small {
//   font-size: 85%;
// }

mark, .mark {
  padding: .2em;
  background-color: #fcf8e3;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// .text-muted {
//   color: #777;
// }

// .text-primary {
//   color: #337ab7;
// }

// a.text-primary {
//   &:hover, &:focus {
//     color: #286090;
//   }
// }

// .text-success {
//   color: #3c763d;
// }

// a.text-success {
//   &:hover, &:focus {
//     color: #2b542c;
//   }
// }

// .text-info {
//   color: #31708f;
// }

// a.text-info {
//   &:hover, &:focus {
//     color: #245269;
//   }
// }

// .text-warning {
//   color: #8a6d3b;
// }

// a.text-warning {
//   &:hover, &:focus {
//     color: #66512c;
//   }
// }

// .text-danger {
//   color: #a94442;
// }

// a.text-danger {
//   &:hover, &:focus {
//     color: #843534;
//   }
// }

// .bg-primary {
//   color: #fff;
//   background-color: #337ab7;
// }

// a.bg-primary {
//   &:hover, &:focus {
//     background-color: #286090;
//   }
// }

// .bg-success {
//   background-color: #dff0d8;
// }

// a.bg-success {
//   &:hover, &:focus {
//     background-color: #c1e2b3;
//   }
// }

// .bg-info {
//   background-color: #d9edf7;
// }

// a.bg-info {
//   &:hover, &:focus {
//     background-color: #afd9ee;
//   }
// }

// .bg-warning {
//   background-color: #fcf8e3;
// }

// a.bg-warning {
//   &:hover, &:focus {
//     background-color: #f7ecb5;
//   }
// }

// .bg-danger {
//   background-color: #f2dede;
// }

// a.bg-danger {
//   &:hover, &:focus {
//     background-color: #e4b9b9;
//   }
// }

// .page-header {
//   padding-bottom: 9px;
//   margin: 40px 0 20px;
//   border-bottom: 1px solid #eee;
// }

// ul, ol {
//   margin-top: 0;
//   margin-bottom: 10px;
// }

// ul ul, ol ul, ul ol, ol ol {
//   margin-bottom: 0;
// }

.list-unstyled {
  padding-left: 0;
  // list-style: none;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  // list-style: none;
  > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
}

// dl {
//   margin-top: 0;
//   margin-bottom: 20px;
// }

// dt, dd {
//   line-height: 1.42857143;
// }


// @media (min-width: 768px) {
//   .dl-horizontal {
//     dt {
//       float: left;
//       width: 160px;
//       overflow: hidden;
//       clear: left;
//       text-align: right;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//     }
//     dd {
//       margin-left: 180px;
//     }
//   }
// }

// abbr {
//   &[title], &[data-original-title] {
//     cursor: help;
//     border-bottom: 1px dotted #777;
//   }
// }

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// blockquote {
//   padding: 10px 20px;
//   margin: 0 0 20px;
//   font-size: 17.5px;
//   border-left: 5px solid #eee;
//   p:last-child, ul:last-child, ol:last-child {
//     margin-bottom: 0;
//   }
//   footer, .small {
//     display: block;
//     font-size: 80%;
//     line-height: 1.42857143;
//     color: #777;
//   }
//   footer::before, .small::before {
//     content: '\2014 \00A0';
//   }
// }

// .blockquote-reverse, blockquote.pull-right {
//   padding-right: 15px;
//   padding-left: 0;
//   text-align: right;
//   border-right: 5px solid #eee;
//   border-left: 0;
// }

// .blockquote-reverse footer::before, blockquote.pull-right footer::before, .blockquote-reverse small::before, blockquote.pull-right small::before, .blockquote-reverse .small::before, blockquote.pull-right .small::before {
//   content: '';
// }

// .blockquote-reverse footer::after, blockquote.pull-right footer::after, .blockquote-reverse small::after, blockquote.pull-right small::after, .blockquote-reverse .small::after, blockquote.pull-right .small::after {
//   content: '\00A0 \2014';
// }

// address {
//   margin-bottom: 20px;
//   font-style: normal;
//   line-height: 1.42857143;
// }

// code, kbd, pre, samp {
//   font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
// }

// code {
//   padding: 2px 4px;
//   font-size: 90%;
//   color: #c7254e;
//   background-color: #f9f2f4;
//   border-radius: 4px;
// }

// kbd {
//   padding: 2px 4px;
//   font-size: 90%;
//   color: #fff;
//   background-color: #333;
//   border-radius: 3px;
//   -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
//   box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
//   kbd {
//     padding: 0;
//     font-size: 100%;
//     font-weight: bold;
//     -webkit-box-shadow: none;
//     box-shadow: none;
//   }
// }

pre {
  -ms-overflow-style: scrollbar;
//   display: block;
//   padding: 9.5px;
//   margin: 0 0 10px;
//   font-size: 13px;
//   line-height: 1.42857143;
//   color: #333;
//   word-break: break-all;
//   word-wrap: break-word;
//   background-color: #f5f5f5;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   code {
//     padding: 0;
//     font-size: inherit;
//     color: inherit;
//     white-space: pre-wrap;
//     background-color: transparent;
//     border-radius: 0;
//   }
}

// .pre-scrollable {
//   max-height: 340px;
//   overflow-y: scroll;
// }

// .container, .container-fluid {
//   padding-right: 15px;
//   padding-left: 15px;
//   margin-right: auto;
//   margin-left: auto;
// }

// @media (min-width: 768px) {
//   .container {
//     width: 750px;
//   }
// }

// @media (min-width: 992px) {
//   .container {
//     width: 970px;
//   }
// }

// @media (min-width: 1200px) {
//   .container {
//     width: 1170px;
//   }
// }

// .row {
//   margin-right: -15px;
//   margin-left: -15px;
// }

// .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
//   position: relative;
//   min-height: 1px;
//   padding-right: 15px;
//   padding-left: 15px;
// }

// .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11 {
//   float: left;
// }


// .col-xs-pull-12 {
//   right: 100%;
// }

// .col-xs-pull-11 {
//   right: 91.66666667%;
// }

// .col-xs-pull-10 {
//   right: 83.33333333%;
// }

// .col-xs-pull-9 {
//   right: 75%;
// }

// .col-xs-pull-8 {
//   right: 66.66666667%;
// }

// .col-xs-pull-7 {
//   right: 58.33333333%;
// }

// .col-xs-pull-6 {
//   right: 50%;
// }

// .col-xs-pull-5 {
//   right: 41.66666667%;
// }

// .col-xs-pull-4 {
//   right: 33.33333333%;
// }

// .col-xs-pull-3 {
//   right: 25%;
// }

// .col-xs-pull-2 {
//   right: 16.66666667%;
// }

// .col-xs-pull-1 {
//   right: 8.33333333%;
// }

// .col-xs-pull-0 {
//   right: auto;
// }

// .col-xs-push-12 {
//   left: 100%;
// }

// .col-xs-push-11 {
//   left: 91.66666667%;
// }

// .col-xs-push-10 {
//   left: 83.33333333%;
// }

// .col-xs-push-9 {
//   left: 75%;
// }

// .col-xs-push-8 {
//   left: 66.66666667%;
// }

// .col-xs-push-7 {
//   left: 58.33333333%;
// }

// .col-xs-push-6 {
//   left: 50%;
// }

// .col-xs-push-5 {
//   left: 41.66666667%;
// }

// .col-xs-push-4 {
//   left: 33.33333333%;
// }

// .col-xs-push-3 {
//   left: 25%;
// }

// .col-xs-push-2 {
//   left: 16.66666667%;
// }

// .col-xs-push-1 {
//   left: 8.33333333%;
// }

// .col-xs-push-0 {
//   left: auto;
// }

// .col-xs-offset-12 {
//   margin-left: 100%;
// }

// .col-xs-offset-11 {
//   margin-left: 91.66666667%;
// }

// .col-xs-offset-10 {
//   margin-left: 83.33333333%;
// }

// .col-xs-offset-9 {
//   margin-left: 75%;
// }

// .col-xs-offset-8 {
//   margin-left: 66.66666667%;
// }

// .col-xs-offset-7 {
//   margin-left: 58.33333333%;
// }

// .col-xs-offset-6 {
//   margin-left: 50%;
// }

// .col-xs-offset-5 {
//   margin-left: 41.66666667%;
// }

// .col-xs-offset-4 {
//   margin-left: 33.33333333%;
// }

// .col-xs-offset-3 {
//   margin-left: 25%;
// }

// .col-xs-offset-2 {
//   margin-left: 16.66666667%;
// }

// .col-xs-offset-1 {
//   margin-left: 8.33333333%;
// }

// .col-xs-offset-0 {
//   margin-left: 0;
// }

// @media (min-width: 768px) {
//   .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11 {
//     float: left;
//   }
//   .col-sm-12 {
//     float: left;
//     width: 100%;
//   }
//   .col-sm-11 {
//     width: 91.66666667%;
//   }
//   .col-sm-10 {
//     width: 83.33333333%;
//   }
//   .col-sm-9 {
//     width: 75%;
//   }
//   .col-sm-8 {
//     width: 66.66666667%;
//   }
//   .col-sm-7 {
//     width: 58.33333333%;
//   }
//   .col-sm-6 {
//     width: 50%;
//   }
//   .col-sm-5 {
//     width: 41.66666667%;
//   }
//   .col-sm-4 {
//     width: 33.33333333%;
//   }
//   .col-sm-3 {
//     width: 25%;
//   }
//   .col-sm-2 {
//     width: 16.66666667%;
//   }
//   .col-sm-1 {
//     width: 8.33333333%;
//   }
//   .col-sm-pull-12 {
//     right: 100%;
//   }
//   .col-sm-pull-11 {
//     right: 91.66666667%;
//   }
//   .col-sm-pull-10 {
//     right: 83.33333333%;
//   }
//   .col-sm-pull-9 {
//     right: 75%;
//   }
//   .col-sm-pull-8 {
//     right: 66.66666667%;
//   }
//   .col-sm-pull-7 {
//     right: 58.33333333%;
//   }
//   .col-sm-pull-6 {
//     right: 50%;
//   }
//   .col-sm-pull-5 {
//     right: 41.66666667%;
//   }
//   .col-sm-pull-4 {
//     right: 33.33333333%;
//   }
//   .col-sm-pull-3 {
//     right: 25%;
//   }
//   .col-sm-pull-2 {
//     right: 16.66666667%;
//   }
//   .col-sm-pull-1 {
//     right: 8.33333333%;
//   }
//   .col-sm-pull-0 {
//     right: auto;
//   }
//   .col-sm-push-12 {
//     left: 100%;
//   }
//   .col-sm-push-11 {
//     left: 91.66666667%;
//   }
//   .col-sm-push-10 {
//     left: 83.33333333%;
//   }
//   .col-sm-push-9 {
//     left: 75%;
//   }
//   .col-sm-push-8 {
//     left: 66.66666667%;
//   }
//   .col-sm-push-7 {
//     left: 58.33333333%;
//   }
//   .col-sm-push-6 {
//     left: 50%;
//   }
//   .col-sm-push-5 {
//     left: 41.66666667%;
//   }
//   .col-sm-push-4 {
//     left: 33.33333333%;
//   }
//   .col-sm-push-3 {
//     left: 25%;
//   }
//   .col-sm-push-2 {
//     left: 16.66666667%;
//   }
//   .col-sm-push-1 {
//     left: 8.33333333%;
//   }
//   .col-sm-push-0 {
//     left: auto;
//   }
//   .col-sm-offset-12 {
//     margin-left: 100%;
//   }
//   .col-sm-offset-11 {
//     margin-left: 91.66666667%;
//   }
//   .col-sm-offset-10 {
//     margin-left: 83.33333333%;
//   }
//   .col-sm-offset-9 {
//     margin-left: 75%;
//   }
//   .col-sm-offset-8 {
//     margin-left: 66.66666667%;
//   }
//   .col-sm-offset-7 {
//     margin-left: 58.33333333%;
//   }
//   .col-sm-offset-6 {
//     margin-left: 50%;
//   }
//   .col-sm-offset-5 {
//     margin-left: 41.66666667%;
//   }
//   .col-sm-offset-4 {
//     margin-left: 33.33333333%;
//   }
//   .col-sm-offset-3 {
//     margin-left: 25%;
//   }
//   .col-sm-offset-2 {
//     margin-left: 16.66666667%;
//   }
//   .col-sm-offset-1 {
//     margin-left: 8.33333333%;
//   }
//   .col-sm-offset-0 {
//     margin-left: 0;
//   }
// }

// @media (min-width: 992px) {
//   .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11 {
//     float: left;
//   }
//   .col-md-12 {
//     float: left;
//     width: 100%;
//   }
//   .col-md-11 {
//     width: 91.66666667%;
//   }
//   .col-md-10 {
//     width: 83.33333333%;
//   }
//   .col-md-9 {
//     width: 75%;
//   }
//   .col-md-8 {
//     width: 66.66666667%;
//   }
//   .col-md-7 {
//     width: 58.33333333%;
//   }
//   .col-md-6 {
//     width: 50%;
//   }
//   .col-md-5 {
//     width: 41.66666667%;
//   }
//   .col-md-4 {
//     width: 33.33333333%;
//   }
//   .col-md-3 {
//     width: 25%;
//   }
//   .col-md-2 {
//     width: 16.66666667%;
//   }
//   .col-md-1 {
//     width: 8.33333333%;
//   }
//   .col-md-pull-12 {
//     right: 100%;
//   }
//   .col-md-pull-11 {
//     right: 91.66666667%;
//   }
//   .col-md-pull-10 {
//     right: 83.33333333%;
//   }
//   .col-md-pull-9 {
//     right: 75%;
//   }
//   .col-md-pull-8 {
//     right: 66.66666667%;
//   }
//   .col-md-pull-7 {
//     right: 58.33333333%;
//   }
//   .col-md-pull-6 {
//     right: 50%;
//   }
//   .col-md-pull-5 {
//     right: 41.66666667%;
//   }
//   .col-md-pull-4 {
//     right: 33.33333333%;
//   }
//   .col-md-pull-3 {
//     right: 25%;
//   }
//   .col-md-pull-2 {
//     right: 16.66666667%;
//   }
//   .col-md-pull-1 {
//     right: 8.33333333%;
//   }
//   .col-md-pull-0 {
//     right: auto;
//   }
//   .col-md-push-12 {
//     left: 100%;
//   }
//   .col-md-push-11 {
//     left: 91.66666667%;
//   }
//   .col-md-push-10 {
//     left: 83.33333333%;
//   }
//   .col-md-push-9 {
//     left: 75%;
//   }
//   .col-md-push-8 {
//     left: 66.66666667%;
//   }
//   .col-md-push-7 {
//     left: 58.33333333%;
//   }
//   .col-md-push-6 {
//     left: 50%;
//   }
//   .col-md-push-5 {
//     left: 41.66666667%;
//   }
//   .col-md-push-4 {
//     left: 33.33333333%;
//   }
//   .col-md-push-3 {
//     left: 25%;
//   }
//   .col-md-push-2 {
//     left: 16.66666667%;
//   }
//   .col-md-push-1 {
//     left: 8.33333333%;
//   }
//   .col-md-push-0 {
//     left: auto;
//   }
//   .col-md-offset-12 {
//     margin-left: 100%;
//   }
//   .col-md-offset-11 {
//     margin-left: 91.66666667%;
//   }
//   .col-md-offset-10 {
//     margin-left: 83.33333333%;
//   }
//   .col-md-offset-9 {
//     margin-left: 75%;
//   }
//   .col-md-offset-8 {
//     margin-left: 66.66666667%;
//   }
//   .col-md-offset-7 {
//     margin-left: 58.33333333%;
//   }
//   .col-md-offset-6 {
//     margin-left: 50%;
//   }
//   .col-md-offset-5 {
//     margin-left: 41.66666667%;
//   }
//   .col-md-offset-4 {
//     margin-left: 33.33333333%;
//   }
//   .col-md-offset-3 {
//     margin-left: 25%;
//   }
//   .col-md-offset-2 {
//     margin-left: 16.66666667%;
//   }
//   .col-md-offset-1 {
//     margin-left: 8.33333333%;
//   }
//   .col-md-offset-0 {
//     margin-left: 0;
//   }
// }

// @media (min-width: 1200px) {
//   .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11 {
//     float: left;
//   }
//   .col-lg-12 {
//     float: left;
//     width: 100%;
//   }
//   .col-lg-11 {
//     width: 91.66666667%;
//   }
//   .col-lg-10 {
//     width: 83.33333333%;
//   }
//   .col-lg-9 {
//     width: 75%;
//   }
//   .col-lg-8 {
//     width: 66.66666667%;
//   }
//   .col-lg-7 {
//     width: 58.33333333%;
//   }
//   .col-lg-6 {
//     width: 50%;
//   }
//   .col-lg-5 {
//     width: 41.66666667%;
//   }
//   .col-lg-4 {
//     width: 33.33333333%;
//   }
//   .col-lg-3 {
//     width: 25%;
//   }
//   .col-lg-2 {
//     width: 16.66666667%;
//   }
//   .col-lg-1 {
//     width: 8.33333333%;
//   }
//   .col-lg-pull-12 {
//     right: 100%;
//   }
//   .col-lg-pull-11 {
//     right: 91.66666667%;
//   }
//   .col-lg-pull-10 {
//     right: 83.33333333%;
//   }
//   .col-lg-pull-9 {
//     right: 75%;
//   }
//   .col-lg-pull-8 {
//     right: 66.66666667%;
//   }
//   .col-lg-pull-7 {
//     right: 58.33333333%;
//   }
//   .col-lg-pull-6 {
//     right: 50%;
//   }
//   .col-lg-pull-5 {
//     right: 41.66666667%;
//   }
//   .col-lg-pull-4 {
//     right: 33.33333333%;
//   }
//   .col-lg-pull-3 {
//     right: 25%;
//   }
//   .col-lg-pull-2 {
//     right: 16.66666667%;
//   }
//   .col-lg-pull-1 {
//     right: 8.33333333%;
//   }
//   .col-lg-pull-0 {
//     right: auto;
//   }
//   .col-lg-push-12 {
//     left: 100%;
//   }
//   .col-lg-push-11 {
//     left: 91.66666667%;
//   }
//   .col-lg-push-10 {
//     left: 83.33333333%;
//   }
//   .col-lg-push-9 {
//     left: 75%;
//   }
//   .col-lg-push-8 {
//     left: 66.66666667%;
//   }
//   .col-lg-push-7 {
//     left: 58.33333333%;
//   }
//   .col-lg-push-6 {
//     left: 50%;
//   }
//   .col-lg-push-5 {
//     left: 41.66666667%;
//   }
//   .col-lg-push-4 {
//     left: 33.33333333%;
//   }
//   .col-lg-push-3 {
//     left: 25%;
//   }
//   .col-lg-push-2 {
//     left: 16.66666667%;
//   }
//   .col-lg-push-1 {
//     left: 8.33333333%;
//   }
//   .col-lg-push-0 {
//     left: auto;
//   }
//   .col-lg-offset-12 {
//     margin-left: 100%;
//   }
//   .col-lg-offset-11 {
//     margin-left: 91.66666667%;
//   }
//   .col-lg-offset-10 {
//     margin-left: 83.33333333%;
//   }
//   .col-lg-offset-9 {
//     margin-left: 75%;
//   }
//   .col-lg-offset-8 {
//     margin-left: 66.66666667%;
//   }
//   .col-lg-offset-7 {
//     margin-left: 58.33333333%;
//   }
//   .col-lg-offset-6 {
//     margin-left: 50%;
//   }
//   .col-lg-offset-5 {
//     margin-left: 41.66666667%;
//   }
//   .col-lg-offset-4 {
//     margin-left: 33.33333333%;
//   }
//   .col-lg-offset-3 {
//     margin-left: 25%;
//   }
//   .col-lg-offset-2 {
//     margin-left: 16.66666667%;
//   }
//   .col-lg-offset-1 {
//     margin-left: 8.33333333%;
//   }
//   .col-lg-offset-0 {
//     margin-left: 0;
//   }
// }

// table {
//   background-color: transparent;
// }

// th {
//   text-align: left;
// }

.table {
  // width: 100%;
  // max-width: 100%;
  // margin-bottom: 20px;
  > {
    thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
      // padding: 8px;
      // line-height: 1.42857143;
      // vertical-align: top;
      // border-top: 1px solid #ddd;
    }
    // thead > tr > th {
    //   vertical-align: bottom;
    //   border-bottom: 2px solid #ddd;
    // }
    // caption + thead > tr:first-child > th, colgroup + thead > tr:first-child > th, thead:first-child > tr:first-child > th, caption + thead > tr:first-child > td, colgroup + thead > tr:first-child > td, thead:first-child > tr:first-child > td {
    //   border-top: 0;
    // }
    // tbody + tbody {
    //   border-top: 2px solid #ddd;
    // }
  }
  // .table {
  //   background-color: #fff;
  // }
}

// .table-condensed > {
//   thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
//     padding: 5px;
//   }
// }

// .table-bordered {
//   border: 1px solid #ddd;
//   > {
//     thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
//       border: 1px solid #ddd;
//     }
//     thead > tr > {
//       th, td {
//         border-bottom-width: 2px;
//       }
//     }
//   }
// }

// .table-striped > tbody > tr:nth-of-type(odd) {
//   background-color: #f9f9f9;
// }

// .table-hover > tbody > tr:hover {
//   background-color: #f5f5f5;
// }

// table {
//   col[class*="col-"] {
//     position: static;
//     display: table-column;
//     float: none;
//   }
//   td[class*="col-"], th[class*="col-"] {
//     position: static;
//     display: table-cell;
//     float: none;
//   }
// }

// .table > {
//   thead > tr > td.active, tbody > tr > td.active, tfoot > tr > td.active, thead > tr > th.active, tbody > tr > th.active, tfoot > tr > th.active, thead > tr.active > td, tbody > tr.active > td, tfoot > tr.active > td, thead > tr.active > th, tbody > tr.active > th, tfoot > tr.active > th {
//     background-color: #f5f5f5;
//   }
// }

// .table-hover > tbody > tr {
//   > {
//     // td.active:hover, th.active:hover {
//     //   background-color: #e8e8e8;
//     // }
//   }
//   &.active:hover > td, &.active:hover > th {
//     background-color: #e8e8e8;
//   }
// }

// .table > {
//   thead > tr > td.success, thead > tr > th.success, tbody > tr > th.success, tfoot > tr > th.success, thead > tr.success > td, tbody > tr.success > td, tfoot > tr.success > td, thead > tr.success > th, tbody > tr.success > th, tfoot > tr.success > th {
//     background-color: #dff0d8;
//   }
// }

// .table-hover > tbody > tr {
//   > {
//     td.success:hover, th.success:hover {
//       background-color: #d0e9c6;
//     }
//   }
//   // &.success:hover > td, &:hover > .success, &.success:hover > th {
//   //   background-color: #d0e9c6;
//   // }
// }

// .table > {
//   // thead > tr > td.info, tbody > tr > td.info, tfoot > tr > td.info, thead > tr > th.info, tbody > tr > th.info, tfoot > tr > th.info,
//   thead > tr.info > td, tbody > tr.info > td, tfoot > tr.info > td, thead > tr.info > th, tbody > tr.info > th, tfoot > tr.info > th {
//     background-color: #d9edf7;
//   }
// }

// .table-hover > tbody > tr {
//   // > {
//   //   td.info:hover, th.info:hover {
//   //     background-color: #c4e3f3;
//   //   }
//   // }
//   &.info:hover > td, &:hover > .info, &.info:hover > th {
//     background-color: #c4e3f3;
//   }
// }

// .table {
//   tr.warning > td, tr.warning > th {
//     background-color: #fcf8e3;
//   }
// }

// .table-hover > tbody > tr {
//   > {
//     td.warning:hover, th.warning:hover {
//       background-color: #faf2cc;
//     }
//   }
//   &.warning:hover > td, &:hover > .warning, &.warning:hover > th {
//     background-color: #faf2cc;
//   }
// }

// .table {
//   tr > td.danger, tr > th.danger, tr.danger > td, tr.danger > th {
//     background-color: #f2dede;
//   }
// }

// .table-hover > tbody > tr {
//   > {
//     td.danger:hover, th.danger:hover {
//       background-color: #ebcccc;
//     }
//   }
//   &.danger:hover > td, &:hover > .danger, &.danger:hover > th {
//     background-color: #ebcccc;
//   }
// }

// .table-responsive {
//   min-height: .01%;
//   overflow-x: auto;
// }

// @media screen and (max-width: 767px) {
//   .table-responsive {
//     width: 100%;
//     margin-bottom: 15px;
//     overflow-y: hidden;
//     -ms-overflow-style: -ms-autohiding-scrollbar;
//     border: 1px solid #ddd;
//     > {
//       .table {
//         margin-bottom: 0;
//         > {
//           thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
//             white-space: nowrap;
//           }
//         }
//       }
//       .table-bordered {
//         border: 0;
//         > {
//           thead > tr > th:first-child, tbody > tr > th:first-child, tfoot > tr > th:first-child, thead > tr > td:first-child, tbody > tr > td:first-child, tfoot > tr > td:first-child {
//             border-left: 0;
//           }
//           thead > tr > th:last-child, tbody > tr > th:last-child, tfoot > tr > th:last-child, thead > tr > td:last-child, tbody > tr > td:last-child, tfoot > tr > td:last-child {
//             border-right: 0;
//           }
//           tbody > tr:last-child > th, tfoot > tr:last-child > th, tbody > tr:last-child > td, tfoot > tr:last-child > td {
//             border-bottom: 0;
//           }
//         }
//       }
//     }
//   }
// }

// fieldset {
//   min-width: 0;
//   padding: 0;
//   margin: 0;
//   border: 0;
// }

// legend {
//   display: block;
//   width: 100%;
//   padding: 0;
//   margin-bottom: 20px;
//   font-size: 21px;
//   line-height: inherit;
//   color: #333;
//   border: 0;
//   border-bottom: 1px solid #e5e5e5;
// }

// label {
//   display: inline-block;
//   max-width: 100%;
//   margin-bottom: 5px;
//   font-weight: bold;
// }

input {
  // &[type="search"] {
  //   -webkit-box-sizing: border-box;
  //   -moz-box-sizing: border-box;
  //   box-sizing: border-box;
  // }
  // &[type="radio"], &[type="checkbox"] {
  //   margin: 4px 0 0;
  //   margin-top: 1px \9;
  //   line-height: normal;
  // }
  &[type="file"] {
    display: block;
    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }
  &[type="range"] {
    display: block;
    width: 100%;
  }
}

// select {
//   &[multiple], &[size] {
//     height: auto;
//   }
// }

// input {
//   &[type="radio"]:focus, &[type="checkbox"]:focus {
//     outline: 5px auto -webkit-focus-ring-color;
//     outline-offset: -2px;
//   }
// }

// output {
//   display: block;
//   padding-top: 7px;
//   font-size: 14px;
//   line-height: 1.42857143;
//   color: #555;
// }

.form-control {
  // display: block;
  // width: 100%;
  // height: 34px;
  // padding: 6px 12px;
  // font-size: 14px;
  // line-height: 1.42857143;
  // color: #555;
  // background-color: #fff;
  // background-image: none;
  // border: 1px solid #ccc;
  // border-radius: 4px;
  // -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  // -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  // -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  // transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  // &:focus {
    // border-color: #66afe9;
    // outline: 0;
    // -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  // }
  &::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  &:-ms-input-placeholder, &::-webkit-input-placeholder {
    color: #999;
  }
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  // &[disabled], &[readonly] {
  //   background-color: #eee;
  //   opacity: 1;
  // }
}

// fieldset[disabled] .form-control {
//   background-color: #eee;
//   opacity: 1;
// }

// .form-control[disabled], fieldset[disabled] .form-control {
//   cursor: not-allowed;
// }

// textarea.form-control {
//   height: auto;
// }

// input[type="search"] {
//   -webkit-appearance: none;
// }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input {
    &[type="date"].form-control, &[type="time"].form-control, &[type="datetime-local"].form-control, &[type="month"].form-control {
      line-height: 34px;
    }
    &[type="date"].input-sm, &[type="time"].input-sm, &[type="datetime-local"].input-sm, &[type="month"].input-sm {
      line-height: 30px;
    }
  }
  .input-group-sm input {
    &[type="date"], &[type="time"], &[type="datetime-local"], &[type="month"] {
      line-height: 30px;
    }
  }
  input {
    &[type="date"].input-lg, &[type="time"].input-lg, &[type="datetime-local"].input-lg, &[type="month"].input-lg {
      line-height: 46px;
    }
  }
  .input-group-lg input {
    &[type="date"], &[type="time"], &[type="datetime-local"], &[type="month"] {
      line-height: 46px;
    }
  }
}

// .form-group {
//   margin-bottom: 15px;
// }

.radio, .checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio label, .checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

// .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
//   position: absolute;
//   margin-top: 4px \9;
//   margin-left: -20px;
// }

// .radio + .radio, .checkbox + .checkbox {
//   margin-top: -5px;
// }

// .radio-inline, .checkbox-inline {
//   position: relative;
//   display: inline-block;
//   padding-left: 20px;
//   margin-bottom: 0;
//   font-weight: normal;
//   vertical-align: middle;
//   cursor: pointer;
// }

// .radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
//   margin-top: 0;
//   margin-left: 10px;
// }

// input {
//   &[type="radio"][disabled], &[type="checkbox"][disabled], &[type="radio"].disabled, &[type="checkbox"].disabled {
//     cursor: not-allowed;
//   }
// }

fieldset[disabled] input {
  &[type="radio"], &[type="checkbox"] {
    cursor: not-allowed;
  }
}

// .radio-inline.disabled, .checkbox-inline.disabled {
//   cursor: not-allowed;
// }

fieldset[disabled] {
  .radio-inline, .checkbox-inline {
    cursor: not-allowed;
  }
}

// .radio.disabled label, .checkbox.disabled label {
//   cursor: not-allowed;
// }

fieldset[disabled] {
  .radio label, .checkbox label {
    cursor: not-allowed;
  }
}

// .form-control-static {
//   min-height: 34px;
//   padding-top: 7px;
//   padding-bottom: 7px;
//   margin-bottom: 0;
//   &.input-lg, &.input-sm {
//     padding-right: 0;
//     padding-left: 0;
//   }
// }

// .input-sm {
//   height: 30px;
//   padding: 5px 10px;
//   font-size: 12px;
//   line-height: 1.5;
//   border-radius: 3px;
// }

select.input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm, select[multiple].input-sm {
  height: auto;
}

.form-group-sm {
  .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  select.form-control {
    height: 30px;
    line-height: 30px;
  }
  textarea.form-control, select[multiple].form-control {
    height: auto;
  }
  .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
}

// .input-lg {
//   height: 46px;
//   padding: 10px 16px;
//   font-size: 18px;
//   line-height: 1.3333333;
//   border-radius: 6px;
// }

select.input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg, select[multiple].input-lg {
  height: auto;
}

.form-group-lg {
  .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }
  select.form-control {
    height: 46px;
    line-height: 46px;
  }
  textarea.form-control, select[multiple].form-control {
    height: auto;
  }
  .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }
}

.has-feedback {
  position: relative;
  .form-control {
    padding-right: 42.5px;
  }
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.input-lg + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success {
  .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline, &.radio label, &.checkbox label, &.radio-inline label, &.checkbox-inline label {
    color: #3c763d;
  }
  .form-control {
    border-color: #3c763d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &:focus {
      border-color: #2b542c;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    }
  }
  .input-group-addon {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d;
  }
  .form-control-feedback {
    color: #3c763d;
  }
}

.has-warning {
  .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline, &.radio label, &.checkbox label, &.radio-inline label, &.checkbox-inline label {
    color: #8a6d3b;
  }
  .form-control {
    border-color: #8a6d3b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &:focus {
      border-color: #66512c;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    }
  }
  .input-group-addon {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b;
  }
  .form-control-feedback {
    color: #8a6d3b;
  }
}

.has-error {
  .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline, &.radio label, &.checkbox label, &.radio-inline label, &.checkbox-inline label {
    color: #a94442;
  }
  .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &:focus {
      border-color: #843534;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    }
  }
  .input-group-addon {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442;
  }
  .form-control-feedback {
    color: #a94442;
  }
}

.has-feedback label {
  ~ .form-control-feedback {
    top: 25px;
  }
  &.sr-only ~ .form-control-feedback {
    top: 0;
  }
}

// .help-block {
//   display: block;
//   margin-top: 5px;
//   margin-bottom: 10px;
//   color: #737373;
// }

@media (min-width: 768px) {
  .form-inline {
    // .form-group {
      // display: inline-block;
      // margin-bottom: 0;
      // vertical-align: middle;
    // }
    // .form-control {
    //   display: inline-block;
    //   width: auto;
    //   vertical-align: middle;
    // }
    .form-control-static {
      display: inline-block;
    }
    .input-group {
      display: inline-table;
      vertical-align: middle;
      .input-group-addon, .input-group-btn, .form-control {
        width: auto;
      }
      > .form-control {
        width: 100%;
      }
    }
    .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }
    .radio, .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .radio label, .checkbox label {
      padding-left: 0;
    }
    .radio input[type="radio"], .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
    }
    .has-feedback .form-control-feedback {
      top: 0;
    }
  }
}

.form-horizontal {
  // .radio, .checkbox, .radio-inline, .checkbox-inline {
  //   padding-top: 7px;
  //   margin-top: 0;
  //   margin-bottom: 0;
  // }
  // .radio, .checkbox {
  //   min-height: 27px;
  // }
  // .form-group {
  //   margin-right: -15px;
  //   margin-left: -15px;
  // }
  // .has-feedback .form-control-feedback {
  //   right: 15px;
  // }
  @media (min-width: 768px) {
    & .control-label {
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right;
    }
    & .form-group-lg .control-label {
      padding-top: 11px;
      font-size: 18px;
    }
    & .form-group-sm .control-label {
      padding-top: 6px;
      font-size: 12px;
    }
  }
}



.btn {
  // display: inline-block;
  // padding: 6px 12px;
  // margin-bottom: 0;
  // font-size: 14px;
  // font-weight: normal;
  // line-height: 1.42857143;
  // text-align: center;
  // white-space: nowrap;
  // vertical-align: middle;
  // -ms-touch-action: manipulation;
  // touch-action: manipulation;
  // cursor: pointer;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  // background-image: none;
  // border: 1px solid transparent;
  // border-radius: 4px;
  &:focus, &:active:focus, &.active:focus, &.focus, &:active.focus, &.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  &:hover, &:focus, &.focus {
    color: #333;
    text-decoration: none;
  }
  &:active, &.active {
    background-image: none;
    // outline: 0;
    // box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  &.disabled, &[disabled] {
    cursor: not-allowed;
    box-shadow: none;
    // opacity: .65;
  }
}

fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity = 65);
  box-shadow: none;
  opacity: .65;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}


// btn-default :active+他セレクタの基本スタイル
%btn-default-option--active {
  // color: #fff;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
%btn-default-dropdown-toggle--focus {
  // color: #fff;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default {
  // color: #333;
  // background-color: #fff;
  border-color: #ccc;
  &:focus, &.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
  }
  &:hover, &:active, &.active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  &:active:hover, &.active:hover,
  &:active:focus, &.active:focus,
  &:active.focus, &.active.focus {
    @extend %btn-default-option--active;
  }

  &:active, &.active {
    background-image: none;
  }

  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus,
  &.disabled.focus, &[disabled].focus {
    background-color: #fff;
    border-color: #ccc;
  }

  & .badge {
    color: #fff;
    background-color: #333;
  }
}

// .open > .dropdown-toggle.btn-default {
//   color: #333;
//   background-color: #e6e6e6;
//   background-image: none;
//   border-color: #adadad;
//   &:hover,&:focus,&.focus {
//     @extend %btn-default-dropdown-toggle--focus;
//   }
// }

fieldset[disabled] {
  & .btn-default:hover,
  & .btn-default:focus,
  & .btn-default.focus {
    background-color: #fff;
    border-color: #ccc;
  }
}



// btn-primary :active+他セレクタの基本スタイル
%btn-primary-option--active {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}
%btn-primary-dropdown-toggle--focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.btn-primary {
  border-color: #2e6da4;
  &:focus, &.focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40;
  }
  &:hover, &:active, &.active {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
  }
  &:active:hover, &.active:hover,
  &:active:focus, &.active:focus,
  &:active.focus, &.active.focus {
    @extend %btn-primary-option--active;
  }
  &:active,&.active {background-image:none}

  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus,
  &.disabled.focus, &[disabled].focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }

  & .badge {
    color: #337ab7;
    background-color: #fff;
  }
}

// .open > .dropdown-toggle.btn-primary {
//   color: #fff;
//   background-color: #286090;
//   background-image: none;
//   border-color: #204d74;
//   &:hover, &:focus, &.focus {
//     @extend %btn-primary-dropdown-toggle--focus;
//   }
// }

fieldset[disabled] {
  & .btn-primary:hover,
  & .btn-primary:focus,
  & .btn-primary.focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}


/*
btn-successはabuiで使用していない為コメントアウト

.btn-success {
  // color: #fff;
  // background-color: #5cb85c;
  // border-color: #4cae4c;
  &.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625;
  }
  &.active {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
  }
}

.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success {
  &:active:hover, &.active:hover {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }
}

.open > .dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success {
  &:active:focus, &.active:focus {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }
}

.open > .dropdown-toggle.btn-success:focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success {
  &:active.focus, &.active.focus {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }
}

.open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success {
  &:active, &.active {
    background-image: none;
  }
}

.open > .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success {
  &.disabled:hover, &[disabled]:hover {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success {
  &.disabled:focus, &[disabled]:focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success:focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success {
  &.disabled.focus, &[disabled].focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}
*/


// btn-info :active+他セレクタの基本スタイル
%btn-info-option--active {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}
%btn-info-dropdown-toggle--active {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info {
  // color: #fff;
  // background-color: #5bc0de;
  // border-color: #46b8da;
  &.focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85;
  }
  &.active {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc;
  }
  &:active:hover, &.active:hover,
  &:active:focus, &.active:focus,
  &:active.focus, &.active.focus {
    @extend %btn-info-option--active;
  }
  &:active,&.active { background-image:none; }

  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus,
  &.disabled.focus, &[disabled].focus {
    background-color: #5bc0de;
    border-color: #46b8da;
  }

  & .badge {
    color: #5bc0de;
    background-color: #fff;
  }
}

// .open > .dropdown-toggle.btn-info {
//   color: #fff;
//   background-color: #31b0d5;
//   background-image: none;
//   border-color: #269abc;
//   &:hover, &:focus, &.focus {
//     @extend %btn-info-dropdown-toggle--active;
//   }
// }

fieldset[disabled] {
  & .btn-info:hover,
  & .btn-info:focus,
  & .btn-info.focus {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}


// btn-warning :active+他セレクタの基本スタイル
%btn-warning-option--active {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}
%btn-warning-dropdown-toggle--focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning {
  // color: #fff;
  // background-color: #f0ad4e;
  // border-color: #eea236;
  &.focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d;
  }
  &.active {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
  }

  &:active:hover, &.active:hover,
  &:active:focus, &.active:focus,
  &:active.focus, &.active.focus {
    @extend %btn-warning-option--active;
  }
  &:active, &.active {background-image:none;}

  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus,
  &.disabled.focus, &[disabled].focus {
    background-color: #f0ad4e;
    border-color: #eea236;
  }

  & .badge {
    color: #f0ad4e;
    background-color: #fff;
  }
}

// .open > .dropdown-toggle.btn-warning {
//   color: #fff;
//   background-color: #ec971f;
//   background-image: none;
//   border-color: #d58512;
//   &:hover,&:focus,&.focus {
//     @extend %btn-warning-dropdown-toggle--focus;
//   }
// }

fieldset[disabled] {
  & .btn-warning:hover,
  & .btn-warning:focus,
  & .btn-warning.focus {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}


// btn-danger :active+他セレクタの基本スタイル
%btn-danger-option--active {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}
%btn-danger-dropdown-toggle--focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger {
  // color: #fff;
  // background-color: #d9534f;
  // border-color: #d43f3a;
  &.focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19;
  }
  &.active {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
  }
  &:active:hover, &.active:hover,
  &:active:focus, &.active:focus,
  &:active.focus, &.active.focus {
    @extend %btn-danger-option--active;
  }
  &:active, &.active { background-image: none; }

  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus,
  &.disabled.focus, &[disabled].focus {
    background-color: #d9534f;
    border-color: #d43f3a;
  }

  & .badge {
    color: #d9534f;
    background-color: #fff;
  }
}

.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #ac2925;
  &:hover, :focus, .focus {
    @extend %btn-danger-dropdown-toggle--focus;
  }
}

fieldset[disabled] {
  & .btn-danger:hover,
  & .btn-danger:focus,
  & .focus {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}


/*
// abuiでは使用してない為コメントアウト
.btn-link {
  font-weight: normal;
  color: #337ab7;
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  &:active, &.active, &[disabled] {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link {
  border-color: transparent;
  &:hover, &:focus, &:active {
    border-color: transparent;
  }
  &:hover, &:focus {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled]:hover {
    color: #777;
    text-decoration: none;
  }
}

fieldset[disabled] .btn-link:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:focus {
  color: #777;
  text-decoration: none;
}
*/

// .btn-lg, .btn-group-lg > .btn {
//   padding: 10px 16px;
//   font-size: 18px;
//   line-height: 1.3333333;
//   border-radius: 6px;
// }

// .btn-sm, .btn-group-sm > .btn {
//   padding: 5px 10px;
//   font-size: 12px;
//   line-height: 1.5;
//   border-radius: 3px;
// }

// .btn-xs, .btn-group-xs > .btn {
//   padding: 1px 5px;
//   font-size: 12px;
//   line-height: 1.5;
//   border-radius: 3px;
// }

.btn-block {
  display: block;
  width: 100%;
  + .btn-block {
    margin-top: 5px;
  }
}

input {
  &[type="submit"].btn-block, &[type="reset"].btn-block, &[type="button"].btn-block {
    width: 100%;
  }
}

.fade {
  opacity: 0;
  transition: opacity .15s linear;
  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;
  &.in {
    display: block;
  }
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height, visibility;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  // border-top: 4px dashed;
  // border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  // display: none;
  float: left;
  // min-width: 160px;
  // padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  // border: 1px solid #ccc;
  // border: 1px solid rgba(0, 0, 0, 0.15);
  // border-radius: 4px;
  // -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  // box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  &.pull-right {
    right: 0;
    left: auto;
  }
  .divider {
    height: 1px;
    margin: {
      left: 0;
      right: 0;
    }
    overflow: hidden;
    // background-color: #e5e5e5;
  }
  > {
    li > a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap;
      &:hover, &:focus {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
    .active > a {
      color: #fff;
      text-decoration: none;
      background-color: #337ab7;
      outline: 0;
      &:hover, &:focus {
        color: #fff;
        text-decoration: none;
        background-color: #337ab7;
        outline: 0;
      }
    }
    .disabled > a {
      color: #777;
      &:hover, &:focus {
        color: #777;
      }
      &:hover, &:focus {
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
        background-image: none;
        filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
      }
    }
  }
}

.open > {
  .dropdown-menu {
    display: block;
  }
  a {
    outline: 0;
  }
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .navbar-right {
    .dropdown-menu {
      right: 0;
      left: auto;
    }
    .dropdown-menu-left {
      right: auto;
      left: 0;
    }
  }
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  position: relative;
  float: left;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group-vertical > .btn:focus, .btn-group > .btn:active, .btn-group-vertical > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group {
  .btn + {
    .btn, .btn-group {
      margin-left: -1px;
    }
  }
  .btn-group + {
    .btn, .btn-group {
      margin-left: -1px;
    }
  }
}

.btn-toolbar {
  margin-left: -5px;
  .btn, .btn-group, .input-group {
    float: left;
  }
  > {
    .btn, .btn-group, .input-group {
      margin-left: 5px;
    }
  }
}

.btn-group {
  > {
    .btn {
      &:not(:first-child):not(:last-child):not(.dropdown-toggle) {
        border-radius: 0;
      }
      &:first-child {
        margin-left: 0;
        &:not(:last-child):not(.dropdown-toggle) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &:last-child:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .dropdown-toggle:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .btn-group {
      float: left;
      &:not(:first-child):not(:last-child) > .btn {
        border-radius: 0;
      }
      &:first-child:not(:last-child) > {
        .btn:last-child, .dropdown-toggle {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &:last-child:not(:first-child) > .btn:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .dropdown-toggle:active {
    outline: 0;
  }
  &.open .dropdown-toggle {
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    &.btn-link {
      box-shadow: none;
    }
  }
  > {
    .btn + .dropdown-toggle {
      padding-right: 8px;
      padding-left: 8px;
    }
    .btn-lg + .dropdown-toggle {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}

.dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}

/*
// abuiでは使用していない為コメントアウト
.btn-group-vertical > {
  .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
  }
  .btn-group {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    > .btn {
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;
      float: none;
    }
  }
  .btn + {
    .btn, .btn-group {
      margin-top: -1px;
      margin-left: 0;
    }
  }
  .btn-group + {
    .btn, .btn-group {
      margin-top: -1px;
      margin-left: 0;
    }
  }
  .btn {
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &:first-child:not(:last-child) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  .btn-group {
    &:not(:first-child):not(:last-child) > .btn {
      border-radius: 0;
    }
    &:first-child:not(:last-child) > {
      .btn:last-child, .dropdown-toggle {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:last-child:not(:first-child) > .btn:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
*/


.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  > {
    .btn {
      display: table-cell;
      float: none;
      width: 1%;
    }
    .btn-group {
      display: table-cell;
      float: none;
      width: 1%;
      .btn {
        width: 100%;
      }
      .dropdown-menu {
        left: auto;
      }
    }
  }
}

[data-toggle="buttons"] > {
  .btn input[type="radio"], .btn-group > .btn input[type="radio"], .btn input[type="checkbox"], .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  &[class*="col-"] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    &:focus {
      z-index: 3;
    }
  }
}

.input-group-lg > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }
}

select.input-group-lg > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    height: 46px;
    line-height: 46px;
  }
}

textarea.input-group-lg > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    height: auto;
  }
}

select[multiple].input-group-lg > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    height: auto;
  }
}

.input-group-sm > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}

select.input-group-sm > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    height: 30px;
    line-height: 30px;
  }
}

textarea.input-group-sm > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    height: auto;
  }
}

select[multiple].input-group-sm > {
  .form-control, .input-group-addon, .input-group-btn > .btn {
    height: auto;
  }
}

.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  // padding: 6px 12px;
  // font-size: 14px;
  font-weight: normal;
  line-height: 1;
  // color: #555;
  // text-align: center;
  // background-color: #eee;
  // border: 1px solid #ccc;
  // border-radius: 4px;
  &.input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
  }
  &.input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px;
  }
  input {
    &[type="radio"], &[type="checkbox"] {
      margin-top: 0;
    }
  }
}

// .input-group .form-control:first-child, .input-group-addon:first-child {
  // border-top-right-radius: 0;
  // border-bottom-right-radius: 0;
// }

.input-group-btn {
  &:first-child > {
    .btn, .btn-group > .btn, .dropdown-toggle {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &:last-child > {
    .btn:not(:last-child):not(.dropdown-toggle), .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child, .input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-btn {
  &:last-child > {
    .btn, .btn-group > .btn, .dropdown-toggle {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &:first-child > {
    .btn:not(:first-child), .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  > .btn {
    position: relative;
    + .btn {
      margin-left: -1px;
    }
    &:hover, &:focus, &:active {
      z-index: 2;
    }
  }
  &:first-child > {
    .btn, .btn-group {
      margin-right: -1px;
    }
  }
  &:last-child > {
    .btn, .btn-group {
      z-index: 2;
      margin-left: -1px;
    }
  }
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  // list-style: none;
  > li {
    position: relative;
    display: block;
    > a {
      position: relative;
      display: block;
      padding: 10px 15px;
      &:hover, &:focus {
        text-decoration: none;
        background-color: #eee;
      }
    }
    &.disabled > a {
      color: #777;
      &:hover, &:focus {
        color: #777;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
      }
    }
  }
  .open > a {
    background-color: #eee;
    border-color: #337ab7;
    &:hover, &:focus {
      background-color: #eee;
      border-color: #337ab7;
    }
  }
  .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }
  > li > a > img {
    max-width: none;
  }
}

.nav-tabs {
  // border-bottom: 1px solid #ddd;
  > li {
    float: left;
    // margin-bottom: -1px;
    > a {
      // margin-right: 2px;
      line-height: 1.42857143;
      // border: 1px solid transparent;
      // border-radius: 4px 4px 0 0;
      // &:hover {
      //   border-color: #eee #eee #ddd;
      // }
    }
    &.active > a {
      // color: #555;
      // cursor: default;
      // background-color: #fff;
      // border: 1px solid #ddd;
      // border-bottom-color: transparent;
      &:hover, &:focus {
        // color: #555;
        cursor: default;
        // background-color: #fff;
        // border: 1px solid #ddd;
        // border-bottom-color: transparent;
      }
    }
  }
  &.nav-justified {
    width: 100%;
    border-bottom: 0;
    > {
      li {
        float: none;
        > a {
          margin-bottom: 5px;
          text-align: center;
        }
      }
      .dropdown .dropdown-menu {
        top: auto;
        left: auto;
      }
      // li > a {
      //   margin-right: 0;
      //   border-radius: 4px;
      // }
      .active > a {
        border: 1px solid #ddd;
        &:hover, &:focus {
          border: 1px solid #ddd;
        }
      }
    }
    // @media (min-width: 768px) {
      & > li {
        display: table-cell;
        width: 1%;
        > a {
          margin-bottom: 0;
        }
      }
      & > {
        li > a {
          border-bottom: 1px solid #ddd;
          border-radius: 4px 4px 0 0;
        }
        .active > a {
          border-bottom-color: #fff;
          &:hover, &:focus {
            border-bottom-color: #fff;
          }
        }
      }
    // }
  }
}

.nav-pills > li {
  float: left;
  > a {
    border-radius: 4px;
  }
  + li {
    margin-left: 2px;
  }
  &.active > a {
    color: #fff;
    background-color: #337ab7;
    &:hover, &:focus {
      color: #fff;
      background-color: #337ab7;
    }
  }
}

.nav-stacked > li {
  float: none;
  + li {
    margin-top: 2px;
    margin-left: 0;
  }
}

// .nav-justified {
//   // width: 100%;
//   > {
//     li {
//       // float: none;
//       > a {
//         // margin-bottom: 5px;
//         // text-align: center;
//       }
//     }
//     .dropdown .dropdown-menu {
//       top: auto;
//       left: auto;
//     }
//   }
// }

// @media (min-width: 768px) {
//   .nav-justified > li {
//     display: table-cell;
//     width: 1%;
//     > a {
//       margin-bottom: 0;
//     }
//   }
// }

/*
// abuiでは使用してない為コメントアウト
.nav-tabs-justified {
  border-bottom: 0;
  > {
    li > a {
      margin-right: 0;
      border-radius: 4px;
    }
    .active > a {
      border: 1px solid #ddd;
      &:hover, &:focus {
        border: 1px solid #ddd;
      }
    }
  }
  @media (min-width: 768px) {
    & > {
      li > a {
        border-bottom: 1px solid #ddd;
        border-radius: 4px 4px 0 0;
      }
      .active > a {
        border-bottom-color: #fff;
        &:hover, &:focus {
          border-bottom-color: #fff;
        }
      }
    }
  }
}
*/


.tab-content > {
  .tab-pane {
    display: none;
  }
  .active {
    display: block;
  }
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

// @media (min-width: 768px) {
//   .navbar {
//     border-radius: 4px;
//   }
// }

// @media (min-width: 768px) {
//   .navbar-header {
//     float: left;
//   }
// }

// .navbar-collapse {
  // padding-right: 15px;
  // padding-left: 15px;
  // overflow-x: visible;
  // -webkit-overflow-scrolling: touch;
  // border-top: 1px solid transparent;
  // -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  // box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  // &.in {
  //   overflow-y: auto;
  // }
// }

@media (min-width: 992px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
    &.collapse {
      display: block;
      height: auto;
      padding-bottom: 0;
      overflow: visible;
    }
    &.in {
      overflow-y: visible;
    }
  }
  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}

// .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
//   margin-right: -15px;
//   margin-left: -15px;
// }

// @media (min-width: 768px) {
//   .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
//     margin-right: 0;
//     margin-left: 0;
//   }
// }

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
  @media (min-width: 768px) {
    .navbar-static-top {
      border-radius: 0;
    }
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  @media (min-width: 768px) {
    & {
      border-radius: 0;
    }
  }
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}

.navbar-fixed-bottom {
  bottom: 0;
  // margin-bottom: 0;
  // border-width: 1px 0 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  &:hover, &:focus {
    text-decoration: none;
  }
  > img {
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar > {
    .container .navbar-brand, .container-fluid .navbar-brand {
      margin-left: -15px;
    }
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  // padding: 9px 10px;
  // margin-top: 8px;
  // margin-right: 15px;
  // margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  // border: 1px solid transparent;
  border-radius: 4px;
  // &:focus {
  //   outline: 0;
  // }
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    + .icon-bar {
      margin-top: 4px;
    }
  }
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  // margin: 7.5px -15px;
  > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
  }
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    > li > a, .dropdown-header {
      padding: 5px 15px 5px 25px;
    }
    > li > a {
      line-height: 20px;
      &:hover, &:focus {
        background-image: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
    > li {
      float: left;
      > a {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

// .navbar-form {
//   padding: 10px 15px;
//   margin-top: 8px;
//   margin-right: -15px;
//   margin-bottom: 8px;
//   margin-left: -15px;
//   border-top: 1px solid transparent;
//   border-bottom: 1px solid transparent;
//   -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
//   box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
// }

@media (min-width: 768px) {
  .navbar-form {
    .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-control-static {
      display: inline-block;
    }
    .input-group {
      display: inline-table;
      vertical-align: middle;
      .input-group-addon, .input-group-btn, .form-control {
        width: auto;
      }
      > .form-control {
        width: 100%;
      }
    }
    .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }
    .radio, .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .radio label, .checkbox label {
      padding-left: 0;
    }
    .radio input[type="radio"], .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
    }
    .has-feedback .form-control-feedback {
      top: 0;
    }
  }
}

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  .navbar-form {
    // width: auto;
    // padding-top: 0;
    // padding-bottom: 0;
    // margin-right: 0;
    margin-left: 0;
    border: 0;
    // -webkit-box-shadow: none;
    // box-shadow: none;
  }
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  // margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  // border-bottom-right-radius: 0;
  // border-bottom-left-radius: 0;
}

// .navbar-btn {
  // margin-top: 8px;
  // margin-bottom: 8px;
  // &.btn-sm {
    // margin-top: 10px;
    // margin-bottom: 10px;
  // }
//   &.btn-xs {
//     margin-top: 14px;
//     margin-bottom: 14px;
//   }
// }

.navbar-text {
  // margin-top: 15px;
  // margin-bottom: 15px;
  @media (min-width: 768px) {
    & {
      float: left;
      // margin-right: 15px;
      // margin-left: 15px;
    }
  }
}

@media (min-width: 768px) {
  .navbar-left {
    float: left !important;
  }
  .navbar-right {
    float: right !important;
    // margin-right: -15px;
    ~ .navbar-right {
      // margin-right: 0;
    }
  }
}

.navbar-default {
  // background-color: #f8f8f8;
  // border-color: #e7e7e7;
  .navbar-brand {
    color: #777;
    &:hover, &:focus {
      color: #5e5e5e;
      background-color: transparent;
    }
  }
  // .navbar-text {
  //   color: #777;
  // }
  // .navbar-nav > {
    // li > a {
    //   color: #777;
    //   &:hover, &:focus {
    //     color: #333;
    //     background-color: transparent;
    //   }
    // }
    // .active > a {
    //   // color: #555;
    //   // background-color: #e7e7e7;
    //   &:hover, &:focus {
    //     color: #555;
    //     background-color: #e7e7e7;
    //   }
    // }
  //   .disabled > a {
  //     color: #ccc;
  //     background-color: transparent;
  //     &:hover, &:focus {
  //       color: #ccc;
  //       background-color: transparent;
  //     }
  //   }
  // }
  .navbar-toggle {
    border-color: #ddd;
    &:hover, &:focus {
      background-color: #ddd;
    }
    .icon-bar {
      background-color: #888;
    }
  }
  // .navbar-collapse, .navbar-form {
  //   border-color: #e7e7e7;
  // }
  // .navbar-nav > .open > a {
  //   color: #555;
  //   background-color: #e7e7e7;
  //   &:hover, &:focus {
  //     color: #555;
  //     background-color: #e7e7e7;
  //   }
  // }
  // .navbar-link {
  //   color: #777;
  //   &:hover {
  //     color: #333;
  //   }
  // }
  .btn-link {
    // color: #777;
    // &:hover, &:focus {
    //   color: #333;
    // }
    &[disabled]:hover {
      color: #ccc;
    }
  }
}

// @media (max-width: 767px) {
//   .navbar-default .navbar-nav .open .dropdown-menu > {
    // li > a {
    //   color: #777;
    //   &:hover, &:focus {
    //     color: #333;
    //     background-color: transparent;
    //   }
    // }
    // .active > a {
    //   color: #555;
    //   background-color: #e7e7e7;
    //   &:hover, &:focus {
    //     color: #555;
    //     background-color: #e7e7e7;
    //   }
    // }
//     .disabled > a {
//       color: #ccc;
//       background-color: transparent;
//       &:hover, &:focus {
//         color: #ccc;
//         background-color: transparent;
//       }
//     }
//   }
// }

fieldset[disabled] .navbar-default .btn-link:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  // background-color: #222;
  // border-color: #080808;
  .navbar-brand {
    color: #9d9d9d;
    &:hover, &:focus {
      color: #fff;
      background-color: transparent;
    }
  }
  // .navbar-text {
  //   color: #9d9d9d;
  // }
  // .navbar-nav > {
  //   li > a {
  //     color: #9d9d9d;
  //     &:hover, &:focus {
  //       color: #fff;
  //       background-color: transparent;
  //     }
  //   }
  //   .active > a {
  //     color: #fff;
  //     background-color: #080808;
  //     &:hover, &:focus {
  //       color: #fff;
  //       background-color: #080808;
  //     }
  //   }
  //   .disabled > a {
  //     color: #444;
  //     background-color: transparent;
  //     &:hover, &:focus {
  //       color: #444;
  //       background-color: transparent;
  //     }
  //   }
  // }
  .navbar-toggle {
    border-color: #333;
    &:hover, &:focus {
      background-color: #333;
    }
    .icon-bar {
      background-color: #fff;
    }
  }
  // .navbar-collapse, .navbar-form {
  //   border-color: #101010;
  // }
  // .navbar-nav > .open > a {
  //   color: #fff;
  //   background-color: #080808;
  //   &:hover, &:focus {
  //     color: #fff;
  //     background-color: #080808;
  //   }
  // }
  // .navbar-link {
  //   color: #9d9d9d;
  //   &:hover {
  //     color: #fff;
  //   }
  // }
  .btn-link {
    // color: #9d9d9d;
    // &:hover, &:focus {
    //   color: #fff;
    // }
    &[disabled]:hover {
      color: #444;
    }
  }
}

// @media (max-width: 767px) {
//   .navbar-inverse .navbar-nav .open .dropdown-menu {
//     > .dropdown-header {
//       border-color: #080808;
//     }
//     .divider {
//       background-color: #080808;
//     }
//     > {
//       li > a {
//         color: #9d9d9d;
//         &:hover, &:focus {
//           color: #fff;
//           background-color: transparent;
//         }
//       }
//       .active > a {
//         color: #fff;
//         background-color: #080808;
//         &:hover, &:focus {
//           color: #fff;
//           background-color: #080808;
//         }
//       }
//       .disabled > a {
//         color: #444;
//         background-color: transparent;
//         &:hover, &:focus {
//           color: #444;
//           background-color: transparent;
//         }
//       }
//     }
//   }
// }

fieldset[disabled] .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

// .breadcrumb {
//   // padding: 8px 15px;
//   // margin-bottom: 20px;
//   // list-style: none;
//   // background-color: #f5f5f5;
//   // border-radius: 4px;
//   > {
//     li {
//       display: inline-block;
//       + li::before {
//         padding: 0 5px;
//         color: #ccc;
//         content: "/\00a0";
//       }
//     }
//     .active {
//       color: #777;
//     }
//   }
// }

// .pagination {
//   // display: inline-block;
//   // padding-left: 0;
//   // margin: 20px 0;
//   // border-radius: 4px;
//   > {
//     li {
//       display: inline;
//       > {
//         a, span {
//           position: relative;
//           float: left;
//           padding: 6px 12px;
//           margin-left: -1px;
//           line-height: 1.42857143;
//           color: #337ab7;
//           text-decoration: none;
//           background-color: #fff;
//           border: 1px solid #ddd;
//         }
//       }
//       &:first-child > {
//         a, span {
//           margin-left: 0;
//           border-top-left-radius: 4px;
//           border-bottom-left-radius: 4px;
//         }
//       }
//       &:last-child > {
//         a, span {
//           border-top-right-radius: 4px;
//           border-bottom-right-radius: 4px;
//         }
//       }
//       > {
//         a:hover, span:hover, a:focus, span:focus {
//           z-index: 2;
//           color: #23527c;
//           background-color: #eee;
//           border-color: #ddd;
//         }
//       }
//     }
//     .active > {
//       a, span, a:hover, span:hover, a:focus, span:focus {
//         z-index: 3;
//         color: #fff;
//         cursor: default;
//         background-color: #337ab7;
//         border-color: #337ab7;
//       }
//     }
//     .disabled > {
//       span {
//         color: #777;
//         cursor: not-allowed;
//         background-color: #fff;
//         border-color: #ddd;
//         &:hover, &:focus {
//           color: #777;
//           cursor: not-allowed;
//           background-color: #fff;
//           border-color: #ddd;
//         }
//       }
//       a {
//         color: #777;
//         cursor: not-allowed;
//         background-color: #fff;
//         border-color: #ddd;
//         &:hover, &:focus {
//           color: #777;
//           cursor: not-allowed;
//           background-color: #fff;
//           border-color: #ddd;
//         }
//       }
//     }
//   }
// }

/*
// abuiでは使用してない為コメントアウト
.pagination-lg > li {
  > {
    a, span {
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.3333333;
    }
  }
  &:first-child > {
    a, span {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
  &:last-child > {
    a, span {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.pagination-sm > li {
  > {
    a, span {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
    }
  }
  &:first-child > {
    a, span {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  &:last-child > {
    a, span {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
*/


// .pager {
  // padding-left: 0;
  // margin: 20px 0;
  // text-align: center;
  // list-style: none;
  // li {
    // display: inline;
    // > {
    //   a, span {
        // display: inline-block;
        // padding: 5px 14px;
        // background-color: #fff;
        // border: 1px solid #ddd;
        // border-radius: 15px;
    //   }
    //   a {
    //     &:hover, &:focus {
    //       text-decoration: none;
    //       background-color: #eee;
    //     }
    //   }
    // }
  // }
  // .next > {
  //   a, span {
  //     float: right;
  //   }
  // }
  // .previous > {
  //   a, span {
  //     float: left;
  //   }
  // }
  // .disabled > {
  //   a {
  //     color: #777;
  //     cursor: not-allowed;
  //     background-color: #fff;
  //     &:hover, &:focus {
  //       color: #777;
  //       cursor: not-allowed;
  //       background-color: #fff;
  //     }
  //   }
  //   span {
  //     color: #777;
  //     cursor: not-allowed;
  //     background-color: #fff;
  //   }
  // }
// }

// .label {
//   display: inline;
//   padding: .2em .6em .3em;
//   font-size: 75%;
//   font-weight: bold;
//   line-height: 1;
//   color: #fff;
//   text-align: center;
//   white-space: nowrap;
//   vertical-align: baseline;
//   border-radius: .25em;
// }

// a.label {
//   &:hover, &:focus {
//     color: #fff;
//     text-decoration: none;
//     cursor: pointer;
//   }
// }

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  // background-color: #777;
  &[href] {
    &:hover, &:focus {
      background-color: #5e5e5e;
    }
  }
}

.label-primary {
  // background-color: #337ab7;
  &[href] {
    &:hover, &:focus {
      background-color: #286090;
    }
  }
}

.label-success {
  // background-color: #5cb85c;
  &[href] {
    &:hover, &:focus {
      background-color: #449d44;
    }
  }
}

.label-info {
  // background-color: #5bc0de;
  &[href] {
    &:hover, &:focus {
      background-color: #31b0d5;
    }
  }
}

.label-warning {
  // background-color: #f0ad4e;
  &[href] {
    &:hover, &:focus {
      background-color: #ec971f;
    }
  }
}

.label-danger {
  // background-color: #d9534f;
  &[href] {
    &:hover, &:focus {
      background-color: #c9302c;
    }
  }
}

/*
// abuiでは使用してない為コメントアウト
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
  &:empty {
    display: none;
  }
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn-xs .badge, .btn-group-xs > .btn .badge {
  top: 0;
  padding: 1px 5px;
}

a.badge {
  &:hover, &:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
}

.list-group-item.active > .badge, .nav-pills > .active > a > .badge {
  color: #337ab7;
  background-color: #fff;
}

.list-group-item > .badge {
  float: right;
  + .badge {
    margin-right: 5px;
  }
}

.nav-pills > li > a > .badge {
  margin-left: 3px;
}
*/

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee;
  h1, .h1 {
    color: inherit;
  }
  p {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 200;
  }
  > hr {
    border-top-color: #d5d5d5;
  }
}

.container .jumbotron, .container-fluid .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px;
}

.jumbotron .container {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .container .jumbotron, .container-fluid .jumbotron {
    padding-right: 60px;
    padding-left: 60px;
  }
  .jumbotron {
    h1, .h1 {
      font-size: 63px;
    }
  }
}

// .thumbnail {
//   // display: block;
//   // padding: 4px;
//   // margin-bottom: 20px;
//   // line-height: 1.42857143;
//   // background-color: #fff;
//   // border: 1px solid #ddd;
//   // border-radius: 4px;
//   // -webkit-transition: border .2s ease-in-out;
//   // -o-transition: border .2s ease-in-out;
//   // transition: border .2s ease-in-out;
//   > img, a > img {
//     display: block;
//     max-width: 100%;
//     height: auto;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }


// .alert {
  // padding: 15px;
  // margin-bottom: 20px;
  // border: 1px solid transparent;
  // border-radius: 4px;
  // h4 {
  //   margin-top: 0;
  //   color: inherit;
  // }
  // .alert-link {
  //   font-weight: bold;
  // }
//   > {
//     p, ul {
//       margin-bottom: 0;
//     }
//     p + p {
//       margin-top: 5px;
//     }
//   }
// }

// .alert-dismissable, .alert-dismissible {
//   padding-right: 35px;
// }

// .alert-dismissable .close, .alert-dismissible .close {
//   position: relative;
//   top: -2px;
//   right: -21px;
//   color: inherit;
// }
/*
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  hr {
    border-top-color: #c9e2b3;
  }
  .alert-link {
    color: #2b542c;
  }
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  hr {
    border-top-color: #a6e1ec;
  }
  .alert-link {
    color: #245269;
  }
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  hr {
    border-top-color: #f7e1b5;
  }
  .alert-link {
    color: #66512c;
  }
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  hr {
    border-top-color: #e4b9c0;
  }
  .alert-link {
    color: #843534;
  }
}
*/

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}


@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}


@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}


// .progress {
//   height: 20px;
//   margin-bottom: 20px;
//   overflow: hidden;
//   background-color: #f5f5f5;
//   border-radius: 4px;
//   -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
//   box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
// }

// .progress-bar {
  // float: left;
  // width: 0;
  // height: 100%;
  // font-size: 12px;
  // line-height: 20px;
  // color: #fff;
  // text-align: center;
  // background-color: #337ab7;
  // -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  // -webkit-transition: width .6s ease;
  // -o-transition: width .6s ease;
  // transition: width .6s ease;
// }

/*
// abuiでは使用してない為 コメントアウト
.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress.active .progress-bar, .progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
*/

.media {
  margin-top: 15px;
  &:first-child {
    margin-top: 0;
  }
  overflow: hidden;
  zoom: 1;
}

.media-body {
  overflow: hidden;
  zoom: 1;
  width: 10000px;
}

.media-object {
  display: block;
  &.img-thumbnail {
    max-width: none;
  }
}

.media-right, .media > .pull-right {
  padding-left: 10px;
}

.media-left, .media > .pull-left {
  padding-right: 10px;
}

.media-left, .media-right, .media-body {
  display: table-cell;
  vertical-align: top;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

a.list-group-item, button.list-group-item {
  color: #555;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #333;
}

a.list-group-item:hover, button.list-group-item:hover, a.list-group-item:focus {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5;
}

button.list-group-item {
  &:focus {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5;
  }
  width: 100%;
  text-align: left;
}

.list-group-item {
  &.disabled {
    color: #777;
    cursor: not-allowed;
    background-color: #eee;
    &:hover, &:focus {
      color: #777;
      cursor: not-allowed;
      background-color: #eee;
    }
    .list-group-item-heading, &:hover .list-group-item-heading, &:focus .list-group-item-heading {
      color: inherit;
    }
    .list-group-item-text, &:hover .list-group-item-text, &:focus .list-group-item-text {
      color: #777;
    }
  }
  &.active {
    z-index: 2;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    &:hover, &:focus {
      z-index: 2;
      color: #fff;
      background-color: #337ab7;
      border-color: #337ab7;
    }
    .list-group-item-heading, &:hover .list-group-item-heading, &:focus .list-group-item-heading, .list-group-item-heading > small, &:hover .list-group-item-heading > small, &:focus .list-group-item-heading > small, .list-group-item-heading > .small, &:hover .list-group-item-heading > .small, &:focus .list-group-item-heading > .small {
      color: inherit;
    }
    .list-group-item-text, &:hover .list-group-item-text, &:focus .list-group-item-text {
      color: #c7ddef;
    }
  }
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success, button.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:hover, button.list-group-item-success:hover, a.list-group-item-success:focus, button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active, button.list-group-item-success.active, a.list-group-item-success.active:hover, button.list-group-item-success.active:hover, a.list-group-item-success.active:focus, button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info, button.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:hover, button.list-group-item-info:hover, a.list-group-item-info:focus, button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active, button.list-group-item-info.active, a.list-group-item-info.active:hover, button.list-group-item-info.active:hover, a.list-group-item-info.active:focus, button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:hover, button.list-group-item-warning:hover, a.list-group-item-warning:focus, button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active, button.list-group-item-warning.active, a.list-group-item-warning.active:hover, button.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus, button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:hover, button.list-group-item-danger:hover, a.list-group-item-danger:focus, button.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active, button.list-group-item-danger.active, a.list-group-item-danger.active:hover, button.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus, button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

// .panel {
  // margin-bottom: 20px;
  // background-color: #fff;
  // border: 1px solid transparent;
  // border-radius: 4px;
  // -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
// }

// .panel-body {
//   padding: 15px;
// }

// .panel-heading {
  // padding: 10px 15px;
  // border-bottom: 1px solid transparent;
  // border-top-left-radius: 3px;
  // border-top-right-radius: 3px;
//   > .dropdown .dropdown-toggle {
//     color: inherit;
//   }
// }

// .panel-title {
  // margin-top: 0;
  // margin-bottom: 0;
  // font-size: 16px;
//   color: inherit;
//   > {
//     a, small, .small, small > a, .small > a {
//       color: inherit;
//     }
//   }
// }

// .panel-footer {
//   padding: 10px 15px;
//   background-color: #f5f5f5;
//   border-top: 1px solid #ddd;
//   border-bottom-right-radius: 3px;
//   border-bottom-left-radius: 3px;
// }

.panel > {
  .list-group, .panel-collapse > .list-group {
    margin-bottom: 0;
  }
  .list-group .list-group-item, .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
  }
  .list-group:first-child .list-group-item:first-child, .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .list-group:last-child .list-group-item:last-child, .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.panel-heading + .list-group .list-group-item:first-child, .list-group + .panel-footer {
  border-top-width: 0;
}

.panel > {
  .table, .table-responsive > .table, .panel-collapse > .table {
    margin-bottom: 0;
  }
  .table caption, .table-responsive > .table caption, .panel-collapse > .table caption {
    padding-right: 15px;
    padding-left: 15px;
  }
  .table:first-child, .table-responsive:first-child > .table:first-child, .table:first-child > thead:first-child > tr:first-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .table:first-child > tbody:first-child > tr:first-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .table:first-child > thead:first-child > tr:first-child td:first-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .table:first-child > tbody:first-child > tr:first-child td:first-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .table:first-child > thead:first-child > tr:first-child th:first-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .table:first-child > tbody:first-child > tr:first-child th:first-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
    border-top-left-radius: 3px;
  }
  .table:first-child > thead:first-child > tr:first-child td:last-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .table:first-child > tbody:first-child > tr:first-child td:last-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .table:first-child > thead:first-child > tr:first-child th:last-child, .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .table:first-child > tbody:first-child > tr:first-child th:last-child, .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
    border-top-right-radius: 3px;
  }
  .table:last-child, .table-responsive:last-child > .table:last-child, .table:last-child > tbody:last-child > tr:last-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .table:last-child > tfoot:last-child > tr:last-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .table:last-child > tbody:last-child > tr:last-child td:first-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .table:last-child > tfoot:last-child > tr:last-child td:first-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .table:last-child > tbody:last-child > tr:last-child th:first-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .table:last-child > tfoot:last-child > tr:last-child th:first-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
  }
  .table:last-child > tbody:last-child > tr:last-child td:last-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .table:last-child > tfoot:last-child > tr:last-child td:last-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .table:last-child > tbody:last-child > tr:last-child th:last-child, .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .table:last-child > tfoot:last-child > tr:last-child th:last-child, .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
  }
  .panel-body + {
    .table, .table-responsive {
      border-top: 1px solid #ddd;
    }
  }
  .table + .panel-body, .table-responsive + .panel-body {
    border-top: 1px solid #ddd;
  }
  .table > tbody:first-child > tr:first-child {
    th, td {
      border-top: 0;
    }
  }
  .table-bordered, .table-responsive > .table-bordered {
    border: 0;
  }
  .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > th:first-child, .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-bordered > tfoot > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > th:last-child, .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-bordered > tfoot > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-bordered > thead > tr:first-child > td, .table-responsive > .table-bordered > thead > tr:first-child > td, .table-bordered > tbody > tr:first-child > td, .table-responsive > .table-bordered > tbody > tr:first-child > td, .table-bordered > thead > tr:first-child > th, .table-responsive > .table-bordered > thead > tr:first-child > th, .table-bordered > tbody > tr:first-child > th, .table-responsive > .table-bordered > tbody > tr:first-child > th, .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-bordered > tfoot > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > td, .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
  }
  .table-responsive {
    > .table-bordered > tfoot > tr:last-child > th {
      border-bottom: 0;
    }
    margin-bottom: 0;
    border: 0;
  }
}

.panel-group {
  margin-bottom: 20px;
  .panel {
    margin-bottom: 0;
    border-radius: 4px;
    + .panel {
      margin-top: 5px;
    }
  }
  .panel-heading {
    border-bottom: 0;
    + .panel-collapse > {
      .panel-body, .list-group {
        border-top: 1px solid #ddd;
      }
    }
  }
  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid #ddd;
    }
  }
}

.panel-default {
  // border-color: #ddd;
  > {
    .panel-heading {
      // color: #333;
      // background-color: #f5f5f5;
      // border-color: #ddd;
      + .panel-collapse > .panel-body {
        border-top-color: #ddd;
      }
      .badge {
        color: #f5f5f5;
        background-color: #333;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #ddd;
    }
  }
}

/*
// abuiでは使用してない為コメントアウト
.panel-primary {
  border-color: #337ab7;
  > {
    .panel-heading {
      color: #fff;
      background-color: #337ab7;
      border-color: #337ab7;
      + .panel-collapse > .panel-body {
        border-top-color: #337ab7;
      }
      .badge {
        color: #337ab7;
        background-color: #fff;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #337ab7;
    }
  }
}

.panel-success {
  border-color: #d6e9c6;
  > {
    .panel-heading {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
      + .panel-collapse > .panel-body {
        border-top-color: #d6e9c6;
      }
      .badge {
        color: #dff0d8;
        background-color: #3c763d;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #d6e9c6;
    }
  }
}

.panel-info {
  border-color: #bce8f1;
  > {
    .panel-heading {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
      + .panel-collapse > .panel-body {
        border-top-color: #bce8f1;
      }
      .badge {
        color: #d9edf7;
        background-color: #31708f;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #bce8f1;
    }
  }
}

.panel-warning {
  border-color: #faebcc;
  > {
    .panel-heading {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
      + .panel-collapse > .panel-body {
        border-top-color: #faebcc;
      }
      .badge {
        color: #fcf8e3;
        background-color: #8a6d3b;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #faebcc;
    }
  }
}

.panel-danger {
  border-color: #ebccd1;
  > {
    .panel-heading {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      + .panel-collapse > .panel-body {
        border-top-color: #ebccd1;
      }
      .badge {
        color: #f2dede;
        background-color: #a94442;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #ebccd1;
    }
  }
}
*/

/*
// abuiでは使用してない為コメントアウト
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  .embed-responsive-item, iframe, embed, object, video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
  }
}

.well-lg {
  padding: 24px;
  border-radius: 6px;
}

.well-sm {
  padding: 9px;
  border-radius: 3px;
}
*/


.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity = 20);
  opacity: .2;
  &:hover, &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity = 50);
    opacity: .5;
  }
}

button.close {
  appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  // position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // z-index: 1050;
  // display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  &.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -25%);
  }
  &.in .modal-dialog {
    transform: translate(0, 0);
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  // border: 1px solid #999;
  // border: 1px solid rgba(0, 0, 0, 0.2);
  // border-radius: 6px;
  outline: 0;
  // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  &.fade {
    filter: alpha(opacity = 0);
    opacity: 0;
  }
  // &.in {
  //   filter: alpha(opacity = 50);
  //   opacity: .5;
  // }
}

// .modal-header {
  // padding: 15px;
  // border-bottom: 1px solid #e5e5e5;
//   .close {
//     margin-top: -2px;
//   }
// }

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  // padding: 15px;
}

.modal-footer {
  // padding: 15px;
  // text-align: right;
  // border-top: 1px solid #e5e5e5;
  .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
  }
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// @media (min-width: 768px) {
  // .modal-dialog {
  //   width: 600px;
  //   margin: 30px auto;
  // }
  // .modal-content {
  //   -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  //   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  // }
//   .modal-sm {
//     width: 300px;
//   }
// }

// @media (min-width: 992px) {
//   .modal-lg {
//     width: 900px;
//   }
// }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity = 0);
  opacity: 0;
  line-break: auto;
  &.in {
    filter: alpha(opacity = 90);
    opacity: .9;
  }
  &.top {
    padding: 5px 0;
    margin-top: -3px;
  }
  &.right {
    padding: 0 5px;
    margin-left: 3px;
  }
  &.bottom {
    padding: 5px 0;
    margin-top: 3px;
  }
  &.left {
    padding: 0 5px;
    margin-left: -3px;
  }
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip {
  // &.top .tooltip-arrow {
  //   bottom: 0;
  //   left: 50%;
  //   margin-left: -5px;
  //   border-width: 5px 5px 0;
  //   border-top-color: #000;
  // }
  &.top-left .tooltip-arrow {
    right: 5px;
    bottom: 0;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }
  // &.right .tooltip-arrow {
  //   top: 50%;
  //   left: 0;
  //   margin-top: -5px;
  //   border-width: 5px 5px 5px 0;
  //   border-right-color: #000;
  // }
  // &.left .tooltip-arrow {
  //   top: 50%;
  //   right: 0;
  //   margin-top: -5px;
  //   border-width: 5px 0 5px 5px;
  //   border-left-color: #000;
  // }
  // &.bottom .tooltip-arrow {
  //   top: 0;
  //   left: 50%;
  //   margin-left: -5px;
  //   border-width: 0 5px 5px;
  //   border-bottom-color: #000;
  // }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
  }
}

/*
// abuiでは使用してない為コメントアウト
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
  &.top {
    margin-top: -10px;
  }
  &.right {
    margin-left: 10px;
  }
  &.bottom {
    margin-top: 10px;
  }
  &.left {
    margin-left: -10px;
  }
}

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

.popover {
  > .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    &::after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: "";
      border-width: 10px;
    }
    border-width: 11px;
  }
  &.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;
    &::after {
      bottom: 1px;
      margin-left: -10px;
      content: " ";
      border-top-color: #fff;
      border-bottom-width: 0;
    }
  }
  &.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;
    &::after {
      bottom: -10px;
      left: 1px;
      content: " ";
      border-right-color: #fff;
      border-left-width: 0;
    }
  }
  &.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    &::after {
      top: 1px;
      margin-left: -10px;
      content: " ";
      border-top-width: 0;
      border-bottom-color: #fff;
    }
  }
  &.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25);
    &::after {
      right: 1px;
      bottom: -10px;
      content: " ";
      border-right-width: 0;
      border-left-color: #fff;
    }
  }
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  > {
    .item {
      position: relative;
      display: none;
      -webkit-transition: .6s ease-in-out left;
      -o-transition: .6s ease-in-out left;
      transition: .6s ease-in-out left;
      > {
        img, a > img {
          line-height: 1;
        }
      }
    }
    .active, .next, .prev {
      display: block;
    }
    .active {
      left: 0;
    }
    .next, .prev {
      position: absolute;
      top: 0;
      width: 100%;
    }
    .next {
      left: 100%;
    }
    .prev {
      left: -100%;
    }
    .next.left, .prev.right {
      left: 0;
    }
    .active {
      &.left {
        left: -100%;
      }
      &.right {
        left: 100%;
      }
    }
  }
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    &.next, &.active.right {
      left: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
    &.prev, &.active.left {
      left: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    &.next.left, &.prev.right, &.active {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity = 50);
  opacity: .5;
  &.left {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
    background-repeat: repeat-x;
  }
  &.right {
    right: 0;
    left: auto;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
  }
  &:hover, &:focus {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity = 90);
    outline: 0;
    opacity: .9;
  }
  .icon-prev, .icon-next, .glyphicon-chevron-left, .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
  }
  .icon-prev, .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px;
  }
  .icon-next, .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px;
  }
  .icon-prev, .icon-next {
    width: 20px;
    height: 20px;
    font-family: serif;
    line-height: 1;
  }
  .icon-prev::before {
    content: '\2039';
  }
  .icon-next::before {
    content: '\203a';
  }
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
  li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fff;
    border-radius: 10px;
  }
  .active {
    width: 12px;
    height: 12px;
    margin: 0;
    background-color: #fff;
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  .btn {
    text-shadow: none;
  }
}

@media screen and (min-width: 768px) {
  .carousel-control {
    .glyphicon-chevron-left, .glyphicon-chevron-right, .icon-prev, .icon-next {
      width: 30px;
      height: 30px;
      margin-top: -10px;
      font-size: 30px;
    }
    .glyphicon-chevron-left, .icon-prev {
      margin-left: -10px;
    }
    .glyphicon-chevron-right, .icon-next {
      margin-right: -10px;
    }
  }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}
*/

// .clearfix {
//   &::before, &::after {
//     display: table;
//     content: " ";
//   }
// }

%base-pseudo-elem {
  &::before, &::after {
    display: table;
    content: " ";
  }
}

.dl-horizontal dd {
  @extend %base-pseudo-elem;
}

.container {
  @extend %base-pseudo-elem;
}

.container-fluid {
  @extend %base-pseudo-elem;
}

.row {
  @extend %base-pseudo-elem;
}

.form-horizontal .form-group {
  @extend %base-pseudo-elem;
}

.btn-toolbar {
  @extend %base-pseudo-elem;
}

.btn-group-vertical > .btn-group {
  @extend %base-pseudo-elem;
}

.nav {
  @extend %base-pseudo-elem;
}

.navbar {
  @extend %base-pseudo-elem;
}

.navbar-header {
  @extend %base-pseudo-elem;
}

.navbar-collapse {
  @extend %base-pseudo-elem;
}

.pager {
  @extend %base-pseudo-elem;
}

.panel-body {
  @extend %base-pseudo-elem;
}

.modal-header {
  @extend %base-pseudo-elem;
}

.modal-footer {
  @extend %base-pseudo-elem;
}

// .clearfix::after,
.dl-horizontal dd::after, .container::after, .container-fluid::after, .row::after, .form-horizontal .form-group::after, .btn-toolbar::after, .btn-group-vertical > .btn-group::after, .nav::after, .navbar::after, .navbar-header::after, .navbar-collapse::after, .pager::after, .panel-body::after, .modal-header::after, .modal-footer::after {
  clear: both;
}


.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}


.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block, .visible-print, .visible-print-block, .visible-print-inline, .visible-print-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs, td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm, td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md, td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg, td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print, td.visible-print {
    display: table-cell !important;
  }
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}



/*
Loading Bootstrap
bootstrap - prettify.css
----------------------------------------------------------------
*/

.com {
  color: #93a1a1;
}

.lit {
  color: #195f91;
}

.pun, .opn, .clo {
  color: #93a1a1;
}

.fun {
  color: #dc322f;
}

.str, .atv {
  color: #D14;
}

.kwd, .prettyprint .tag {
  color: #1e347b;
}

.typ, .atn, .dec, .var {
  color: teal;
}

.pln {
  color: #48484c;
}

.prettyprint {
  padding: 8px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  &.linenums {
    -webkit-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
    -moz-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
    box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
  }
}

ol.linenums {
  margin: 0 0 0 33px;
  li {
    padding-left: 12px;
    color: #bebec5;
    line-height: 20px;
    text-shadow: 0 1px 0 #fff;
  }
}
