@charset "UTF-8";
/*
Glyphicon
----------------------------------------------------------------
*/


$icon-font-path:            "../fonts/";
$icon-font-name:            "abui-icons-regular";
$icon-font-svg-id:          "abui-icons-regular";


@font-face {
  font-family: 'ABUI-icons';
  src: url($icon-font-path + $icon-font-name + '.eot');
  src: url($icon-font-path + $icon-font-name + '.eot?#iefix') format('embedded-opentype'),
       url($icon-font-path + $icon-font-name + '.woff') format('woff'),
       url($icon-font-path + $icon-font-name + '.ttf') format('truetype'),
       // url($icon-font-path + $icon-font-name + '.svg#' + $icon-font-svg-id) format('svg');
       url($icon-font-path + $icon-font-name + '.svg#') format('svg');
  font-weight: normal;
  font-style: normal;
}



/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: 'ABUI-icons';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-abui-"] {
*/
// .icon-abui-arrow-right, .icon-abui-arrow-left, .icon-abui-cmd, .icon-abui-check-inverted, .icon-abui-heart, .icon-abui-location, .icon-abui-plus, .icon-abui-check, .icon-abui-cross, .icon-abui-list, .icon-abui-new, .icon-abui-video, .icon-abui-photo, .icon-abui-volume, .icon-abui-time, .icon-abui-eye, .icon-abui-chat, .icon-abui-search, .icon-abui-user, .icon-abui-mail, .icon-abui-lock, .icon-abui-gear, .icon-abui-radio-unchecked, .icon-abui-radio-checked, .icon-abui-checkbox-unchecked, .icon-abui-checkbox-checked, .icon-abui-calendar-solid, .icon-abui-pause, .icon-abui-play, .icon-abui-check-inverted-2 {
//   display: inline-block;
//   font-family: 'abui-icons';
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

[class^="icon-abui-"], [class*=" icon-abui-"] {
  font-family: 'ABUI-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  // Better Font Rendering ===========
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*! Abui Icons */
// Icon を配列で設定。「\」を前に付けてください
// $key: $value
$icons: (
  addressbook: "\\e043",
  alert: "\\e101",
  arrow-down: "\\e114",
  arrow-left: "\\e079",
  arrow-right: "\\e080",
  arrow-up: "\\e113",
  astrolablogo-mark: "\\e197",
  astrolablogo-type: "\\e200",
  ban: "\\e090",
  barcode: "\\e040",
  bell: "\\e213",
  blackboard: "\\e218",
  book: "\\e906",
  bookmark: "\\e044",
  bullhorn: "\\e122",
  calendar-solid: "\\e109",
  calendar: "\\e109",
  card: "\\e177",
  cart: "\\e116",
  chat: "\\e111",
  check-inverted: "\\e084",
  check: "\\e013",
  checkbox-checked: "\\e067",
  checkbox-unchecked: "\\e157",
  circle-arrow-down: "\\e134",
  circle-arrow-left: "\\e132",
  circle-arrow-right: "\\e131",
  circle-arrow-up: "\\e133",
  circle-cross: "\\e083",
  circle-plus: "\\e081",
  clip: "\\e142",
  clipboard: "\\e205",
  cols: "\\e901",
  contract: "\\e097",
  copy: "\\e224",
  cross: "\\e014",
  currentlocaiton: "\\e103",
  data: "\\e025",
  download: "\\e026",
  expand: "\\e096",
  eye: "\\e105",
  eye-close: "\\e106",
  file: "\\e022",
  filter: "\\e138",
  flash: "\\e162",
  folder: "\\e117",
  gear-alt: "\\e136",
  gear: "\\e019",
  guide: "\\e085",
  heart-solid: "\\e143",
  heart: "\\e005",
  home: "\\e021",
  info: "\\e086",
  keyboard: "\\e908",
  linechart: "\\e185",
  list-arrow: "\\e057",
  list-plus: "\\e058",
  list: "\\e056",
  location: "\\e062",
  lock: "\\e033",
  mail: "\\2709",
  map: "\\e184",
  matrix: "\\e011",
  maximize: "\\e140",
  microphone: "\\e600",
  minimize: "\\e601",
  minus: "\\2212",
  organization: "\\e180",
  outside: "\\e164",
  pause: "\\e073",
  pc: "\\e909",
  pen: "\\e065",
  pencil: "\\270f",
  phone: "\\e182",
  photo: "\\e046",
  picture: "\\e060",
  play: "\\e072",
  plus: "\\2b",
  population: "\\e211",
  print: "\\e045",
  pushpin: "\\e146",
  qrcode: "\\e039",
  radio-checked: "\\e149",
  radio-unchecked: "\\e148",
  reload: "\\e031",
  repeat: "\\e030",
  rows: "\\e902",
  search: "\\e003",
  send: "\\e171",
  smartphone: "\\e145",
  smile: "\\e240",
  sort: "\\e150",
  star-solid: "\\e007",
  star: "\\e006",
  tablet: "\\e90a",
  tag: "\\e042",
  thlist: "\\e012",
  threedots-vertical: "\\e235",
  threedots: "\\e234",
  time: "\\e023",
  trash: "\\e020",
  upload: "\\e027",
  user-multiple: "\\e904",
  user: "\\e008",
  video: "\\e059",
  volume: "\\e038",
  window: "\\e237",
  write: "\\e065",
  zoomin: "\\e015",
  zoomout: "\\e016",
  cloud: "\\2601",
  cloud-upload: "\\e198",
  cloud-download: "\\e346",
  flag: "\\e034",
  gift: "\\e102",
  airplane: "\\e108",
  folder-open: "\\e118",
  certificate: "\\e124",
  certificate-solid: "\\e331",
  award: "\\e323",
  globe: "\\e135",
  globe-asia: "\\e313",
  triangle-alert: "\\e209",
  frown: "\\e301",
  meh: "\\e302",
  chat-dots: "\\e303",
  chat-alt: "\\e304",
  chat-alt-dots: "\\e305",
  calendar-blank: "\\e306",
  calendar-check: "\\e307",
  mail-open: "\\e308",
  unlock: "\\e309",
  key: "\\e310",
  control: "\\e311",
  file-line: "\\e312",
  arrow-double-left: "\\e318",
  arrow-double-right: "\\e319",
  arrow-double-up: "\\e320",
  arrow-double-down: "\\e321",
  arrow-alt-left: "\\e314",
  arrow-alt-right: "\\e315",
  arrow-alt-up: "\\e316",
  arrow-alt-down: "\\e317",
  database: "\\e322",
  bag: "\\e324",
  basket: "\\e326",
  baloon: "\\e325",
  bed: "\\e327",
  box: "\\e328",
  calculator: "\\e329",
  car: "\\e330",
  company: "\\e332",
  industry: "\\e333",
  list-search: "\\e334",
  receipt: "\\e335",
  ship: "\\e336",
  store: "\\e337",
  truck: "\\e339",
  train: "\\e338",
  yacht: "\\e343",
  user-solid: "\\e341",
  user-multiple-solid: "\\e340",
  wireless: "\\e342",
  wrench: "\\e344",
  dashboard: "\\e345",
  dine: "\\e903"
);
//キーとバリューをカンマで指定
@each $key, $value in $icons {
    .icon-abui-#{$key} {
        &::before {
          content: unquote("\"") + $value + unquote("\"");
        }
    }
}
