@charset "UTF-8";
/*
Navbar
----------------------------------------------------------------
*/



// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  font-size: $component-font-size-base;
  border: none;
  border-radius: 0;
  min-height: $navbar-height-base;
  // height: $navbar-height-base;
  // margin-bottom: $navbar-margin-bottom;
  margin-bottom: 0;
  // overflow: hidden;

  // サブナビゲーションをまとめて右寄せにしたい時に使用
  & &__subnav-wrap {
    @media (min-width: $grid-float-breakpoint) {
      float: right;
      & > .navbar-nav {
        margin: 0;
      }
    }
  }
}

$navbar-brandlogo-size: round($navbar-height-base * 0.6667);  // 32px
.navbar-brandlogo {
  &::before {
    font-family: 'ABUI-icons';
    speak: none;
    font-style: normal;
    font-weight: $font-weight-base;
    font-variant: normal;
    text-transform: none;
    line-height: round($navbar-input-line-height * 0.714);  // 1
    // Better Font Rendering ===========
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e197";
  }
  @media (min-width: $screen-sm-min) {
    padding-right: $navbar-brandlogo-size + $l;
    &::before {
      font-size: $font-size-base * 2;
      color: $inverse;
      width: $navbar-brandlogo-size;  // 32px
      height: $navbar-brandlogo-size;  // 32px
      position: absolute;
      // top: $s;
      top: (($navbar-height-base - $navbar-brandlogo-size) / 2);
      right: $l;
      display: block;
      opacity: .8;
    }
  }
  @media (max-width: $screen-xs-max) {
    &::before {
      display: none;
    }
  }
}

// Navbar heading
//
// Groups `.navbar-title` and `.navbar-toggle` into a single component for easy
// styling of responsive aspects.

.navbar-header {
//   // float: left;
  @media (min-width: $grid-float-breakpoint) {
    float: left;
  }
}
// .navbar-right {
//   float: right;
// }

// Navbar collapse (body)
//
// Group your navbar content into this for easy collapsing and expanding across
// various device sizes. By default, this content is collapsed when <768px, but
// will expand past that for a horizontal display.
//
// To start (on mobile devices) the navbar links, forms, and buttons are stacked
// vertically and include a `max-height` to overflow in case you have too much
// content for the user's viewport.

.navbar-collapse {
  box-shadow: none;
  padding-right: $l;
  padding-left: $l;


  @media (min-width: $grid-float-breakpoint) {
    // Account for first and last children spacing
    .navbar-nav.navbar-left:first-child {
      margin-left: -16px;

      > li:first-child a {
        border-top-left-radius: $navbar-border-radius;
        border-bottom-left-radius: $navbar-border-radius;
      }
    }
    .navbar-nav.navbar-right:last-child {
      .navbar > .container & {
        margin-right: -36px;
      }
      > .dropdown:last-child > a {
        border-radius: 0 $navbar-border-radius $navbar-border-radius 0;
      }
    }
    .navbar-form.navbar-right:last-child {
      margin-right: -16px;
      &[role="search"] {
        margin-right: -20px;
      }
      .navbar-fixed-top &,
      .navbar-fixed-bottom & {
        margin-right: 0;
      }
    }
  }
  @media (max-width: $grid-float-breakpoint-max) {
    .navbar-nav.navbar-right:last-child {
      margin-bottom: 3px;
    }
  }
}

// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.container,
.container-fluid {
  > .navbar-header,
  > .navbar-collapse {
    margin-right: -16px;
    margin-left:  -16px;

    @media (min-width: $grid-float-breakpoint) {
      margin-right: 0;
      margin-left:  0;
    }
  }
}

//
// Navbar alignment options
//
// Display the navbar across the entirety of the page or fixed it to the top or
// bottom of the page.

// Static top (unfixed, but 100% wide) navbar
.navbar-static-top {
  z-index: $zindex-navbar;
  border-width: 0;
  border-radius: 0;
}

// Fix the top/bottom navbars when screen real estate supports it
.navbar-fixed-top,
.navbar-fixed-bottom {
  z-index: $zindex-navbar-fixed;
  border-radius: 0;
}
.navbar-fixed-top {
  border-width: 0;
}
.navbar-fixed-bottom {
  margin-bottom: 0; // override .navbar defaults
  border-width: 0;
}

// @media (min-width: $grid-float-breakpoint) {
//   .navbar-fixed-top .navbar-collapse,
//   .navbar-static-top .navbar-collapse,
//   .navbar-fixed-bottom .navbar-collapse {
//     padding-right: 16px;
//   }
// }


// Title

.navbar-title {
  // font-size: floor($component-font-size-base * 1.429); // ~20px
  font-size: floor($component-font-size-base * 1.143); // ~16px
  line-height: $navbar-input-line-height * 0.78571429; // 1.1
  // padding: (($navbar-height-base - 22px) / 2) $l;
  @include navbar-vertical-align(17px, $navbar-height-base);
  padding-left: $l;
  padding-right: $l;
  // height: $navbar-height-base;
  float: left;

  & > [class*="icon-abui-"] {
    font-size: floor($component-font-size-base * 1.267); // ~19px
    line-height: $navbar-input-line-height * 0.90214286; // 1.263
    vertical-align: top;
  }

  @media (min-width: $grid-float-breakpoint) {
    .navbar > .container &,
    .navbar > .container-fluid & {
      margin-left: -16px;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    position: absolute;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.navbar-toggle+.navbar-title {
  @media (max-width: $grid-float-breakpoint-max) {
    padding-right: 52px;
  }
}

h1, h2 {
  &.navbar-title {
    margin-top: 0;
    margin-bottom: 0;
  }
}
h1 {
  &.navbar-title {
    font-weight: bold;
  }
}
h2 {
  &.navbar-title {
    font-weight: $font-weight-base;
  }
}

.navbar.with-sidebar {
  & #header-site.navbar-title {
    margin-left: ($l * 2);
  }
}



// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {
  border: none;
  color: $brand-primary;
  margin: 0 0 0 $l;
  padding: 0 $l;
  height: $navbar-height-base;
  line-height: $navbar-height-base;

  z-index: 1;

  &::before {
    color: $link-color;
    content: "\e234";
    font-family: "ABUI-icons";
    font-size: floor($component-font-size-base * 1.467); // ~22px
    font-style: normal;
    font-weight: $font-weight-base;
    font-smoothing: antialiased;
    transition: color .25s linear;
  }
  &:hover,
  &:focus {
    outline: none;

    &::before {
      color: $link-hover-color;
    }
  }
  .icon-bar {
    display: none;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    display: block;
  }
  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
}


// Navbar nav links
//
// Builds on top of the `.nav` components with it's own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.navbar-nav {
  margin: 0;

  > li > a {
    font-size: floor($component-font-size-base * 1.067); // ~16px
    padding: (($navbar-height-base - 24px) / 2) $l;
    line-height: $line-height-computed * 0.78571429;  // 22px
  }

  .dropdown-toggle {
    transition: .25s;
    & .caret {
      border-top-color: $link-color;
      border-bottom-color: $link-color;
      border-width: 7px 5px;
      margin-left: $x;
    }
  }
  > li > a:hover,
  > li > a:focus,
  .open > a:focus,
  .open > a:hover {
    background-color: transparent;

    .caret {
      border-top-color: $link-hover-color;
      border-bottom-color: $link-hover-color;
    }
  }

  [class^="icon-abui-"] {
    line-height: $line-height-computed * 0.71428571;  // 20px
    position: relative;
    top: 1px;
  }
  .visible-sm,
  .visible-xs {
    > [class^="icon-abui-"] {
      margin-left: $m;
    }
  }
}
.navbar:not(.navbar-footblock) {
  > .navbar-nav {
    @media (max-width: $screen-xs-max) {
    // @media (max-width: $grid-float-breakpoint-max) {
      margin: 0 -16px;

      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a,
        .dropdown-header {
          padding: 7px 15px 7px 32px !important;
        }
        > li > a {
          line-height: 23px;
        }
      }
      > li > a {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
}

// Navbar form
//
// Extension of the `.form-inline` with some extra flavor for optimum display in
// our navbars.

.navbar-input {
  @include input-size($input-height-small, 5px, 12px, $input-font-size-small, $navbar-input-line-height);
}

.navbar-form {
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;

  // Vertically center in expanded, horizontal navbar
  @include navbar-vertical-align($input-height-small);

  .form-group {
    margin-top: 0;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 5px;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    margin: 0 -16px;
    width: auto;
  }

  // .navbar-nav + &.navbar-left,
  // &.navbar-right:last-child { // Width fix for Webkit and IE11
  //   @media (min-width: $grid-float-breakpoint) {
  //     width: 260px;
  //   }
  // }

  // Controls sizing
  .form-control,
  .input-group-addon,
  .btn { @extend .navbar-input; }

  // Reset rounded corners
  @include form-controls-corners-reset();

  .form-control {
    font-size: $component-font-size-base;
    border-radius: 5px;
    display: table-cell;
  }
  .form-group + .btn {
    font-size: $component-font-size-base;
    border-radius: 5px;
    margin-left: $x;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .form-group {
      margin-bottom: 0;

      & + .btn {
        margin-top: 9px;
        margin-left: 0;
      }
    }
  }
}

// Dropdown menus

// Menu position and menu carets
.navbar-nav > li {
  > .dropdown-menu {
    min-width: 100%;
    border-radius: $border-radius-base;

    @media (max-width: $grid-float-breakpoint-max) {
      transition: all 0s;
      display: none;
    }
  }
  &.open > .dropdown-menu {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 0 !important;
      display: block;
    }
    .divider {
      @media (max-width: $grid-float-breakpoint-max) {
        background-color: $gray-subtler;
        margin-left: $l * 2;
      }
    }
  }
}
.navbar-right {
  .dropdown-menu {
    @media (min-width: $grid-float-breakpoint) {
      right: auto;
      left: 0;
    }
  }
}

.navbar-nav > .dropdown > .dropdown-menu {
  padding-top: $x;
  padding-bottom: $x;
  li {
    > a {
      border-radius: 0;
      padding: $s $m;
      small {
        font-weight: $font-weight-base;
        font-size: 76%;
        display: block;
      }
    }
  }
  & .nav-header {
    font-size: 86%;
    font-weight: bold;
    padding: $s $m 0;
    @media (max-width: $grid-float-breakpoint-max) {
      // for mobile
      padding-left: $l*2;
    }
    a {
      padding: 0 0 $s 0;
    }
  }
}

.navbar-nav > .open > .dropdown-menu {
  // padding-top: $x;
  li {
    > a {
      border-radius: 0;
      padding: $s $m;
      small {
        font-weight: $font-weight-base;
        font-size: 76%;
        display: block;
      }
    }
  }
  & .nav-header {
    font-size: 86%;
    font-weight: bold;
    padding: $s $m 0;
    @media (max-width: $screen-xs-max) {
      // for mobile
      padding-left: $l*2;
    }
    a {
      padding: 0 0 $s 0;
    }
  }
}


// Menu position and menu caret support for dropups via extra dropup class
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-bottom-right-radius: $border-radius-base;
  border-bottom-left-radius: $border-radius-base;
}

.navbar-nav > .open > .dropdown-toggle,
.navbar-nav > .open > .dropdown-toggle:focus,
.navbar-nav > .open > .dropdown-toggle:hover {
  background-color: transparent;
}

// Text in navbars
//
// Add a class to make any element properly align itself vertically within the navbars.

.navbar-text {
  font-size: floor($component-font-size-base * 1.067); // ~16px
  line-height: $navbar-input-line-height * 1.02714; // 1.438 (~23px)
  color: $gray;
  margin-top: 0;
  margin-bottom: 0;
  @include navbar-vertical-align(20px, $navbar-height-base);

  span {
    line-height: round($navbar-input-line-height * 0.71428);  // 1
    padding: 0;
    padding-right: $s;
    display: inline-block;
    &.datetime {
      color: $gray-light;
    }
    &.username {
      border-right: 1px solid $gray-light;
      padding-right: $l;
      margin-right: $l;
    }
  }

  &.navbar-logininfo {
    font-size: floor($component-font-size-base * 0.858); // ~12px
    // @include navbar-vertical-align(48px, $navbar-height-base);
    line-height: round($navbar-input-line-height * 0.71428);  // 1
    margin-top: $x;

    @media (max-width: $grid-float-breakpoint-max) {
      float: left;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    margin-left: $l;
    margin-right: $s;

    // Outdent the form if last child to line up with content down the page
    &.navbar-right:last-child {
      margin-right: 0;
    }
  }
}



// Buttons in navbars
//
// Vertically center a button within a navbar (when *not* in a form).

.navbar-btn {
  margin-top: (($navbar-height-base - $input-height-base) / 2);
  margin-bottom: (($navbar-height-base - $input-height-base) / 2);

  &.btn-sm {
    margin-top: (($navbar-height-base - $input-height-small) / 2);
    margin-bottom: (($navbar-height-base - $input-height-small) / 2) - 1;
  }
  &.btn-xs {
    margin-top: (($navbar-height-base - 24) / 2);
    margin-bottom: (($navbar-height-base - 24) / 2);
  }
}


@include keyframes(scale-up-center) {
  0%   { transform: scale(0.5);}
  100% { transform: scale(1);}
}

// Unread icon
//
%navbar-badge {
  font-family: $font-family-base;
  font-weight: 700;
  text-align: center;
  color: $inverse;
  border-radius: 50%;
  position: absolute;
  right: $m;
  top: 35%;
  z-index: $zindex-navbar * 0.01;
  @include animation(scale-up-center 0.5s both);
}

%navbar-badge-position {
  @media (max-width: $screen-xs-min) {
    // margin: 0 0 0 10px;
    float: right;
    position: static;
  }
}

// .navbar-unread,
// .navbar-new {
  // .active & {
  //   background-color: $inverse;
  //   display: none;
  // }
// }

.navbar-new {
  font-size: round($component-font-size-base * 0.85714);  // 12px
  line-height: round($line-height-computed * 0.607);  // 17px
  background-color: $brand-danger;
  width: auto;
  min-width: $navbar-height-base * 0.375;   // 18px
  height: $navbar-height-base * 0.375;   // 18px
  padding: 0 1px;
  margin: -6px -10px;
  -webkit-font-smoothing: subpixel-antialiased;
  @extend %navbar-badge;
  @extend %navbar-badge-position;
  @media (max-width: $screen-xs-min) {
    margin: 0 -4px 0 10px;
  }
}

.navbar-unread {
  font-size: $component-font-size-base * 0;
  line-height: round($navbar-input-line-height * 0.71428);  // 1
  background-color: $brand-danger;
  width: $navbar-height-base * 0.125;
  height: $navbar-height-base * 0.125;
  @extend %navbar-badge;
  @extend %navbar-badge-position;
  @media (max-width: $screen-xs-min) {
    margin: 0 2px 0 10px;
  }
}

// Icon in navbars
//

.navbar-icon {
  font-size: floor($component-font-size-base * 1.143); // ~16px
  line-height: $navbar-input-line-height * 1.02714; // 1.438
  color: $inverse;
  margin-top: 0;
  margin-bottom: 0;
  float: right;
  @include navbar-vertical-align(48px, $navbar-height-base);

  & > a {
    line-height: 1;
    padding: $l 0 $l $m;
    display: block;
  }
}

.sidebar-toggle {
  cursor: pointer;
}



// Alternate navbars
// --------------------------------------------------

// Default navbar
.navbar-default {
  background-color: $navbar-default-bg;

  .navbar-title {
    color: $navbar-default-brand-color;
    a {
      color: $navbar-default-brand-color;
      &:hover,
      &:focus {
        color: $navbar-default-brand-hover-color;
        background-color: $navbar-default-brand-hover-bg;
      }
    }

    .navbar-title-separator {
      font-weight: 100;
      color: $navbar-default-link-active-color;
      padding: 0 $s;
    }
  }

  .navbar-toggle {
    &::before {
      color: $navbar-default-toggle-color;
    }
    &:hover,
    &:focus {
      background-color: transparent;

      &::before {
        color: $navbar-default-toggle-hover-color;
      }
    }
  }

  .navbar-collapse {
    border-color: $navbar-default-form-border;
    border-width: 2px;
  }
  .navbar-form {
    @extend .navbar-collapse;
    padding-left: $l + $m;

    @media (max-width: $grid-float-breakpoint-max) {
      border: none;
      padding-left: $l;
      padding-right: $l;
    }
  }

  .navbar-nav {
    > li > a {
      color: $navbar-default-link-color;

      &:hover,
      &:focus {
        color: $navbar-default-link-hover-color;
        background-color: $navbar-default-link-hover-bg;
        // border-bottom: 2px solid $navbar-default-link-hover-bg;
      }
    }
    > .active > a {
      color: $navbar-default-link-active-color;
      background-color: $navbar-default-link-active-bg;
      border-bottom: 2px solid $navbar-default-link-hover-color;
      @media (max-width: $screen-xs-max) {
        border-bottom: none;
        border-left: 2px solid $navbar-default-link-hover-color;
        background-color: darken($navbar-default-bg, 4%);
      }
      &:hover,
      &:focus {
        color: $navbar-default-link-active-color;
        background-color: $navbar-default-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
        background-color: $navbar-default-link-disabled-bg;
        opacity: .7;
      }
    }
    > .disabled.dropdown {
      > a {
        &:hover,
        &:focus {
          .caret {
            border-top-color: $navbar-default-caret-color;
            border-bottom-color: $navbar-default-caret-color;
          }
        }
      }
    }
  }

  .navbar-nav > .dropdown > .dropdown-menu {
    & .nav-header {
      color: $brand-primary;
      a {
        color: $brand-primary;
        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }

  .navbar-nav > .open > .dropdown-menu {
    & .nav-header {
      color: $brand-primary;
      a {
        color: $brand-primary;
        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }

  // Dropdown menu items and carets
  .navbar-nav {
    // Caret text color
    > .dropdown > a .caret,
    .dropdown-toggle .caret {
      border-top-color: $navbar-default-caret-color;
      border-bottom-color: $navbar-default-caret-color;
    }
    // Caret should match text color on active
    > .active > a .caret {
      border-top-color: $navbar-default-caret-active-color;
      border-bottom-color: $navbar-default-caret-active-color;
    }
    // Caret should match text color on hover
    > .dropdown > a:hover .caret,
    > .dropdown > a:focus .caret {
      border-top-color: $navbar-default-caret-hover-color;
      border-bottom-color: $navbar-default-caret-hover-color;
    }

    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: $navbar-default-link-active-bg;
        color: $navbar-default-link-active-color;
        .caret {
          border-top-color: $navbar-default-caret-active-color;
          border-bottom-color: $navbar-default-caret-active-color;
        }
      }
    }


    @media (max-width: $grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: $navbar-default-link-color;
          &:hover,
          &:focus {
            color: $navbar-default-link-hover-color;
            background-color: $navbar-default-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-default-link-active-color;
            background-color: $navbar-default-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-default-link-disabled-color;
            background-color: $navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }

  .navbar-form {
    .form-control {
      border-color: transparent;
      @include placeholder($navbar-default-form-placeholder);

      &:focus {
        border-color: $brand-secondary;
        color: $brand-secondary;
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;
      }
    }
    .input-group-btn .btn {
      border-color: transparent;
      color: $navbar-default-form-icon;
    }
    .input-group.focus {
      .form-control,
      .input-group-btn .btn {
         border-color: $brand-secondary;
         color: $brand-secondary;
      }
    }
  }

  .navbar-text {
    color: $gray;
    a {
      color: $navbar-default-link-color;

      &:hover,
      &:focus {
        color: $navbar-default-link-hover-color;
      }
    }
  }

  .navbar-btn {
    @include button-variant($gray-light, transparent, $gray-subtler, $btn-flat-default-active-bg);
  }

  .navbar-icon {
    a {
      color: $gray-light;
      &:hover,
      &:focus {
        color: $navbar-default-toggle-hover-color;
      }
    }
  }
}

// Inverse navbar
.navbar-inverse {
  @extend .navbar-default;
  background-color: $navbar-inverse-bg;
  background: $navbar-inverse-bg-gradient;

  .navbar-title {
    color: $navbar-inverse-brand-color;
    a {
      color: $navbar-inverse-brand-color;
      &:hover,
      &:focus {
        color: $navbar-inverse-brand-hover-color;
        background-color: $navbar-inverse-brand-hover-bg;
      }
    }
    .navbar-title-separator {
      color: mix($inverse, $navbar-inverse-bg, 60%);
    }
  }

  .navbar-toggle {
    &::before {
      color: $navbar-inverse-toggle-color;
    }
    &:hover,
    &:focus {
      background-color: transparent;

      &::before {
        color: $navbar-inverse-toggle-hover-color;
      }
    }
  }

  .navbar-collapse {
    border-color: darken($navbar-inverse-bg, 3%);
  }

  .navbar-nav {
    > li > a {
      color: $navbar-inverse-link-color;

      &:hover,
      &:focus {
        color: $navbar-inverse-link-hover-color;
        background-color: $navbar-inverse-link-hover-bg;
      }
    }
    > .active > a {
      color: $navbar-inverse-link-active-color;
      border-bottom-color: $navbar-inverse-link-active-bg;
      background-color: $navbar-inverse-link-active-bg;
      @media (max-width: $screen-xs-max) {
        border-left: none;
      }
      &:hover,
      &:focus {
        color: $navbar-inverse-link-color;
        background-color: $navbar-inverse-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-inverse-link-disabled-color;
        background-color: $navbar-inverse-link-disabled-bg;
        opacity: .7;
      }
    }
    > .disabled.dropdown {
      > a {
        &:hover,
        &:focus {
          .caret {
            border-top-color: $navbar-inverse-caret-color;
            border-bottom-color: $navbar-inverse-caret-color;
          }
        }
      }
    }
  }

  // Dropdown menu items and carets
  .navbar-nav {
    // Caret should match text color on hover
    > .dropdown > a:hover .caret,
    > .dropdown > a:focus .caret {
      border-top-color: $navbar-inverse-caret-hover-color;
      border-bottom-color: $navbar-inverse-caret-hover-color;
    }

    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: $navbar-inverse-link-active-bg;
        color: $navbar-inverse-link-active-color;
        border-left-color: transparent;
        .caret {
          border-top-color: $navbar-inverse-link-active-color;
          border-bottom-color: $navbar-inverse-link-active-color;
        }
      }
    }
    > .dropdown > a .caret {
      border-top-color: $navbar-inverse-caret-color;
      border-bottom-color: $navbar-inverse-caret-color;
    }

    > .dropdown > .dropdown-menu {
      & .nav-header {
        color: $navbar-inverse-dropdown-header;
        a {
          color: $navbar-inverse-dropdown-header;
          padding-left: 0!important;
          &:hover {
            color: lighten($navbar-inverse-dropdown-header, 10%);
            background-color: transparent;
          }
        }
      }
    }

    > .open > .dropdown-menu {
      & .nav-header {
        color: $navbar-inverse-dropdown-header;
        @media (max-width: $screen-xs-max) {
          color: mix($navbar-inverse-bg, white, 50%);
        }
        a {
          color: $navbar-inverse-dropdown-header;
          padding-left: 0!important;
          &:hover {
            color: lighten($navbar-inverse-dropdown-header, 10%);
            background-color: transparent;
          }
        }
      }
    }

    > .open {
      > .dropdown-arrow {
        border-top-color: $navbar-inverse-dropdown-arrow;
        border-bottom-color: $navbar-inverse-dropdown-arrow;
      }
      > .dropdown-menu {
        background-color: $navbar-inverse-dropdown-bg;
        @media (max-width: $grid-float-breakpoint-max) {
          background-color: $navbar-inverse-bg;
        }

        > li > a {
          color: $navbar-inverse-dropdown-link-color;
          border-radius: $border-radius-base;
          &:hover,
          &:focus {
            color: $navbar-inverse-dropdown-link-hover-color;
            background-color: $navbar-inverse-dropdown-link-hover-bg;
          }
          &.highlighted {
            background-color: darken($dropdown-inverse-bg, 8%);
            @media (max-width: $grid-float-breakpoint-max) {
              background-color: darken($navbar-inverse-bg, 8%);
            }
            &:hover,
            &:focus {
              background-color: $navbar-inverse-dropdown-link-hover-bg;
            }
          }
        }
        // li {}

        // & .nav-header {
        //   color: darken($navbar-inverse-dropdown-bg, 25%);
        //   @media (max-width: $grid-float-breakpoint-max) {
        //     color: mix($inverse, $navbar-inverse-bg, 80%);
        //   }
        // }
        > .divider {
          background-color: $dropdown-inverse-divider;
          @media (max-width: $grid-float-breakpoint-max) {
            margin-left: $l * 2;
            // background-color: mix($dropdown-inverse-divider, $navbar-inverse-bg, 15%);
            background-color: mix($inverse, $navbar-inverse-bg, 16%);
          }
        }
      }
    }

    // .open > .dropdown-menu {
    //   & .nav-header {
    //     a {
    //       color: darken($navbar-inverse-dropdown-bg, 25%);
    //       &:hover {
    //         color: darken($navbar-inverse-dropdown-bg, 15%);
    //       }
    //     }
    //   }
    // }

    @media (max-width: $grid-float-breakpoint-max) {
       > li > a {
         border-left-width: 0;
       }
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: $navbar-inverse-link-color;
          &:hover,
          &:focus {
            color: $navbar-inverse-link-hover-color;
            background-color: $navbar-inverse-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-inverse-link-active-color;
            background-color: $navbar-inverse-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-inverse-link-disabled-color;
            background-color: $navbar-inverse-link-disabled-bg;
          }
        }
      }
      // Custom background for dividers when collapsed
      .dropdown-menu .divider {
        background-color: $navbar-inverse-divider;
      }
    }
  }

  .navbar-form {
    @extend .navbar-collapse;

    @media (max-width: $grid-float-breakpoint-max) {
      border: none;
    }
    .form-control {
      color: $navbar-inverse-form-color;
      border-color: transparent;
      background-color: transparent;
      @include placeholder($navbar-inverse-form-placeholder);

      &:focus {
        border-color: $navbar-inverse-form-border;
        color: $brand-secondary;
      }
    }
    .btn {
      @include button-variant($btn-default-color, $brand-secondary, $btn-primary-hover-bg, $btn-primary-active-bg);
    }
    .input-group-btn .btn {
      border-color: transparent;
      background-color: transparent;
      color: rgba($navbar-inverse-form-icon, .5);
    }
    .input-group {
      background-color: $navbar-inverse-form-bg;
      &.input-group-rounded {
        border-radius: round($input-height-small / 2);
      }
      &.focus {
        .form-control,
        .input-group-btn .btn {
           border-color: $navbar-inverse-form-border;
        }
        .form-control {
          border-right-color: transparent;
          color: $navbar-inverse-form-color;
        }
        .input-group-btn .btn {
          color: $navbar-inverse-form-icon;
        }
      }
      .form-control {
        border-right-color: transparent;
      }
    }

    &[role="search"] {
      margin-right: -20px;
      @media (max-width: $screen-xs-max) {
        margin-left: -20px;
      }
    }


    @media (max-width: $grid-float-breakpoint-max) {
      border-color: $navbar-inverse-form-border;
    }
  }

  .navbar-text {
    color: $inverse;
    a {
      color: $navbar-inverse-link-color;
      &:hover,
      &:focus {
        color: $navbar-inverse-link-hover-color;
      }
    }
    span {
      &.datetime {
        color: $navbar-inverse-link-hover-color;
      }
      &.username {
        border-right-color: $navbar-inverse-link-hover-color;
      }
    }
  }

  .navbar-btn {
    @include button-variant($btn-default-color, $brand-secondary, $btn-primary-hover-bg, $btn-primary-active-bg);
  }
  .navbar-icon {
    a {
      color: $navbar-inverse-link-color;
      &:hover,
      &:focus {
        color: $navbar-inverse-toggle-hover-color;
      }
    }
  }

  .sidebar-toggle {
    &::before {
      color: $navbar-inverse-toggle-color;
    }
    &:hover,
    &:focus {
      &::before {
        color: $navbar-inverse-toggle-hover-color;
      }
    }
  }
}

// Embossed navbar
.navbar-embossed {
  @media (min-width: $grid-float-breakpoint) {
    > .navbar-collapse {
      border-radius: $navbar-border-radius;
      box-shadow: 0 -2px 0 fade(black, 0.15) inset;
    }
    &.navbar-inverse .navbar-nav {
      .active > a,
      .open > a {
        box-shadow: 0 -2px 0 fade(black, 0.15) inset;
      }
    }
  }
}

// Large navbar
.navbar-lg {
  min-height: $navbar-height-large;
  // height: $navbar-height-large;

  &.navbar-brandlogo {
    @media (min-width: $grid-float-breakpoint) {
      &::before {
        top: (($navbar-height-large - $navbar-brandlogo-size) / 2);
      }
    }
  }

  .navbar-header{
    .sidebar-toggle{
      // margin-top: (($navbar-height-large - $navbar-height-base) / 3);
      @include navbar-vertical-align(48px, $navbar-height-large);
    }
  }

  .navbar-title {
    font-size: floor($component-font-size-base * 1.429); // ~20px
    line-height: 1;
    // padding-top: (($navbar-height-large - 20px) / 2);
    // padding-bottom: (($navbar-height-large - 20px) / 2);
    @include navbar-vertical-align(20px, $navbar-height-large);

    > [class*="icon-abui-"] {
      font-size: floor($component-font-size-base * 1.6); // ~24px
      line-height: 1;
    }
  }

  .navbar-nav {
    > li > a {
      font-size: $component-font-size-base;
      line-height: $navbar-input-line-height * 1.14285714;  // 1.6
      @media (min-width: $screen-sm-min) {
      // @media (min-width: $grid-float-breakpoint) {
        padding-top: (($navbar-height-large - 24px) / 2);
        padding-bottom: (($navbar-height-large - 24px) / 2);
      }
    }
  }

  .navbar-toggle {
    height: $navbar-height-large;
    line-height: $navbar-height-large;
  }

  .navbar-form {
    @include navbar-vertical-align($input-height-small, $navbar-height-large);
  }

  .navbar-text {
    @include navbar-vertical-align(24px, $navbar-height-large);
    &.navbar-logininfo {
      margin-top: 6px;
    }
  }

  .navbar-btn {
    margin-top: (($navbar-height-large - $input-height-base) / 2);
    margin-bottom: (($navbar-height-large - $input-height-base) / 2);

    &.btn-sm {
      margin-top: (($navbar-height-large - $input-height-small) / 2);
      margin-bottom: (($navbar-height-large - $input-height-small) / 2);
    }
    &.btn-xs {
      margin-top: (($navbar-height-large - 24px) / 2);
      margin-bottom: (($navbar-height-large - 24px) / 2);
    }
  }
  & + .navbar-default.with-navbar-fixed-top{
    .navbar-header{
      margin-top: $navbar-height-large - $navbar-height-base;
    }
    .collapse.navbar-collapse{
      margin-top: $navbar-height-large - $navbar-height-base;
    }
  }

  .navbar-icon {
    margin-top: 6px;
  }
}

// Foot Block
$screen-wide-base: 1366px;
.navbar-footblock {
  opacity: .9;
  &.with-sidebar {
    @media (min-width: $screen-wide-base) {
      margin-left: $sidebar-width;
    }
  }
  &:hover {
    opacity: 1;
  }
  .navbar-nav {
    .btn {
      margin-top: (($navbar-height-base - $input-height-base) / 2 + 1);
      margin-left: 3px;
      margin-right: 3px;
      @include button-variant($gray, transparent, transparent, transparent, transparent);
      &.disabled,
      &[disabled],
      fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
          color: $gray-light;
        }
      }
      &:hover {
        color: $brand-primary
      }
    }
    .dropdown {
      .dropdown-toggle {
        margin-top: (($navbar-height-base - $input-height-base) / 2 );
      }
    }
    li {
      .navbar-text {
        padding: $m $l $m 0;
        margin-top: (($navbar-height-base - $input-height-base) / 2 + 1);
        color: $gray-light;
        @media (min-width: $grid-float-breakpoint) {
          margin-right: 0;
        }
      }
    }
    // override Bootstrap
    > li >.dropdown-menu {
      border-radius: $border-radius-base;
    }
    &.navbar-center {
      li {
        .navbar-text {
          padding: 0 $l;
          margin-bottom: -8px;
        }
      }
      .dropdown {
        .dropdown-toggle {
          margin-top: 0;
          top: (($navbar-height-base - $input-height-base) / 2 );
        }
      }
    }
  }
  &.navbar-lg {
    .btn {
      margin-top: (($navbar-height-large - $input-height-base) / 2 - 1);
    }
  }
  @media (max-width: $grid-float-breakpoint-max) {
    .navbar-nav {
      >li {
        text-align: center;
        &.open {
          >.dropdown-menu {
             > li {
              text-align: left;
            }
            .divider {
              margin: 3px 0;
              background-color: $gray-subtle;
            }
          }
        }
        .navbar-text {
          margin-left: $l;
          margin-right: 0;
        }
      }
      // &.navbar-right {
      //   float: none;
      // }
      &.navbar-center  {
        // position: relative;
        // width: auto;
        text-align: center;
        width: 100%;
        position: absolute;
        float: none;
        >li {
          // display: block;
          float: none;
          display: inline-block;
          .dropdown-menu {
            >li:not(.divider) {
              display: list-item;
            }
          }
        }
      }
    }
  }
  // override Bootsrtrap
  @media (max-width: $screen-xs-max) {
    .navbar-nav{
      float: left;
      >li {
        float: left;
      }
      .open {
        .dropdown-menu {
          position: absolute;
          background-color: $dropdown-bg;
          box-shadow: 0 1px 4px $gray-lighter;
        }
      }
    }
    .navbar-text {
      float: left;
    }
    .navbar-right {
      float: right ;
    }
  }
}

// Dropdown Position {
.navbar-fixed-bottom {
  .dropdown .dropdown-menu,
  .select .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
  .select .dropdown-menu {
    margin-bottom: $s;
    width: auto;
  }
}


// navbar Position
@media (min-width: $grid-float-breakpoint) {
  .navbar-nav.navbar-center {
    text-align: center;
    width: 100%;
    position: absolute;
    float: none;
    > li {
     float: none;
     display: inline-block;
    }
  }
  .navbar-right {
    margin-right: 0;
  }
  .navbar-nav.navbar-right {
    &:last-child {
      margin-right: 0;
    }
  }
}

// btn-back
$btn-back-height: 20px;
.navbar {
  .navbar-header {
    .btn-back {
      z-index: 2;
      display: block;
      position: absolute;
      padding-left: $l;
      width: 48px;
      font-size: $btn-back-height;
      color: $gray;
      opacity: .5;

      & + .navbar-title {
        padding-left: 48px;
      }

      .icon {
        display: block;
        height: $navbar-height-base;
        padding-top: ($navbar-height-base - $btn-back-height) /2;
      }
    }
  }
  &.navbar-lg {
    .navbar-header {
      .btn-back {
        .icon {
          height: $navbar-height-large;
          padding-top: ($navbar-height-large - $btn-back-height) /2;
        }
      }
    }
  }
  &.navbar-inverse {
    .navbar-header {
      .btn-back {
        color: rgba(white, .75);
      }
    }
  }
  &.with-sidebar {
    @media (max-width: $screen-xs-max) {
      .navbar-header {
        .btn-back {
          display: none;
          & + .navbar-title {
            padding-left: $l;
          }
        }
      }
    }

  }
}
