@charset "UTF-8";
/*
Slider
----------------------------------------------------------------
*/



.ui-slider {
  background: $gray-subtle;
  border-radius: $border-radius-base * 16;
  box-shadow: none;
  height: $slider-height;
  margin-top: $slider-height * 5;
  margin-bottom: $slider-height * 5;
  position: relative;
  cursor: pointer;
}

.noUi-handle {
  background-color: $slider-handle-bg;
  border-radius: 50%;
  outline: none;
  height: round($slider-height * 5.25);
  width: round($slider-width * 5.25);
  position: absolute;
  top: -8px;
  left: -10.5px;
  z-index: 2;
  cursor: pointer;
  transition: background .25s;

  &:hover,
  &:focus {
    background-color: $slider-handle-hover-bg;
    outline: none;
  }
  &:active {
    background-color: $slider-handle-active-bg;
  }
}

.noUi-connect {
  background-color: $slider-range-bg;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 1;
}

// Segments
// -------------------------
.noUi-horizontal {
  .slider-segments {
    width: 100%;
    position: relative;
    top: -4px;
    .segment {
      background-color: $slider-segment-bg;
      border-radius: 50%;
      height: $slider-height;
      width: $slider-width;
    }
  }
}
.noUi-vertical {
  .slider-segments {
    height: 100%;
    position: absolute;
    top: 0px;
    .segment {
      background-color: $slider-segment-bg;
      border-radius: 50%;
      height: $slider-height;
      width: $slider-width;
    }
  }
}

// Vertical orientation
// -------------------------
.noUi-vertical {
  width: $slider-width;
  height: $slider-height * 70;

  .noUi-handle {
    margin-left: -6px;
    margin-bottom: -10px;
    top: auto;
    left: -0.1em;
    &.noUi-handle-lower {
      top: -12px;
    }
    &.noUi-handle-upper {
      top: -12px;
    }
  }
  .noUi-connect {
    width: 100%;
    bottom: 0;
    border-radius: 0 0 ($border-radius-large * 10) ($border-radius-large * 10);
    height: auto;
  }
}

// Scale/Pips
// -------------------------
.noUi-pips {
  color: $gray-lighter;
  font-size: ceil($font-size-base * 0.6875);
}
.noUi-marker,
.noUi-marker-sub,
.noUi-marker-large {
  background: $gray-subtle;
}

.noUi-marker-horizontal {
  &.noUi-marker {
    height: $x;
  }
  &.noUi-marker-sub {
    height: $s;
  }
  &.noUi-marker-large {
    height: $m;
  }
}
.noUi-marker-vertical {
  &.noUi-marker {
    width: $x;
  }
  &.noUi-marker-sub {
    width: $s;
  }
  &.noUi-marker-large {
    width: $m;
  }
}
.ui-slider{
  &.with-scale {
    &.noUi-horizontal {
      margin-bottom: $slider-height * 5 + 24px;
    }
  }
}
