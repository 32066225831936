@charset "UTF-8";
/*
Dropdown
----------------------------------------------------------------
*/


// Bootstrap Customize Style
.dropdown-header {
  font-size: inherit;
  padding: 0;
}
.dropdown-menu {
  min-width: 0;
}



//-------------------------------
.dropdown-menu {
  background-color: $dropdown-bg;
  border: none;
  min-width: 100%;
  margin-top: 0px;
  opacity: 0;
  padding: 0;
  visibility: hidden;
  @extend .dropshadow--lv2;
  transition-duration: .25s;
  border-radius: $border-radius-base;
  z-index: 1000;

  // Opened state
  .open > & {
    margin-top: $x !important;
    margin-bottom: $x !important;
    opacity: 1;
    visibility: visible;
  }
  &.open {
    display: block;
  }
  &.inner {
    text-align: left;
  }
  li {
    > a {
      color: $gray;
      padding: 7px $m;
      text-decoration: none;
      transition: background-color .25s;
      @extend .clearfix;

      &:hover,
      &:active,
      &:focus {
        background: mix($dropdown-bg,$gray-subtle,50%);
        color: inherit;
        outline: none;
      }

      &.highlighted {
        background: $gray-subtle;

        &:hover,
        &:focus {
          background: $gray-subtle;
        }
      }

      &:before {
        float: right;
        margin-top: 3px;
      }
    }

    &.dropdown-header {
      margin-bottom: 5px;
      margin-top: 12px;
      padding: 0 $m;
      .text {
        font-size: 86%;
        font-weight: $font-weight-base;
        color: $brand-primary;
      }
    }
    &:not(.dropdown-header) {
      .opt {
        color: $gray;
        padding: 7px $m;
        text-decoration: none;
        transition: background-color .25s;
        @extend .clearfix;
      }
      // &.active,
      &.selected {
        a {
          background: $brand-primary;
          color: $inverse;
          &:hover,
          &:focus {
            background: mix($brand-primary, white, 80%);
            color: $inverse;
          }
        }
      }
    }
  }

  // Disabled
  > .disabled {
    a,
    a:hover,
    a:focus {
      color: $gray-light;
    }
  }

  // @media (max-width: $screen-sm-max) {
  //   border-radius: 0 0 $border-radius-base $border-radius-base !important;
  // }
  .divider {
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: $gray-subtle;
  }
}

// Dropdown expands to top
.dropup,
.navbar-fixed-bottom .dropdown {
  .dropdown-menu {
    margin-bottom: 8px;
  }
  .dropdown-arrow {
    border-bottom: none;
    border-top: 8px outset $dropdown-bg;
    bottom: 100%;
    top: auto;
  }
}

.navbar-fixed-bottom {
  &.navbar-footblock {
    & .dropdown.open {
      & > .dropdown-menu {
        margin-bottom: $s;
      }
    }
  }
}


// Second level nav
.navbar-fixed-bottom .nav > li > ul:before {
  border-bottom: none;
  border-top: 9px outset $brand-primary;
  bottom: 4px;
  top: auto;
}

.open {
  &.dropup {
    > .dropdown-menu {
      margin-bottom: 4px;
      z-index: 1040;
    }
    > .dropdown-arrow {
      margin-bottom: 10px;

      &.dropdown-arrow-inverse {
        border-top-color: $dropdown-inverse-bg;
      }
    }
  }
  > .dropdown-arrow {
    margin-top: -3px;
    opacity: 1;
  }
}

// Arrows
// --------------------------------------------------
.dropdown-arrow {
  border-style: solid;
  border-width: 0 5px 7px;
  border-color: transparent transparent $dropdown-bg transparent;
  height: 0;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 100%;
  width: 0;
  z-index: 910;
  transition: .1s;
  @include rotate(360deg); // Make corners smooth

  @media (max-width: $grid-float-breakpoint-max) {
    display: none;
  }
}

// Alternate Color
// --------------------------------------------------
.dropdown-inverse {
  background-color: $dropdown-inverse-bg;
  color: $gray-subtler;
  // padding: 4px 0 6px;
  // padding: 0;

  li {
    &.active,
    &.selected {
      > a {
        background: $brand-secondary;
        color: $gray-subtler;
        position: relative;
        z-index: 1;
      }
    }
    .divider {
      margin-left: 11px;
      margin-right: 11px;
    }
    > a {
      border-radius: 2px;
      color: $dropdown-inverse-link-color;

      &:hover,
      &:active,
      &:focus {
        background: $dropdown-inverse-link-hover-bg;
      }
      &.highlighted {
        background: mix($brand-primary, $inverse, 85%);
        &:hover,
        &:focus {
          background: mix($brand-primary, $inverse, 75%);
        }
      }
    }
    &.divider {
      background-color: $dropdown-inverse-divider;
    }
  }
  // Disabled
  > .disabled {
    a,
    a:hover,
    a:focus {
      color: $gray-lighter;
    }
  }
}
.dropdown-arrow {
  &.dropdown-arrow-inverse {
    border-bottom-color: $dropdown-inverse-bg;
  }
}
