@charset "UTF-8";
/*
Typography
----------------------------------------------------------------
*/



// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  small {
    font-weight: normal;
    line-height: 1;
    color: $headings-small-color;
  }
}
h1, h2, h3,
.h1, .h2, .h3 {
  small,
  .small {
    font-size: 65%;
  }
}
h4, h5, h6,
.h4, .h5, .h6 {
  small,
  .small {
    font-size: 75%;
  }
}


h1, h2, h3,
.h1, .h2, .h3 {
  // margin-top: $line-height-computed * 1.25;
  margin-top: 0;
  padding-top: ($line-height-computed / 1.5);
  margin-bottom: ($line-height-computed / 1.25);
}
h4,
.h4 {
  // margin-top: ($line-height-computed / 1);
  margin-top: 0;
  padding-top: ($line-height-computed / 1.75);
  margin-bottom: ($line-height-computed / 1.25);
  font-weight: normal;
  color: $gray;
}
h5,
.h5 {
  // margin-top: ($line-height-computed / 1);
  margin-top: 0;
  padding-top: ($line-height-computed / 2);
  margin-bottom: ($line-height-computed / 1.25);
  font-weight: normal;
}
h6, .h6 {
  // margin-top: ($line-height-computed / 1.25);
  margin-top: 0;
  padding-top: ($line-height-computed / 2.5);
  margin-bottom: ($line-height-computed / 1.75);
  color: $gray-light;
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }


// Body text
// -------------------------

p {
  font-size:   $font-size-base;
  line-height: $line-height-base;
  margin: 0 0 ($line-height-computed / 2.25);
}

.lead {
  margin-bottom: $line-height-computed;
  font-size:   floor($font-size-base * 1.556);  // ~28px
  line-height: 1.46428571;                      // ~41px
  font-weight: 300;

  @media (min-width: $screen-sm-min) {
    font-size: ($font-size-base * 1.667);       // ~30px
  }
}

.note {
  color: $gray-lighter;
  font-size: $font-size-base*0.6875; // 11px
}

.note-shoulder {
  float: right;
  display: block;
  color: $gray-lighter;
  font-size: floor($component-font-size-base*0.786);
  font-weight: normal;
  line-height: 1;
  padding-bottom: $x;
}

.caption {
  font-size: 88%;
  color: $gray-light;
  padding-top: $x;
}


// Emphasis & misc
// -------------------------

// Ex: 18px base font * 83% = about 15px
small,
.small {
  font-size: 83%;     // ~15px
  // line-height: 2.067; // ~31px
}

// Contextual emphasis
.text-muted {
  color: $text-muted;
}
.text-inverse {
  color: $inverse;
}
.text-primary {
  @include text-emphasis-variant($brand-primary);
}
.text-warning {
  @include text-emphasis-variant($state-warning-text);
}
.text-danger {
  @include text-emphasis-variant($state-danger-text);
}
.text-success {
  @include text-emphasis-variant($state-success-text);
}
.text-info {
  @include text-emphasis-variant($state-info-text);
}

// Contextual backgrounds
.bg-primary {
  // Given the contrast here, this is the only class to have its color inverted
  // automatically.
  color: $inverse;
  @include bg-variant($brand-primary);
}
.bg-success {
  @include bg-variant($state-success-bg);
}
.bg-info {
  @include bg-variant($state-info-bg);
}
.bg-warning {
  @include bg-variant($state-warning-bg);
}
.bg-danger {
  @include bg-variant($state-danger-bg);
}

// Solid backgrounds
.bg-solid-primary {
  color: $inverse;
  @include bg-variant($brand-primary);
}
.bg-solid-success {
  @include bg-variant($brand-success);
}
.bg-solid-info {
  @include bg-variant($brand-info);
}
.bg-solid-warning {
  @include bg-variant($brand-warning);
}
.bg-solid-danger {
  @include bg-variant($brand-danger);
}
.bg-solid-additional1 {
  @include bg-variant($brand-additional1);
}
.bg-solid-additional2 {
  @include bg-variant($brand-additional2);
}

// Page header
// -------------------------

.page-header {
  padding-bottom: (($line-height-computed / 2) - 1);
  margin: ($line-height-computed * 2) 0 $line-height-computed;
  border-bottom: 1px solid $page-header-border-color;
}


// Lists
// --------------------------------------------------

// Unordered and Ordered lists
ul,
ol {
  margin-top: 0;
  margin-bottom: ($line-height-computed / 2);
  & ul,
  & ol {
    margin-bottom: 0;
  }
}

// Description Lists
dl {
  margin-top: 0;
  margin-bottom: $line-height-computed;
}

dt,
dd {
  line-height: $line-height-base;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}


// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).

@media (min-width: $grid-float-breakpoint) {
  .dl-horizontal {
    dt {
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: ($component-offset-horizontal - 20);
      float: left;
      overflow: hidden;
      clear: left;
    }
    dd {
      margin-left: $component-offset-horizontal;
    }
  }
}

// MISC
// ----

// Abbreviations and acronyms
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted $abbr-border-color;
}

// Blockquotes
blockquote {
  font-size: $font-size-base + 1.5;
  border-left: 3px solid $blockquote-border-color;
  padding: 0 0 0 $l;
  margin: 0 0 $line-height-computed;

  p {
    font-size: ceil($font-size-base * 1.111); // ~20px
    line-height: $line-height-base - 0.2;                        // ~31px
    font-weight: normal;
    margin-bottom: .4em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  footer, .small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
  }
  footer::before, .small::before {
    content: '\2014 \00A0';
  }

  .small {
    font-size: ceil($font-size-base * 0.857);
    line-height: $line-height-base;
    font-style: italic;
    color: $blockquote-small-color;

    &:before {
      content: "";
    }
  }

  // Float right with text-align: right
  &.pull-right {
    padding-right: $l;
    padding-left: 0;
    border-right: 3px solid $blockquote-border-color;
    border-left: 0;
    & footer::before,
    & .small::before {
      content: '';
    }
    & footer::after,
    & .small::after {
      content: '\00A0 \2014';
    }
  }
}

// Addresses
address {
  font-style: normal;
  line-height: $line-height-base;
  margin-bottom: $line-height-computed;
}


// helper-classes
//text-left、text-center、text-right は_bootstrap.scssで規定
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
