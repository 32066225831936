@charset "UTF-8";
/*
Button group
----------------------------------------------------------------
*/



// Button carets

.caret {
  .btn-default & {
    border-top-color: $btn-default-color;
  }
  .btn-primary &,
  .btn-success &,
  .btn-warning &,
  .btn-danger &,
  .btn-info & {
    border-top-color: white;
  }
}
.dropup {
  & .btn-default .caret {
    border-bottom-color: $btn-default-color;
  }
  .btn-primary,
  .btn-success,
  .btn-warning,
  .btn-danger,
  .btn-info {
   .caret {
      border-bottom-color: $inverse;
    }
  }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-xs > .btn { @extend .btn-xs; }
.btn-group-sm > .btn { @extend .btn-sm; }
.btn-group-lg > .btn { @extend .btn-lg; }
.btn-group-hg > .btn { @extend .btn-hg; }


.btn-group {
  > .btn {
    & + .btn {
      margin-left: -1px;
    }
    & + .dropdown-toggle {
      border-left: 1px solid mix($btn-default-bg, white, 50%);
      // padding-left: $m;
      // padding-right: $m;
      width: 12px*2 + 5px*2 + 1px;
      height: $input-height-base;
      padding: 0;
      padding-right: 0;
      z-index: 10;
      // .caret {
      //   margin: 5px 3px 5px;
      // }
      .caret {
        margin: -2px 0 0;
      }
      &.btn-hg {
        width: 19px*2 + 5px*2 + 1px;
        height: $input-height-huge;
      }
      &.btn-lg {
        width: 14px*2 + 5px*2 + 1px;
        height: $input-height-large;
      }
      &.btn-sm {
        width: 10px*2 + 5px*2 + 1px;
        height: $input-height-small;
      }
      &.btn-xs {
        width: 8px*2 + 4px*2 + 1px;
        height: 24px;
      }

      &.btn-primary {
        border-left-color: mix($brand-primary, white, 50%);
      }
      &.btn-success {
        border-left-color: mix($brand-success, white, 50%);
      }
      &.btn-info {
        border-left-color:mix($brand-info, white, 50%);
      }
      &.btn-warning {
        border-left-color:mix($brand-warning, white, 50%);
      }
      &.btn-danger {
        border-left-color:mix($brand-danger, white, 50%);
      }
      &.btn-inverse {
        border-left-color:$gray-subtle;
      }
      &.btn-inverse-primary {
        border-left-color: mix($brand-primary, white, 25%);
      }
      &.btn-inverse-success {
        border-left-color: mix($brand-success, white, 25%);
      }
      &.btn-inverse-info {
        border-left-color:mix($brand-info, white, 25%);
      }
      &.btn-inverse-warning {
        border-left-color:mix($brand-warning, white, 25%);
      }
      &.btn-inverse-danger {
        border-left-color:mix($brand-danger, white, 25%);
      }
      &.btn-flat-default,
      &.btn-flat-primary,
      &.btn-flat-info,
      &.btn-flat-warning,
      &.btn-flat-danger {
        & + .dropdown-toggle {
          border-left-color: mix($btn-default-bg, white, 16%);
        }
      }
    }
  }
}

.dropdown-toggle {
  &.btn-xs {
    .caret {
      border-width: 6px 4px;
    }
  }
  // .caret {
  //   margin-left: $s;
  // }
}

.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  font-weight: 400;
}
.btn-group {
  &.open {
    & > .dropdown-menu {
      padding: 0;
    }
  }
}

.btn-group:focus .dropdown-toggle {
  outline: none;
  transition: .25s;
}

// The clickable button for toggling the menu
// Remove the gradient and set the same inset shadow as the :active state
.btn-group.open .dropdown-toggle {
  color: fade($btn-default-color, 75%);
  box-shadow: none;
}

// 幅いっぱいのDropdown Button
.btn-group {
  &.btn-block {
    & > .btn {
      width: 100%;
      &:first-child:not(:last-child):not(.dropdown-toggle) {
        padding-right: 12px*2 + 5px*2;
        border-top-right-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        &.btn-hg {
          padding-right: 19px*2 + 5px*2 + 21px;
        }
        &.btn-lg {
        padding-right: 14px*2 + 5px*2 + 15px;
        }
        &.btn-sm {
        padding-right: 10px*2 + 5px*2 + 11px;
        }
        &.btn-xs {
        padding-right: 8px*2 + 4px*2 + 6px;
        }
      }
      & + .dropdown-toggle {
        width: 12px*2 + 5px*2 + 1px;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        padding-right: 0;
        &.btn-hg,
        &.btn-lg,
        &.btn-sm,
        &.btn-xs {
          padding-right: 0;
        }
        &.btn-hg {
          width: 19px*2 + 5px*2 + 1px;
        }
        &.btn-lg {
          width: 14px*2 + 5px*2 + 1px;
        }
        &.btn-sm {
          width: 10px*2 + 5px*2 + 1px;
        }
        &.btn-xs {
          width: 8px*2 + 4px*2 + 1px;
        }
        .caret {
          margin: -2px 0 0;
        }
      }
    }
    & > .dropdown-toggle {
      padding-right: 12px*2 + 5px*2;
      .caret {
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -2px;
        vertical-align: middle;
        z-index: 10;
      }
      &.btn-hg {
        padding-right: 19px*2 + 5px*2;
        .caret {
          right: 19px;
        }
      }
      &.btn-lg {
        padding-right: 14px*2 + 5px*2;
        .caret {
          right: 14px;
        }
      }
      &.btn-sm {
        padding-right: 10px*2 + 5px*2;
        .caret {
          right: 10px;
        }
      }
      &.btn-xs {
        padding-right: 8px*2 + 4px*2;
        .caret {
          right: 8px;
        }
      }
    }
  }

  // 表組内の場合は初期値に戻す（IE11 未対応）
  .cell-control > & {
    & .dropdown-toggle {
      &.btn-sm {
        padding-right: 10px !important;
      }
    }
  }
}

/* Button Group Justified */
// btn-inverseの時、線が２重になるバグの対処
.btn-group.btn-group-justified {
  & > .btn-group {
    & .btn {
      border-right-width: 0px;
    }
  }
  & > .btn-group {
    &:last-of-type {
      & .btn {
        border-right-width: 1px;
      }
    }
  }
  // ホバー時の調整
  & > .btn-group:hover + .btn-group {
    & .btn-inverse {
      border-left: 1px solid $btn-inverse-hover-bg;
      &-primary {
        border-left: 1px solid $btn-flat-primary-active-bg;
      }
      &-info {
        border-left: 1px solid $btn-flat-info-active-bg;
      }
      &-danger {
        border-left: 1px solid $btn-flat-danger-active-bg;
      }
      &-success {
        border-left: 1px solid $btn-flat-success-active-bg;
      }
      &-warning {
        border-left: 1px solid $btn-flat-warning-active-bg;
      }
    }
  }
}


// Other button locations
// Button with icon inside
.btn-toolbar .btn {
  &.active {
    color: $btn-default-color;
  }
  > [class^="icon-abui-"] {
    font-size: $icon-normal;
    margin: 0 1px;
  }
}

// Switcher
.btn-group {
  &.btn-switcher {
    .active {
      color: $inverse;
      &.btn-inverse-primary {
        color: $brand-primary;
        border-color: $brand-primary;
      }
      &.btn-inverse-success {
        color: $brand-success;
        border-color: $brand-success;
      }
      &.btn-inverse-danger {
        color: $brand-danger;
        border-color: $brand-danger;
      }
      &.btn-inverse-warning {
        color: $brand-warning;
        border-color: $brand-warning;
      }
      &.btn-inverse-info {
        color: $brand-info;
        border-color: $brand-info;
      }
    }
  }
}
