@charset "UTF-8";
/*
Loader
----------------------------------------------------------------
*/



.sk-fading-circle {
  width: 64px;
  height: 64px;
  margin: -32px auto 100px -32px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1050;
  & .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    &::before {
      content: '';
      margin: 0 auto;
      width: 15%;
      height: 15%;
      // background-color: $inverse;
      background-color: $gray-subtle;
      border-radius: 100%;
      @include animation(sk-circleFadeDelay 1.2s infinite ease-in-out both);
      display: block;
      // -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      //         animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }
  }
}

.sk-circle {
  &.sk-circle2 {
    @include rotate(30deg);
    &::before {
      @include animation-delay(-1.1s);
    }
  }
  &.sk-circle3 {
    @include rotate(60deg);
    &::before {
      @include animation-delay(-1s);
    }
  }
  &.sk-circle4 {
    @include rotate(90deg);
    &::before {
      @include animation-delay(-.9s);
    }
  }
  &.sk-circle5 {
    @include rotate(120deg);
    &::before {
      @include animation-delay(-.8s);
    }
  }
  &.sk-circle6 {
    @include rotate(150deg);
    &::before {
      @include animation-delay(-.7s);
    }
  }
  &.sk-circle7 {
    @include rotate(180deg);
    &::before {
      @include animation-delay(-.6s);
    }
  }
  &.sk-circle8 {
    @include rotate(210deg);
    &::before {
      @include animation-delay(-.5s);
    }
  }
  &.sk-circle9 {
    @include rotate(240deg);
    &::before {
      @include animation-delay(-.4s);
    }
  }
  &.sk-circle10 {
    @include rotate(270deg);
    &::before {
      @include animation-delay(-.3s);
    }
  }
  &.sk-circle11 {
    @include rotate(300deg);
    &::before {
      @include animation-delay(-.2s);
    }
  }
  &.sk-circle12 {
    @include rotate(330deg);
    &::before {
      @include animation-delay(-.1s);
    }
  }
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

